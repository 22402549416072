import * as CONSTANTS from "redux/constants/actionTypes";

type State = {
  isLoading: boolean;
  couponStatus: string | null;
};

const initialState: State = {
  isLoading: true,
  couponStatus: null,
};

type Action = { type: string; payload: string };

const printBookOrder = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case CONSTANTS.USER_COUPON_STATUS.SUCCESS:
      return {
        ...state,
        couponStatus: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default printBookOrder;

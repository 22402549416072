import {
  FOOTER_STYLE_GRAY,
  FOOTER_STYLE_WHITE,
  SHOW_FOOTER,
  SHOW_HEADER,
  SHOW_LEFTSIDEBAR,
  UPDATE_HEADER_TITLE
} from "../constants";
const initialState = {
  showLeftSideBar: true,
  showHeader: true,
  showFooter: true,
  footer: {
    bgClass: "bg-gray-2"
  },
  headerTitle: "",
  loading: true
};

const layout = (state = initialState, { type = null, payload = null } = {}) => {
  switch (type) {
    case SHOW_LEFTSIDEBAR:
      return {
        ...state,
        showLeftSideBar: payload
      };
    case SHOW_HEADER:
      return {
        ...state,
        showHeader: payload
      };
    case SHOW_FOOTER:
      return {
        ...state,
        showFooter: payload
      };
    case FOOTER_STYLE_GRAY:
      return {
        ...state,
        footer: { bgClass: payload },
        loading: false
      };
    case FOOTER_STYLE_WHITE:
      return {
        ...state,
        loading: false,
        footer: { bgClass: payload }
      };
    case UPDATE_HEADER_TITLE:
      return {
        ...state,
        headerTitle: payload
      };
    default:
      return state;
  }
};

export default layout;

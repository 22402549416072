import { getGoogleSessionId } from "services";
import { REACT_APP_CLIENT_ID } from "utils/env";
import { v4 as uuidv4 } from "uuid";

export const createHeader = () => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId(),
    "Cache-Control": "no-cache",
  };
};

export function getOS() {
  const userAgent = window.navigator.userAgent;

  if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
    return "mac";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS";
  } else if (/Win32|windows|Win64|WinCE/i.test(userAgent)) {
    return "windows";
  } else if (/android/i.test(userAgent)) {
    return "android";
  } else if (/linux/i.test(userAgent)) {
    return "linux";
  }

  return null;
}

export function isJSDOMEnvironment() {
  return navigator.userAgent.includes("Node.js") || navigator.userAgent.includes("jsdom");
}

export const MAC_OS = "mac";
export const WINDOWS_OS = "windows";
export const LINUX_OS = "linux";

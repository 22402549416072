import { useHistory } from "react-router-dom";
//Providers
import { Auth0Provider } from "@auth0/auth0-react";
import { REACT_APP_AUTH0_AUDIENCE, REACT_APP_AUTH0_CLIENTID, REACT_APP_AUTH0_DOMAIN } from "utils/env";

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo ? appState.returnTo : window.location.pathname);
  };

  /**
   * @type {import("@auth0/auth0-react").Auth0ProviderOptions}
   */
  const providerConfig = {
    domain: REACT_APP_AUTH0_DOMAIN,
    clientId: REACT_APP_AUTH0_CLIENTID,
    authorizationParams: {
      audience: REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: window.location.origin,
    },
    onRedirectCallback,
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

export default AuthProvider;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { tr } from "components/utils";

import Button from "components/Button";
import CreatePrompt from "pages/ShareStory/PaywallOrCreatePrompt";

const FreeAccountModalContent = ({ handleLogin, handleSignup }) => {
  const { t } = useTranslation();
  return (
    <div>
      <CreatePrompt freeAccountModal={true} />
      <div className="button-wrap flex w-full justify-center mb-6">
        <Button
          size="large"
          fontWeight="medium"
          width="full"
          title={tr(t, "shareStory.signUp")}
          handleClick={handleSignup}
        />
      </div>
      <div className="flex justify-center">
        <span className="text-xs text-black">
          {tr(t, "shareStory.alreadyAccount")}
          <Link
            className="ml-1.5 text-blue-4"
            to="#"
            onClick={handleLogin}
          >
            {tr(t, "shareStory.signIn")}
          </Link>
        </span>
      </div>
    </div>
  );
};

export default FreeAccountModalContent;

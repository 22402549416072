import React from 'react';
import Typography from 'components/Typography';
import { NavLink } from "react-router-dom";

const GetSearchNavs = (searchTabMenu, clearAppErrorState, setIsSearchTab) => {
  return searchTabMenu.map((item, index) => (
    <NavLink
      key={index}
      onClick={() => {
        setIsSearchTab(false);
        clearAppErrorState();
      }}
      to={`${item.navigateTo}`}
      className="ml-0 active:bg-gray-1 active:text-gray-7 "
    >
      <p className="flex justify-between">
        <Typography size={16} text="gray-1">
          {" "}
          {item.itemName}{" "}
        </Typography>
      </p>
    </NavLink>
  ));
}

export default GetSearchNavs
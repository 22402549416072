import { getOwner } from "../../services";
import {
  CLEARHOMEPAGESTORIES,
  CLEAR_STORY_LIKES_PERSONS,
  DELETEINDIVDUALOWNSTORY,
  GROUP_STORY_LIST_UPDATE_REDUX,
  HOMESCROLLCORD,
  MILO_MODAL,
  ONBOARDING_CHECKLIST,
  ONBOARDING_MODULE,
  OWN_STORY_LIST_UPDATE_REDUX,
  REMOVE_MEDIA_FROM_STORIES,
  REMOVE_UNFOLOW_AUTHOR_STORY,
  RESET_OWN_STORY_OFFSET,
  RETRY_FAILED_STORY,
  UPDATE_LAST_VISIT_GROUP
} from "../constants";
import * as CONSTANTS from "../constants/actionTypes";
import { removeMediaFromStories } from "../helpers";
import {
  updateGroupStoriesList,
  updateOwnStroiesList,
  updateRetryFailedStory,
  updateUnfollowAuthorStory
} from "./../helpers/ownStories";
const initialState = {
  isLoading: true,
  countLoading: false,
  stories: [],
  ownStories: [],
  ownStoryOffset: 0,
  groupStories: [],
  peopleStories: [],
  lastVisitedGroup: null,
  groupStoryOffset: 0,
  peopleStoryOffset: 0,
  storiesCount: 0,
  isPaginationLoading: false,
  isLikesPaginationLoading: false,
  trees: [],
  treesForGroup: [],
  treesForGroupLoading: false,
  treesUpdated: false,
  treesLoading: true,
  error: false,
  isRecentProple: true,
  recentProple: [],
  storylikespersonsList: [],
  isMiloModalOpen: false,
  scrollCord: { x: 0, y: 0 },
  hintListLoading: true,
  hintList: [],
  hintCollectionIds: [],
  updateHintStatus: false,
  checkModulesEligibility: false,
  onBoardingCheckList: []
};

const homepage = (state = initialState, { type = null, payload = null } = {}) => {
  const loadingPaginateFail = {
      ...state,
      isPaginationLoading: false
    },
    loadingPaginateRequest = {
      ...state,
      isPaginationLoading: true
    },
    failure = {
      ...state,
      isLoading: false,
      error: true
    };
  switch (type) {
    case CONSTANTS.UPDATE_TREENAME_SUCCESS:
      return {
        ...state,
        trees: payload,
        treesUpdated: true
      };
    case CONSTANTS.UPDATE_TREENAME_FAILURE:
      return {
        ...state,
        error: payload,
        treesUpdated: true
      };
    case CONSTANTS.GETSTORIES.REQUEST:
      return {
        ...state,
        isLoading: true,
        stories: [],
        error: false
      };
    case CONSTANTS.GETSTORIES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stories: payload,
        error: false
      };
    }
    case CONSTANTS.GETSTORIES.FAILURE:
      return failure;
    case CONSTANTS.GETHOMESTORYANDUPDATELIST.SUCCESS:
      return {
        ...state,
        stories: state.stories.map((item) =>
          typeof item === "string" && item === payload.storyId ? payload : item
        )
      };
    case CONSTANTS.GETOWNSTORYANDUPDATELIST.SUCCESS:
      return {
        ...state,
        ownStories: state.ownStories.map((item) =>
          typeof item === "string" && item === payload.storyId ? payload : item
        )
      };
    case CONSTANTS.GETGROUPSTORYANDUPDATELIST.SUCCESS:
      return {
        ...state,
        groupStories: state.groupStories.map((item) =>
          typeof item === "string" && item === payload.storyId ? payload : item
        )
      };
    case CONSTANTS.GETOWNSTORIES.REQUEST:
      return {
        ...state,
        isLoading: true,
        ownStories: [],
        error: false
      };
    case CONSTANTS.GETOWNSTORIES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ownStories: payload,
        error: false
      };
    case CONSTANTS.GETOWNSTORIES.FAILURE:
      return failure;
    case CONSTANTS.GETGROUPSTORIES.REQUEST:
      return {
        ...state,
        isLoading: true,
        groupStories: [],
        error: false
      };
    case CONSTANTS.GETGROUPSTORIES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupStories: payload,
        error: false
      };
    case CONSTANTS.GETGROUPSTORIES.FAILURE:
      return failure;
    case CONSTANTS.GETSTORIESCOUNT.REQUEST:
      return {
        ...state,
        error: false,
        countLoading: true
        // isLoading: true,
      };

    case CONSTANTS.GETSTORIESCOUNT.SUCCESS:
      return {
        ...state,
        storiesCount: payload,
        error: false,
        countLoading: false
      };
    case CONSTANTS.GETSTORIESPAGINATION.REQUEST:
      return loadingPaginateFail;
    case CONSTANTS.GETSTORIESPAGINATION.SUCCESS:
      return {
        ...state,
        stories: [...state.stories, ...payload],
        isPaginationLoading: false
      };

    case CONSTANTS.GETSTORIESPAGINATION.FAILURE:
      return loadingPaginateRequest;
    case CONSTANTS.GETOWNSTORIESPAGINATION.REQUEST:
      return loadingPaginateRequest;
    case CONSTANTS.GETOWNSTORIESPAGINATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ownStories: [...state.ownStories, ...payload],
        isPaginationLoading: false
      };

    case CONSTANTS.GETOWNSTORIESPAGINATION.FAILURE:
      return loadingPaginateFail;
    case CONSTANTS.GETGROUPSTORIESPAGINATION.REQUEST:
      return loadingPaginateRequest;
    case CONSTANTS.GETGROUPSTORIESPAGINATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupStories: [...state.groupStories, ...payload],
        isPaginationLoading: false
      };

    case CONSTANTS.GETGROUPSTORIESPAGINATION.FAILURE:
      return loadingPaginateFail;

    case CONSTANTS.GETTREESLIST.REQUEST:
      return {
        ...state,
        error: false,
        treesLoading: true
      };

    case CONSTANTS.GETTREESLIST.SUCCESS:
      return {
        ...state,
        treesLoading: false,
        trees: payload,
        error: false
      };

    case CONSTANTS.GETTREESLIST.FAILURE:
      return {
        ...state,
        treesLoading: false,
        error: true
      };

    case CONSTANTS.GETTREESLISTFORGROUP.REQUEST:
      return {
        ...state,
        error: false,
        treesForGroupLoading: true
      };

    case CONSTANTS.GETTREESLISTFORGROUP.SUCCESS:
      return {
        ...state,
        treesForGroupLoading: false,
        treesForGroup: payload
      };

    case CONSTANTS.GETTREESLISTFORGROUP.FAILURE:
      return {
        ...state,
        treesForGroupLoading: false,
        error: payload
      };

    case CONSTANTS.GETNEWSPAPERFREEVIEWSCOUNT.SUCCESS:
      return {
        ...state,
        newspaperviewcount: payload,
        error: false
      };

    case CONSTANTS.GETNEWSPAPERFREEVIEWSCOUNT.FAILURE:
      return {
        ...state,
        error: true
      };
    case CONSTANTS.UPDATENEWSPAPERVIEWSPAGEURL.SUCCESS:
      return {
        ...state,
        updateNewsView: true,
        error: false
      };

    case CONSTANTS.UPDATENEWSPAPERVIEWSPAGEURL.FAILURE:
      return {
        ...state,
        updateNewsView: false,
        error: true
      };
    case CONSTANTS.RECENTPEOPLE.REQUEST:
      return {
        ...state,
        isRecentProple: true
      };
    case CONSTANTS.RECENTPEOPLE.SUCCESS:
      return {
        ...state,
        isRecentProple: false,
        recentProple: payload
      };
    case CONSTANTS.RECENTPEOPLE.FAILURE:
      return {
        ...state,
        isRecentProple: false,
        recentProple: []
      };
    case CONSTANTS.UPDATESTORYISLIKED.SUCCESS: {
      let storyList;
      let storyIndex = state.stories.findIndex((item) => item.storyId === payload.storyId);
      if (payload.isLiked === "like") {
        storyList = [...(state.stories ?? [])];
        storyList[storyIndex] = {
          ...storyList[storyIndex],
          likes: [...(storyList[storyIndex]?.likes || []), { userId: getOwner() }]
        };
      } else if (payload.isLiked === "unlike") {
        storyList = [...(state.stories ?? [])];
        storyList[storyIndex] = {
          ...storyList[storyIndex],
          likes: storyList[storyIndex]?.likes.filter((value) => value.userId !== getOwner())
        };
      }
      return {
        ...state,
        stories: [...storyList]
      };
    }
    case CONSTANTS.SETFOLLOWUNFOLLOWLIKE.SUCCESS:
      state.storylikespersonsList[payload.index].isFollowing = payload.isFollow;
      return {
        ...state
      };
    case CLEAR_STORY_LIKES_PERSONS:
      return {
        ...state,
        storylikespersonsList: []
      };
    case CONSTANTS.STORYLIKESPERSONS.REQUEST:
      return {
        ...state,
        isLikesPageLoading: true
      };

    case CONSTANTS.STORYLIKESPERSONS.SUCCESS:
      return {
        ...state,
        isLikesPageLoading: false,
        storylikespersonsList: [...state.storylikespersonsList, ...(payload?.data ?? [])]
      };
    case CONSTANTS.SETFOLLOWUNFOLLOW.SUCCESS:
      state.stories[payload.index].isFollow = payload.isFollow;
      return {
        ...state
      };

    case MILO_MODAL: {
      return {
        ...state,
        isMiloModalOpen: payload
      };
    }
    case HOMESCROLLCORD: {
      return {
        ...state,
        scrollCord: payload
      };
    }
    case CLEARHOMEPAGESTORIES: {
      return {
        ...state,
        stories: []
      };
    }
    case CONSTANTS.HINTLIST.REQUEST:
      return {
        ...state,
        hintListLoading: true
      };
    case CONSTANTS.HINTLIST.SUCCESS:
      return {
        ...state,
        hintList: payload.homePageHints || [],
        hintCollectionIds: payload.subscribedCollectionIds || [],
        hintListLoading: false
      };
    case CONSTANTS.HINTLIST.FAILURE:
      return {
        ...state,
        hintListLoading: false
      };
    case CONSTANTS.UPDATEHINTSTATUS.REQUEST:
      return {
        ...state,
        updateHintStatus: true
      };
    case CONSTANTS.UPDATEHINTSTATUS.SUCCESS:
      return {
        ...state,
        hintList: state.hintList.filter((y) => y.hintId !== payload.hintId),
        updateHintStatus: false
      };
    case CONSTANTS.UPDATEHINTSTATUS.FAILURE:
      return {
        ...state,
        updateHintStatus: false
      };
    case UPDATE_LAST_VISIT_GROUP:
      return {
        ...state,
        lastVisitedGroup: payload
      };
    case DELETEINDIVDUALOWNSTORY:
      return {
        ...state,
        ownStories: state.ownStories.filter((y) => y?.storyId !== payload.storyId),
        groupStories: state.groupStories.filter((y) => y?.storyId !== payload.storyId)
      };
    case OWN_STORY_LIST_UPDATE_REDUX:
      return updateOwnStroiesList(state, payload);
    case GROUP_STORY_LIST_UPDATE_REDUX:
      return updateGroupStoriesList(state, payload);
    case REMOVE_MEDIA_FROM_STORIES: {
      return removeMediaFromStories(state, payload, ["ownStories", "groupStories", "stories"]);
    }
    case RETRY_FAILED_STORY:
      return updateRetryFailedStory(state, payload);
    case RESET_OWN_STORY_OFFSET:
      return {
        ...state,
        ownStoryOffset: 0
      };
    case REMOVE_UNFOLOW_AUTHOR_STORY:
      return updateUnfollowAuthorStory(state, payload);
    case ONBOARDING_MODULE:
      return {
        ...state,
        checkModulesEligibility: payload
      };
    case ONBOARDING_CHECKLIST:
      return {
        ...state,
        onBoardingCheckList: payload
      };
    default:
      return state;
  }
};

export default homepage;

import sanitizeHtml from "sanitize-html";
import { memo, useCallback } from "react";
import { tr } from "../utils";
import { useTranslation } from "react-i18next";

export default function Translator({ tkey }: { tkey?: string }): JSX.Element | null {
    const { t } = useTranslation();

    if (!tkey) return null;
    if (/\n/g.test(t(tkey))) return <StringToNode key={tkey} value={tr(t, tkey)?.replace?.(/\n/g, "<br />")} />;
    return tr(t, tkey);
}


// https://github.com/facebook/react/issues/12014
const StringToNode = memo(({ value }: { value: string }) => {
    const spanRefCallback = useCallback((node: HTMLSpanElement) => {
        if (!node?.parentElement) return;

        // HTML string inserted adjacent to preserve the DOM hierarchy for this text while keep formatting.
        // Original solution was to overwrite the outerHTML of the span but that was causing exceptions when React attempted to unmount the span which no longer existed.
        node.parentElement.insertAdjacentHTML("beforeend", sanitizeHtml(value))
    }, [value]);

    return <span style={{ display: "none" }} key={value} ref={spanRefCallback} />;
});
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { publicRoutes } from "pages/Routes";
import { checkStoriedAccount, getFamilySearchAccessToken } from "redux/actions/gedcom";

import Loader from "components/Loader";
import {
  setFamilySearchAuthCode,
  setFamilySearchIdToken,
  setFamilySearchRefreshToken,
  setFamilySearchToken,
  setUserFlow
} from "services";
import { REACT_APP_ENV_URL } from "utils/env";

const RedirectToLogin = () => {
  const { loginWithRedirect: auth0LoginWithRedirect, isAuthenticated } = useAuth0();
  const locationUse = useLocation();
  const history = useHistory();
  const locationName = locationUse.pathname;
  const publicRoute = publicRoutes.find((route) => route.path === locationName);
  const search = locationUse?.search;
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");

  useEffect(() => {
    if (isAuthenticated) return;
    if (!publicRoute) {
      setUserFlow("signIn");
      if (locationName === "/login") {
        auth0LoginWithRedirect();
      } else if (locationName === "/link-accounts") {
        auth0LoginWithRedirect({
          authorizationParams: {
            roleOptions: "hideSignUpSocial"
          },
          appState: {
            returnTo: `/accounts${search}`
          }
        });
      } else if (locationName === "/signup-with-familysearch") {
        async function checkAccount() {
          if (code) {
            setFamilySearchAuthCode(code);
            const FSAccessToken = await getFamilySearchAccessToken(code);
            setFamilySearchToken(FSAccessToken?.access_token);
            setFamilySearchRefreshToken(FSAccessToken?.refresh_token);
            if (FSAccessToken) {
              const decodedToken = jwt_decode(FSAccessToken?.id_token);
              if (decodedToken) {
                setFamilySearchIdToken(FSAccessToken?.id_token);
                const isStoried = await checkStoriedAccount(decodedToken?.sub);
                if (isStoried) {
                  //Fs email is on storied so we redirect them to LOHP
                  history.push("/");
                } else {
                  //Fs user is affliated and is not on storied so we redirect them to signup
                  if (decodedToken?.qualifies_for_affiliate_account === "true") {
                    auth0LoginWithRedirect({
                      authorizationParams: {
                        roleOptions: "fsAccountCreation",
                        screen_hint: "signup"
                      },
                      appState: {
                        returnTo: `/import-family-search-tree?code=${code}`
                      }
                    });
                  }
                  //Fs user is not affliated so we redirect them to LOHP
                  else {
                    history.push("/");
                  }
                }
              }
            }
          } else {
            const url = `https://ident.familysearch.org/cis-web/oauth2/v3/authorization?response_type=code&scope=openid%20profile%20email%20qualifies_for_affiliate_account%20country%20offline_access&client_id=QVB8-LX1H-ZB2S-MRL6-CVPA-NDDN-XHCO-BUZL&redirect_uri=${REACT_APP_ENV_URL}/signup-with-familysearch`;
            window.open(url, "_self", `width=399,height=759`);
          }
        }
        checkAccount();
      }
    } else {
      auth0LoginWithRedirect({
        appState: {
          returnTo: `${locationName}${search}`
        }
      });
    }
  }, [auth0LoginWithRedirect, history, locationName, publicRoute, search, isAuthenticated, code]);

  return (
    <>
      <Loader />
    </>
  );
};

export default RedirectToLogin;

import * as CONSTANTS from "../constants/actionTypes";
import { SnackbarMessage } from "redux/actions/toastr";
const initialState: { message: SnackbarMessage[] } = {
    message: []
};

type Action = {
    type: typeof CONSTANTS.ADDTMESSAGE,
    payload: SnackbarMessage
} | {
    type: typeof CONSTANTS.REMOVETMESSAGE,
    payload: any
};

export default function toastr(state = initialState, { type, payload }: Action) {
    switch (type) {
        case CONSTANTS.ADDTMESSAGE:
            let message = state.message
            if (message.length >= 3) {
                message = state.message.slice(0, 2)
            }
            return {
                ...state,
                message: [payload, ...message],
            };
        case CONSTANTS.REMOVETMESSAGE:
            return {
                ...state,
                message: state.message.filter((_message) => _message.key !== payload),
            };
        default:
            return state;
    }
}
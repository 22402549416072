import { lazy } from "react";
const ViewStoriesV1 = lazy(() => import("pages/StoriesLayoutV1/ViewStoryV1"));
// contexts

const StoriesView = ({
  showStory,
  handleModal,
  previewShareable = false,
  storySharePreview = false
}) => {
  if (!showStory) return null;
  const props = {
    handleModal: handleModal,
    tweaks: true,
    previewStoryBool: true,
    previewShareable: previewShareable,
    storySharePreview
  };
  return <ViewStoriesV1 {...props} />;
};

export default StoriesView;

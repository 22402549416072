const pageData = [
  {
    title: "",
    date: "",
    place: "",
    content: "",
    recipesAdditionalDetails: "",
    recipesDirections: "",
    recipesIngredients: "",
  },
  { person: [], storyCategories: [], NamedAuthor: [] },
  { groupIds: [] },
];
const removeFlag = {
  person: "RemoveAllPersonsInStory",
  NamedAuthor: "RemoveAllNamedAuthorInStory",
  storyCategories: "RemoveAllStoryCategories",
  pets: "RemoveAllPetsInStory",
  groupIds: "RemoveAllGroupIds",
};
export function createChangeField({ formik, step, selectedFile, additionalImages }) {
  const fields = pageData[step];
  for (let field in fields) {
    const isField = formik.values[field] || formik.values[field] === "";
    if (isField) {
      const vaildForArrayFeild =
        Array.isArray(formik.values[field]) && !formik.values[field]?.length && field in removeFlag;
      if (vaildForArrayFeild) {
        fields[removeFlag[field]] = true;
      } else if (Array.isArray(formik.values[field])) {
        delete fields[removeFlag[field]];
      }
      fields[field] = formik.values[field];
    }
  }
  if (step === 0 ) {
    fields.files = selectedFile;
  } else if (step === 0 && !selectedFile?.length) {
    fields.files = [];
  }
  if (step === 0 && additionalImages?.length) {
    fields.additionalImages = additionalImages;
  } else if (step === 0 && !additionalImages?.length) {
    fields.additionalImages = [];
  }
  return fields;
}

export function isMediaChanges(currentFile, existFile) {
  if ((currentFile || [])?.length !== (existFile || [])?.length) {
    return true;
  }
  for (let i = 0; i < currentFile.length; i++) {
    const isNewImage = existFile?.find(({ mediaId }) => currentFile[i].mediaId !== mediaId);
    if (isNewImage) return true;
  }
  return false;
}

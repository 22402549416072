import axios from "axios";
import { getFamilySearchToken } from "services";

const BASE_URL = "https://ident.familysearch.org/cis-web/oauth2/v3";

export const apiFamilySearchRequest = (method, url, data, onUploadProgress, source) => {
  const sourceJSON = source
    ? {
        cancelToken: source.token
      }
    : {};

  const headers = {
    Accept: "application/json"
  };

  return axios({
    method,
    url: `${BASE_URL}${url}`,
    headers,
    data,
    ...sourceJSON,
    onUploadProgress: function (progressEvent) {
      if (onUploadProgress) onUploadProgress(progressEvent);
    }
  });
};

export const apiFamilySearchApiRequest = (method, url, data, onUploadProgress, source) => {
  const access_token = getFamilySearchToken();
  const bearer = `Bearer ${access_token}`;
  const sourceJSON = source
    ? {
        cancelToken: source.token
      }
    : {};

  const headers = {
    Accept: "application/json",
    Authorization: bearer
  };

  return axios({
    method,
    url: `${url}`,
    headers,
    data,
    ...sourceJSON,
    onUploadProgress: function (progressEvent) {
      if (onUploadProgress) onUploadProgress(progressEvent);
    }
  });
};

import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import store from "redux/store";
import "./App.css";
import ErrorWrapper from "./ErrorWrapper";

const App = () => {
  // listener to remove pop up of pedigree view when user navigates screen directly mean without closing pop up window.
  useEffect(() => {
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handlePopState = () => {
    const simplePopOver = document.getElementById("simple-popover");
    if (simplePopOver) simplePopOver.remove();
  };

  return (
    <>
      <Helmet>
        <title>Storied</title>
        <meta name="description" content="" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Provider store={store}>
        <Sentry.ErrorBoundary fallback={({ error }) => <ErrorWrapper appErrorState={error} />}>
          <ErrorWrapper />
        </Sentry.ErrorBoundary>
      </Provider>
      <div id="graph-backdrop"></div>
    </>
  );
};

export default App;

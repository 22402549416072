/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActivationCodesRedeemActivationCodeData,
  ActivationCodesRedeemActivationCodeParams,
  AdminActivationCodesActivationCodeDetailsData,
  AdminActivationCodesBulkCancelActivationCodesData,
  AdminActivationCodesBulkCancelActivationCodesPayload,
  AdminActivationCodesCancelActivationCodeData,
  AdminActivationCodesCancelActivationCodeParams,
  AdminActivationCodesGenerateActivationCodesData,
  AdminActivationCodesGenerateActivationCodesParams,
  AdminActivationCodesReserveActivationCodesData
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ActivationCodes extends HttpClient {
  /**
   * No description
   *
   * @tags ActivationCodes
   * @name ActivationCodesRedeemActivationCode
   * @request POST:/api/ActivationCodes/redeem-activation-code
   * @secure
   */
  activationCodesRedeemActivationCode = (
    query: ActivationCodesRedeemActivationCodeParams,
    params: RequestParams = {}
  ) =>
    this.request<ActivationCodesRedeemActivationCodeData, any>({
      path: `/api/ActivationCodes/redeem-activation-code`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags ActivationCodes
   * @name AdminActivationCodesGenerateActivationCodes
   * @request POST:/api/admin/ActivationCodes/generate-activation-codes
   * @secure
   */
  adminActivationCodesGenerateActivationCodes = (
    query: AdminActivationCodesGenerateActivationCodesParams,
    params: RequestParams = {}
  ) =>
    this.request<AdminActivationCodesGenerateActivationCodesData, any>({
      path: `/api/admin/ActivationCodes/generate-activation-codes`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags ActivationCodes
   * @name AdminActivationCodesReserveActivationCodes
   * @request GET:/api/admin/ActivationCodes/reserve-activation-codes/{itemCode}/{numberOfCodes}
   * @secure
   */
  adminActivationCodesReserveActivationCodes = (
    itemCode: string,
    numberOfCodes: number,
    params: RequestParams = {}
  ) =>
    this.request<AdminActivationCodesReserveActivationCodesData, any>({
      path: `/api/admin/ActivationCodes/reserve-activation-codes/${itemCode}/${numberOfCodes}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags ActivationCodes
   * @name AdminActivationCodesCancelActivationCode
   * @request POST:/api/admin/ActivationCodes/cancel-activation-code
   * @secure
   */
  adminActivationCodesCancelActivationCode = (
    query: AdminActivationCodesCancelActivationCodeParams,
    params: RequestParams = {}
  ) =>
    this.request<AdminActivationCodesCancelActivationCodeData, any>({
      path: `/api/admin/ActivationCodes/cancel-activation-code`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags ActivationCodes
   * @name AdminActivationCodesBulkCancelActivationCodes
   * @request POST:/api/admin/ActivationCodes/bulk-cancel-activation-codes
   * @secure
   */
  adminActivationCodesBulkCancelActivationCodes = (
    data: AdminActivationCodesBulkCancelActivationCodesPayload,
    params: RequestParams = {}
  ) =>
    this.request<AdminActivationCodesBulkCancelActivationCodesData, any>({
      path: `/api/admin/ActivationCodes/bulk-cancel-activation-codes`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags ActivationCodes
   * @name AdminActivationCodesActivationCodeDetails
   * @request GET:/api/admin/ActivationCodes/activation-code-details/{adminActivationCode}
   * @secure
   */
  adminActivationCodesActivationCodeDetails = (
    adminActivationCode: string,
    params: RequestParams = {}
  ) =>
    this.request<AdminActivationCodesActivationCodeDetailsData, any>({
      path: `/api/admin/ActivationCodes/activation-code-details/${adminActivationCode}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

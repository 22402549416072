import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";
import GroupBookIcon from "assets/images/groupbook-icon.svg";

import { tr } from "components/utils";

const PaywallTemp = ({ freeAccountModal }) => {
  const { t } = useTranslation();
  const subtitle = freeAccountModal ? "shareStory.createAFreeAccount" : "shareStory.paywallPage";
  const para = freeAccountModal
    ? "shareStory.signUpToLikeCommentShare"
    : "shareStory.loggedInUsersPaywallPage";

  return (
    <div>
      <div className="vector flex w-full justify-center mb-8">
        <img
          src={GroupBookIcon}
          alt={tr(t, "shareStory.welcomeToStoried")}
        />
      </div>
      <div className="create-info text-center mb-8 w-full flex flex-col mx-auto">
        <h3 className="mb-2.5 text-gray-7 text-2xl mb-1.5 typo-font-bold">{tr(t, subtitle)}</h3>
        <p className="info-sp-block">
          <Typography
            size={16}
            text="secondary"
          >
            {tr(t, para)}
          </Typography>
        </p>
      </div>
    </div>
  );
};
export default PaywallTemp;

import Translator from "../../components/Translator";

export const familyMenu = [
  {
    id: 7,
    name: <Translator tkey="person.menu.parent" />
  },
  {
    id: 8,
    name: <Translator tkey="person.menu.spouse" />
  },
  {
    id: 9,
    name: <Translator tkey="person.menu.sibling" />
  },
  {
    id: 10,
    name: <Translator tkey="person.menu.child" />
  }
];

export const lifeEventsMenu = [
  {
    id: 11,
    name: "Birth"
  },
  {
    id: 12,
    name: "Marriage"
  },
  {
    id: 13,
    name: "Death"
  },
  {
    id: 14,
    name: "Adoption"
  },
  {
    id: 15,
    name: "Annulment"
  },
  {
    id: 16,
    name: "Arrival"
  },
  {
    id: 17,
    name: "Baptism"
  },
  {
    id: 18,
    name: "Bar Mitzvah"
  },
  {
    id: 42,
    name: "Bat Mitzvah"
  },
  {
    id: 44,
    name: "Blessing"
  },
  {
    id: 19,
    name: "Burial"
  },
  {
    id: 20,
    name: "Christening"
  },
  {
    id: 41,
    name: "Circumcision"
  },
  {
    id: 50,
    name: "Confirmation"
  },
  {
    id: 48,
    name: "Cremation"
  },
  {
    id: 21,
    name: "Departure"
  },
  {
    id: 22,
    name: "Destination"
  },
  {
    id: 23,
    name: "Divorce"
  },
  {
    id: 24,
    name: "Divorce Filed"
  },
  {
    id: 25,
    name: "Education"
  },
  {
    id: 26,
    name: "Emigration"
  },
  {
    id: 27,
    name: "Employment"
  },
  {
    id: 28,
    name: "Engagement"
  },
  {
    id: 51,
    name: "Excommunication"
  },
  {
    id: 29,
    name: "First Communion"
  },
  {
    id: 43,
    name: "Funeral"
  },
  {
    id: 30,
    name: "Graduation"
  },
  {
    id: 31,
    name: "Immigration"
  },
  {
    id: 32,
    name: "Marriage Bann"
  },
  {
    id: 33,
    name: "Marriage Contract"
  },
  {
    id: 34,
    name: "Marriage License"
  },
  {
    id: 35,
    name: "Marriage Settlement"
  },
  {
    id: 36,
    name: "Military"
  },
  {
    id: 45,
    name: "Mission"
  },
  {
    id: 37,
    name: "Naturalization"
  },
  {
    id: 46,
    name: "Ordination"
  },
  {
    id: 38,
    name: "Probate"
  },
  {
    id: 39,
    name: "Residence"
  },
  {
    id: 47,
    name: "Retirement"
  },
  {
    id: 40,
    name: "Separation"
  },
  {
    id: 49,
    name: "Will"
  }
];

export const nonSelectableMenu = [
  {
    id: 1,
    name: "Birth"
  },
  {
    id: 2,
    name: "Adoption"
  }
];

export const photoMenu = [
  {
    id: 1,
    name: <Translator tkey="person.menu.photo.viewProfilePhoto" />
  },
  {
    id: 2,
    name: <Translator tkey="person.menu.photo.resizeProfilePhoto" />
  },
  {
    id: 3,
    name: <Translator tkey="person.menu.photo.chooseNewProfilePhoto" />
  },
  {
    id: 4,
    name: <Translator tkey="person.menu.photo.removeProfilePhoto" />
  }
];
export const accountMenu = [
  {
    id: 1,
    icon: "setting",
    name: <Translator tkey="accountMenu.Settings" />
  },
  {
    id: 2,
    icon: "help",
    name: <Translator tkey="accountMenu.Help" />
  },
  {
    id: 3,
    icon: "logout",
    name: <Translator tkey="accountMenu.Logout" />
  }
];

export const settingsMenu = [
  {
    id: 1,
    icon: "profile-circle",
    title: "settingsMenu.Profile",
    name: "profile"
  },
  {
    id: 2,
    icon: "trees",
    title: "settingsMenu.Trees",
    name: "trees"
  },
  {
    id: 3,
    icon: "groups",
    title: "settingsMenu.Groups",
    name: "groups"
  },
  {
    id: 4,
    icon: "card",
    title: "settingsMenu.Billing",
    name: "billing"
  },
  {
    id: 5,
    icon: "envolope",
    title: "settingsMenu.Communication",
    name: "communication"
  }
];

export const addFamilyMenu = [
  {
    id: 4,
    name: <Translator tkey="pedigree.actions.add.spouse" />,
    elementId: "add-spouse"
  },
  {
    id: 5,
    name: <Translator tkey="pedigree.actions.add.child" />,
    elementId: "add-child"
  },
  {
    id: 7,
    name: <Translator tkey="pedigree.actions.add.sibling" />,
    elementId: "add-sibling"
  },
  {
    id: 6,
    name: <Translator tkey="pedigree.actions.add.parent" />,
    elementId: "add-parent"
  }
];

export const heroPhotoMenu = [
  {
    id: 1,
    name: <Translator tkey="person.menu.photo.viewBackgroundPhoto" />
  },
  {
    id: 2,
    name: <Translator tkey="person.menu.photo.resizeBackgroundPhoto" />
  },
  {
    id: 3,
    name: <Translator tkey="person.menu.photo.chooseNewBackgroundPhoto" />
  },
  {
    id: 4,
    name: <Translator tkey="person.menu.photo.removeBackgroundPhoto" />
  }
];
export const stickyPersonHeaderMenu = [
  {
    id: 4,
    name: "Delete Person"
  }
];

export const stickyHeaderPetMenu = [
  {
    id: 1,
    name: "Delete Pet"
  }
];
export const rowInteractionMenu = (name) => {
  return [
    {
      id: 1,
      name: `View ${name}`
    },
    {
      id: 2,
      name: <Translator tkey="person.menu.ViewTree" />
    },
    {
      id: 3,
      name: <Translator tkey="person.menu.deletePerson" />
    }
  ];
};

export const rowInteractionMenuForEvent = [
  { id: 4, name: <Translator tkey="person.menu.deleteEvent" /> }
];

export const mobileRowInteractionMenu = (name) => {
  return [
    {
      id: 5,
      name: <Translator tkey="person.menu.editName" />
    },
    {
      id: 1,
      name: `View ${name}`
    },
    {
      id: 2,
      name: <Translator tkey="person.menu.ViewTree" />
    },
    {
      id: 3,
      name: <Translator tkey="person.menu.deletePerson" />
    }
  ];
};

export const searchTabMenu = [
  {
    id: 1,
    itemIcon: "recordSearch",
    itemName: <Translator tkey="loggedoutHomepage.nav.HistoricalSearch" />,
    navigateTo: "/historical-record-search"
  },
  {
    id: 2,
    itemIcon: "newspaperSearch",
    itemName: <Translator tkey="loggedoutHomepage.nav.NewspaperSearch" />,
    navigateTo: "/newspaper-search"
  }
];

export const peopleTabMenu = [
  {
    id: 1,
    itemIcon: "mIconTree",
    itemName: <Translator tkey="loggedoutHomepage.nav.FamilyTrees" />,
    navigateTo: "/family-trees"
  },
  {
    id: 2,
    itemIcon: "people",
    itemName: <Translator tkey="loggedoutHomepage.nav.Connections" />,
    navigateTo: "/people"
  }
];

export const communicationMenu = [
  {
    id: 1,
    key: "Send hourly",
    value: "1",
    title: "settings.communication.menus.SendHourly"
  },
  {
    id: 2,
    key: "Send daily",
    value: "2",
    title: "settings.communication.menus.SendDaily"
  },
  {
    id: 3,
    key: "Send weekly",
    value: "3",
    title: "settings.communication.menus.SendWeekly"
  },
  {
    id: 4,
    key: "Send monthly",
    value: "4",
    title: "settings.communication.menus.SendMonthly"
  }
];

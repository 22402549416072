import { GROUP_STORY_LIST_UPDATE_REDUX, OWN_STORY_LIST_UPDATE_REDUX } from "redux/constants";
import { getOwner } from "services";
import { BLANKID } from "utils";
import { REACT_APP_MEDIA_URL } from "utils/env";

function buildMediaObj(file, index, layoutId) {
  const extension = file?.file?.name?.split(".")?.pop() || "png";
  const MediaBaseUrl = REACT_APP_MEDIA_URL;
  const obj = {
    orderNumber: index,
    originatingMediaId: file.mediaId,
    originatingMediaURL:
      file?.imageURL ||
      `${MediaBaseUrl}/storied-user-content/${getOwner()}/images/${file.mediaId}.${extension}`
  };
  const croppedInfo =
    layoutId && layoutId !== BLANKID ? file?.mediaObj?.[layoutId] : file?.mediaObj;
  if (file.isCrop && croppedInfo?.MediaId) {
    obj.mediaId = croppedInfo?.MediaId;
    obj.croppingInfo = {
      cropX: croppedInfo?.cropCordinates?.x,
      cropY: croppedInfo?.cropCordinates?.y,
      height: croppedInfo?.cropCordinates?.height,
      width: croppedInfo?.cropCordinates?.width,
      zoomAspect: croppedInfo?.zoomLevel
    };
    const fileExtensioArr = croppedInfo?.file?.name?.split(".") || croppedInfo?.url?.split(".");
    const fileExtensionPop = fileExtensioArr?.length <= 1 ? "" : fileExtensioArr?.pop();
    const fileExtension = fileExtensionPop && `.${fileExtensionPop}`;
    obj.url = `${MediaBaseUrl}/storied-user-content/${getOwner()}/images/${croppedInfo?.MediaId}${
      fileExtension || ".png"
    }`;
  } else {
    obj.mediaId = file.mediaId;
    obj.url =
      file?.imageURL ||
      file?.mediaUrl ||
      `${MediaBaseUrl}/storied-user-content/${getOwner()}/images/${file.mediaId}.${extension}`;
    obj.croppingInfo = null;
  }
  return obj;
}
const updateInGroup = (state, payload) => {
  const groupStories = state.groupStories || [];
  const groupIds = payload.groupIds || [];
  const updateinGroupCond =
    groupIds.includes(state.lastVisitedGroup) &&
    !groupStories.find((y) => y.storyId === payload.storyId);
  if (updateinGroupCond) {
    groupStories.unshift(payload);
  }
};
export function ownStoryUpdateRedux({
  dispatch,
  storyId,
  values,
  files,
  additionalImages,
  editMode
}) {
  const primaryPersonId = values?.person?.find(({ petType }) => !petType)?.id || "";
  const payload = {
    authorId: values.authorId || getOwner(),
    content: values.content || "",
    contributors: [getOwner()],
    createdDate: new Date(),
    date: values.date,
    groupIds: values.groupIds,
    layoutId: values.layoutId || BLANKID,
    likes: [],
    location: values.place.name,
    locationId: values.place.id,
    personDetail: {
      id: BLANKID,
      ownerId: BLANKID,
      givenName: null,
      surname: null,
      gender: null,
      profileImageUrl: null,
      totalCount: 0
    },
    petsInStory: {
      id: BLANKID,
      ownerId: BLANKID,
      givenName: null,
      surname: null,
      gender: null,
      profileImageUrl: null,
      petType: "",
      totalCount: 0
    },
    primaryPersonId,
    privacy: values.privacy,
    status: values.status,
    storyAudio: [],
    storyPdfs: [],
    storyCategories: values.storyCategories,
    storyExternalImages: [],
    storyId: storyId,
    storyImages: [],
    additionalStoryImages: [],
    storyVideos: [],
    title: values.title || "",
    topics: [],
    updatedDate: new Date(),
    totalImages: 0
  };
  if (values?.person?.length) {
    const personDetail = values?.person.filter((y) => !y.petType);
    const petsInStory = values?.person.filter((y) => y.petType);
    payload.personDetail = {
      id: personDetail[0]?.id,
      ownerId: personDetail[0]?.ownerId,
      givenName: personDetail[0]?.givenName,
      surname: personDetail[0]?.surname,
      gender: personDetail[0]?.gender,
      profileImageUrl: personDetail[0]?.profileImageUrl,
      totalCount: personDetail.length ?? 0
    };
    payload.petsInStory = {
      id: petsInStory[0]?.id,
      ownerId: petsInStory[0]?.ownerId,
      givenName: petsInStory[0]?.givenName,
      gender: petsInStory[0]?.gender,
      profileImageUrl: petsInStory[0]?.profileImageUrl,
      petType: petsInStory[0]?.petType,
      totalCount: petsInStory.length ?? 0
    };
  }
  if (files?.length) {
    payload.storyImages.push(buildMediaObj(files[0], 0, values?.layoutId));
  }
  if (files?.length || additionalImages?.length) {
    payload.totalImages = files.length + additionalImages.length;
  }
  payload.editMode = editMode;
  dispatch({ type: OWN_STORY_LIST_UPDATE_REDUX, payload });
  if (values.status === "Published") {
    dispatch({ type: GROUP_STORY_LIST_UPDATE_REDUX, payload });
  }
}

export function updateOwnStroiesList(state, payload) {
  const existOwnStoriesIndex = state.ownStories.findIndex((y) => y.storyId === payload.storyId);
  if (existOwnStoriesIndex < 0) {
    if (!payload.editMode) {
      delete payload.editMode;
      state.ownStoryOffset = state.ownStoryOffset + 1;
      state.ownStories.unshift(payload);
    }
  } else {
    updateInGroup(state, payload);
    state.ownStories[existOwnStoriesIndex] = payload;
  }
  return { ...state };
}

export function updateGroupStoriesList(state, payload) {
  if (payload.editMode) {
    const storyPrevState = state.groupStories.findIndex((y) => y?.storyId === payload.storyId);
    if (storyPrevState > -1 && !payload.groupIds.includes(state.lastVisitedGroup)) {
      state.groupStories.splice(storyPrevState, 1);
    }
  }
  if (!payload.groupIds.includes(state.lastVisitedGroup)) return { ...state };
  const existGroupStoriesIndex = state.groupStories.findIndex((y) => y.storyId === payload.storyId);
  if (existGroupStoriesIndex < 0) {
    if (!payload.editMode) {
      delete payload.editMode;
      state.groupStoryOffset = state.groupStoryOffset + 1;
      state.groupStories.unshift(payload);
    }
  } else {
    state.groupStories[existGroupStoriesIndex] = payload;
  }

  return { ...state };
}

export const updateRetryFailedStory = (state, payload) => {
  const updateKey = payload?.updateKey;
  if (updateKey) {
    const mapStories = state[updateKey].map((story) => {
      if (story.apiError && story.storyId === payload.storyId) {
        delete payload?.updateKey;
        return payload;
      } else {
        return story;
      }
    });
    state[updateKey] = mapStories;
  }
  return {...state};
};

export const updateUnfollowAuthorStory=(state,payload)=>{
  state.stories=state.stories.filter((story) =>story?.authorId !== payload.userId);
  return {...state};
};
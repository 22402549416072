import React, { useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import "../Privacy/index.css";
import { setVisitorIDAccess } from "services";
import {
  addPreviewerAPI,
  getSharedStoryIDUsingInvitationID,
  userAssociationToStory,
} from "redux/actions/story";
import useIndex from "pages/ShareStory/Hooks/useIndex";

import FreeAccountModal from "pages/ShareStory/FreeAccountModal";
import Loader from "components/Loader";
import LoggedOutHeader from "components/Header/LoggedOutHeader";
import Waiting from "pages/ShareStory/Components/Waiting";


const ShareStory = () => {
  let {
    history,
    dispatch,
    messageID,
    openWaitList,
    setOpenWaitList,
    step,
    showAccountModal,
    setShowAccountModal,
    isAccessTokenTerms,
    visitorID,
    setStepFunction,
    sharedStory,
    handleLoginFunc,
    handleSignupFunc
  } = useIndex();
  const { storyId } = sharedStory || {};
  useEffect(() => {
    if (!visitorID) {
      visitorID = uuidv4();
      setVisitorIDAccess(visitorID);
    }
    if (messageID) {
      dispatch(getSharedStoryIDUsingInvitationID(messageID, visitorID));
    }
  }, []);

  useEffect(() => {
    const { storyPreviewStatusId } = sharedStory || {};
    if (storyPreviewStatusId === 1) {
      previewStatusFuncOne();
    } else if (storyPreviewStatusId === 2) {
      userAssociationFunc();
    } else if (storyPreviewStatusId === 3) {
      previewStatusFuncThree();
    }
  }, [sharedStory]);

  const userAssociationFunc = () => {
    if (isAccessTokenTerms) {
      dispatch(userAssociationToStory(storyId)).then((res) => {
        if (res) {
          const storyUrl = "view";
          history.replace(`/stories/${storyUrl}/0/${storyId}`);
        }
      });
    } else {
      history.replace(`/story-preview/${storyId}`);
    }
  };

  const previewStatusFuncOne = () => {
    if (visitorID && messageID && sharedStory) {
      const giveRightsAPIFormData = {
        invitationId: messageID,
        storyId,
        visitorId: visitorID,
      };
      dispatch(addPreviewerAPI(giveRightsAPIFormData)).then((response) => {
        if (response) {
          userAssociationFunc();
        }
      });
    }
  };

  const previewStatusFuncThree = () => {
    if (!isAccessTokenTerms) {
      setOpenWaitList(true);
    } else {
      if (sharedStory?.storyId) {
        const storyUrl = "view";
        history.replace(`/stories/${storyUrl}/0/${storyId}`);
      }
    }
  };

  const commonProps = {
    handleLogin: handleLoginFunc,
    handleSignup: handleSignupFunc,
  };

  const waitingProps = {
    alreadyViewed: true,
    setStepFunction,
    step,
    storyId,
    openWaitList,
    setOpenWaitList,
    ...commonProps,
  };

  return (
    <div>
      <LoggedOutHeader {...commonProps} />
      <Loader />
      <Waiting waitingProps={waitingProps} />
      <FreeAccountModal
        setShowAccountModal={setShowAccountModal}
        show={showAccountModal}
        {...commonProps}
      />
    </div>
  );
};

export default ShareStory;

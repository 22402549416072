import { create } from "zustand";

interface useImportFamilySearchState {
  isSharedChecked: boolean;
  setIsSharedChecked: (value: boolean) => void;
}

export const useImportFamilySearchStore = create<useImportFamilySearchState>((set) => ({
  isSharedChecked: true,
  setIsSharedChecked: (value: boolean) => set({ isSharedChecked: value }),
}));
export const IMAGE = "Image";
export const EXTERNAL_IMAGE = "ExternalImage";
export const NA_CLIPPING = "NaClipping";
export const NACLIPPING = "NAClipping";
export const AUDIO = "Audio";
export const PDF = "Pdf";
export const RECORD = "Record";
export const PET = "pet";
export const PERSON = "person";
export const GROUP = "group";
export const USER = "user";
export const FILTER_ALL = "All Media";
export const FILTER_PHOTOS = "Photos";
export const FILTER_AUDIO = "Audio";
export const FILTER_PDF = "PDF";
export const FILTER_NEWSPAPERS = "Newspapers";
export const FILTER_RECORDS = "Records";

export const flattenMediaList = (
  mediaList: any[],
  activeFilter: string | boolean,
  activePersonFilterId: string | null,
  isPerson: boolean = false
) => {
  const mediaItems = mediaList?.map((page: { mediaDetails: any }) => page.mediaDetails).flat();
  let filteredItems = [];
  const acceptedTypes = isPerson
    ? `${IMAGE},${EXTERNAL_IMAGE},${AUDIO},${PDF},${RECORD}`
    : `${IMAGE},${EXTERNAL_IMAGE},${AUDIO},${PDF}`;
  filteredItems = mediaItems?.filter((item: { type: string }) =>
    acceptedTypes.includes(item?.type)
  );
  if (activeFilter === FILTER_PHOTOS)
    filteredItems = mediaItems?.filter((item: { type: string }) => item?.type === IMAGE);
  else if (activeFilter === FILTER_AUDIO)
    filteredItems = mediaItems?.filter((item: { type: string }) => item?.type === AUDIO);
  else if (activeFilter === FILTER_PDF)
    filteredItems = mediaItems?.filter((item: { type: string }) => item?.type === PDF);
  else if (activeFilter === FILTER_NEWSPAPERS)
    filteredItems = mediaItems?.filter((item: { type: string }) => item?.type === EXTERNAL_IMAGE);
  else if (activeFilter === FILTER_RECORDS)
    filteredItems = mediaItems?.filter((item: { type: string }) => item?.type === RECORD);

  if (activePersonFilterId)
    filteredItems = filteredItems.filter((item: { taggedPersons: any[] }) =>
      item?.taggedPersons?.find(
        (person: { personId: any }) => person.personId === activePersonFilterId
      )
    );
  return filteredItems?.filter((item) => item.title !== "FamilySearch Family Tree");
};

interface TaggedPerson {
  personId: string;
  givenName: string;
  surname: string;
  treeId: string;
  treeName: string;
}

interface MediaDetail {
  taggedPersons: TaggedPerson[];
}

interface PageData {
  mediaDetails: MediaDetail[];
}

export function getAllUniqueTaggedPersons(data: PageData[] = []): TaggedPerson[] | string {
  const allTaggedPersons = data.flatMap(
    (page) => page?.mediaDetails?.flatMap((detail) => detail.taggedPersons) || []
  );

  const uniqueTaggedPersons = allTaggedPersons.reduce((acc, person) => {
    if (person && !acc.some((p) => p && p.personId === person.personId)) {
      acc.push(person);
    }
    return acc;
  }, [] as TaggedPerson[]);

  return uniqueTaggedPersons.length > 0 ? uniqueTaggedPersons : [];
}

export const getMediaViewerBaseUrl = (collectionType: string) => {
  let url = "/my-scrapbook/media";
  if (collectionType === PET) {
    url = "/family/pet-page/scrapbook/media";
  } else if (collectionType === GROUP) {
    url = "/groups/storied/scrapbook/media";
  } else if (collectionType === PERSON) {
    url = "/family/person-page/scrapbook/media";
  }
  return url;
};

export function applyOpacityAndPointerEvents(
  validSelectedFiles: any[],
  media: { clonedMediaId: any; id: any }
) {
  const conditionResult = validSelectedFiles.some(
    (file: { mediaId: any; originatingMediaId: any }) =>
      file.mediaId === media.clonedMediaId ||
      file.mediaId === media.id ||
      file.originatingMediaId === media.id
  );
  return {
    "opacity-50 pointer-events-none": conditionResult,
    "opacity-1": !conditionResult
  };
}

import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";


const ShareStoryRoutes = ({ component: Component, ...rest }) => {
  const locationUse = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (locationUse.hash.search("#state") !== -1) {
      history.replace("/");
    }
  }, [locationUse.hash]);
  return (
    <Route
      {...rest}
      render={(props) => {
        return  <Component {...props} /> ;
      }}
    />
  );
};

export default ShareStoryRoutes;

import { useState } from "react";

export const useMouseEventHandler = () => {
  const [focusFlag, setFocusFlag] = useState(false);

  const onMouseEnterHandler = (e) => {
    e?.currentTarget?.focus();
    setFocusFlag(true);
  };

  const onMouseLeaveHandler = () => {
    setFocusFlag(false);
    let ele = document.querySelector("#menu-list-grow, #header-menu-list, #interaction-menu ul");
    ele?.focus();
  };
  
  return { focusFlag, onMouseEnterHandler, onMouseLeaveHandler };
};

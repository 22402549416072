import { removeGroupPage, removeOfferEvent, removeRegisterType, removeSearchCollectionId } from "services";
import {
  BACK_END,
  EARLY,
  ECOMMERCE,
  NO_ACCOUNT,
  STORIED,
  TWO_FOR_ONE_USERTYPE,
  USD,
} from "utils/constant";

export const dataLayerregistration = (userId, regType) => {
  window.dataLayer = window.dataLayer || [];
  // dataLayerregistration
  window.dataLayer.push({
    event: "registration",
    regType,
    user_id: userId,
  });
  removeRegisterType();
  removeGroupPage();
};

export const dataLayerOffer = (source, type, userId = false, denyContentId = null) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "offer",
    event_category: "ecommerce",
    event_type: type,
    event_source: source,
    ...(userId && { user_id: userId }),
    ...(denyContentId && { deny_content_id: denyContentId }),
  });
  removeOfferEvent();
  removeSearchCollectionId();
};

export const dataLayerGroup = (userId, userType) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "group_creation",
    eventCategory: "engagement",
    eventAction: BACK_END,
    user_id: userId,
    brand: STORIED,
    userType: userType,
  });
};

export const dataLayerOfferModal = (userId, planname, userType) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.offer_modal",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          brand: "storied",
          name: planname,
          userType: userType,
          ...(userId && { user_id: userId }),
        },
      ],
    },
  });
};

export const dataLayerOfferPage = (userId, userType) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.offer_page",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          brand: STORIED,
          userType: userType,
          ...(userId && { user_id: userId }),
        },
      ],
    },
  });
};

export const dataLayerDurationPage = (userId, userType) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.duration_page",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          brand: STORIED,
          userType: userType ? userType : NO_ACCOUNT,
          ...(userId && { user_id: userId }),
        },
      ],
    },
  });
};

export const dataLayerPaymentDetails = (
  userId,
  planId,
  name,
  amount,
  duration,
  userType,
  planName
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.payment_page",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          id: planId,
          ...(name && { name: name }),
          brand: STORIED,
          quantity: 1,
          ...(amount && { price: amount }),
          ...(duration && { duration: duration }),
          userType: planName === EARLY ? TWO_FOR_ONE_USERTYPE : userType,
          user_id: userId,
        },
      ],
    },
  });
};

export const dataLayerOrderSummery = (
  userId,
  planId,
  selectPlanName,
  price,
  duration,
  userType,
  planName
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.order_summary_page",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          id: planId,
          ...(selectPlanName && { name: selectPlanName }),
          brand: STORIED,
          quantity: 1,
          ...(price && { price : price }),
          ...(duration && { duration: duration }),
          userType: planName === EARLY ? TWO_FOR_ONE_USERTYPE : userType,
          ...(userId && { user_id: userId }),
        },
      ],
    },
  });
};

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  UserOnBoardingCheckModulesEligibilityData,
  UserOnBoardingGroupitemStatusData,
  UserOnBoardingOnboardingChecklistData,
  UserOnBoardingProcessCompleteData,
  UserOnBoardingSearchitemStatusData,
  UserOnBoardingStorybookitemStatusData
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class UserOnBoarding extends HttpClient {
  /**
   * No description
   *
   * @tags UserOnBoarding
   * @name UserOnBoardingSearchitemStatus
   * @request PUT:/api/UserOnBoarding/searchitem-status
   * @secure
   */
  userOnBoardingSearchitemStatus = (params: RequestParams = {}) =>
    this.request<UserOnBoardingSearchitemStatusData, any>({
      path: `/api/UserOnBoarding/searchitem-status`,
      method: "PUT",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags UserOnBoarding
   * @name UserOnBoardingGroupitemStatus
   * @request PUT:/api/UserOnBoarding/groupitem-status
   * @secure
   */
  userOnBoardingGroupitemStatus = (params: RequestParams = {}) =>
    this.request<UserOnBoardingGroupitemStatusData, any>({
      path: `/api/UserOnBoarding/groupitem-status`,
      method: "PUT",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags UserOnBoarding
   * @name UserOnBoardingStorybookitemStatus
   * @request PUT:/api/UserOnBoarding/storybookitem-status
   * @secure
   */
  userOnBoardingStorybookitemStatus = (params: RequestParams = {}) =>
    this.request<UserOnBoardingStorybookitemStatusData, any>({
      path: `/api/UserOnBoarding/storybookitem-status`,
      method: "PUT",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags UserOnBoarding
   * @name UserOnBoardingProcessComplete
   * @request PUT:/api/UserOnBoarding/process-complete
   * @secure
   */
  userOnBoardingProcessComplete = (params: RequestParams = {}) =>
    this.request<UserOnBoardingProcessCompleteData, any>({
      path: `/api/UserOnBoarding/process-complete`,
      method: "PUT",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags UserOnBoarding
   * @name UserOnBoardingOnboardingChecklist
   * @request GET:/api/UserOnBoarding/onboarding-checklist
   * @secure
   */
  userOnBoardingOnboardingChecklist = (params: RequestParams = {}) =>
    this.request<UserOnBoardingOnboardingChecklistData, any>({
      path: `/api/UserOnBoarding/onboarding-checklist`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags UserOnBoarding
   * @name UserOnBoardingCheckModulesEligibility
   * @request GET:/api/UserOnBoarding/check-modules-eligibility
   * @secure
   */
  userOnBoardingCheckModulesEligibility = (params: RequestParams = {}) =>
    this.request<UserOnBoardingCheckModulesEligibilityData, any>({
      path: `/api/UserOnBoarding/check-modules-eligibility`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

import {
    GET,
    USER_ERROR,
    GET_ALL_TREES,
    SET_ALL_USER_TREES,
    GET_ALL_GROUPS,
    GET_ALL_PROJECTS,
    LEAVE_GROUP,
    CLEAR_GEDCOM,
    SET_HOMEPERSON_MODAL,
    UPDATE_ORDER_USER_GROUP,
    SET_IMPORTING_INPROGRESS
} from "../constants";
import { apiRequest } from "../requests";

export const getAllUserTreesList = (id, apiUrl = `Users/trees?numberOfTrees=5`) => async (dispatch) => {
    try {
        let requestData = { authorId: id };
        const response = await apiRequest(GET, apiUrl, requestData);
        const ownTrees = response?.data?.filter((tree) => tree.ownerId === id);
        response.ownUserTrees = ownTrees;

        dispatch({
            type: GET_ALL_TREES,
            payload: response
        });
    } catch (err) {
        dispatch({
            type: USER_ERROR,
            payload: { msg: err }
        });
    }
};

export const addToRecentTrees = (trees, treeId, startNewTree) => (dispatch) => {
    let foundTree = trees.filter(ele => ele.treeId === treeId);
    if (foundTree && foundTree.length) {
      let remainingTrees = trees.filter(ele => ele.treeId !== treeId);
      let firstPositionedTree = [...foundTree, ...remainingTrees];
      dispatch({
        type: GET_ALL_TREES,
        payload: firstPositionedTree
      });
    }
    else {
      let newTree = [{
        treeId: startNewTree?.treeId,
        homePersonId: startNewTree?.primaryPersonId,
        treeName: startNewTree?.treeName
      }];
      let newAddedTrees = [...newTree, ...trees];
      dispatch({
        type: GET_ALL_TREES,
        payload: newAddedTrees
      });
      dispatch({
        type: CLEAR_GEDCOM
      });
    }
  };

  export const showSetHomePersonModal = (person) => (dispatch) => {
    dispatch({
      type: SET_HOMEPERSON_MODAL,
      payload: person,
    });
  };

  export const setImportingInProgress = (inProgress) => (dispatch) => {
    dispatch({
      type: SET_IMPORTING_INPROGRESS,
      payload: inProgress
    });
  };

  export const getUserGroups = () => async (dispatch) => {
    try {
      const response = await apiRequest(GET, `Users/groups`);

      dispatch({
          type: GET_ALL_GROUPS,
          payload: response.data
      });
  } catch (err) {
      dispatch({
          type: USER_ERROR,
          payload: { msg: err }
      });
  }
  };

  export const getUserProjects = () => (dispatch) => {
    try {
      // const response = await apiRequest(GET, `Users/projects`);
      const response = [
        {
          url: "url",
          name: "My first project"
        },
        {
          url: "url",
          name: "My second project"
        },
        {
          url: "url",
          name: "My third project"
        },
        {
          url: "url",
          name: "My fourth project"
        }
      ];
      dispatch({
          type: GET_ALL_PROJECTS,
          payload: response
      });
  } catch (err) {
      dispatch({
          type: USER_ERROR,
          payload: { msg: err }
      });
  }
  };

  export const updateOrderUserGroup = (groupId, order = 0) => (dispatch) => {
    dispatch({
      type: UPDATE_ORDER_USER_GROUP,
      payload: {groupId, order},
    });
  };

  export const leaveUserGroup = (payload) => (dispatch) => {
    dispatch({
      type: LEAVE_GROUP,
      payload: payload,
    });
  };

  export const setAllUserTreesList = (payload) => (dispatch) => {
    try {
      dispatch({
        type: SET_ALL_USER_TREES,
        payload: payload
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: { msg: err }
      });
    }
  };
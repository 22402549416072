import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { getAccessToken, getLoggedInViaShareableID } from "services";
import Loader from "components/Loader";
// contexts
import { MainsPageContext } from "contexts";

const ShareableAfterAuth = () => {
  const history = useHistory();
  const isAccessTokenTerms = getAccessToken();
  const storyId = getLoggedInViaShareableID();

  useEffect(() => {
    if (storyId && isAccessTokenTerms) {
      const storyUrl = "view";
      history.replace(`/stories/${storyUrl}/0/${storyId}`);
    }
  }, [history, isAccessTokenTerms, storyId]);

  return <Loader />;
};

export default ShareableAfterAuth;

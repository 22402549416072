import { AxiosPromise, Method } from "axios";
import { getGoogleSessionId } from "services";
import { REACT_APP_CLIENT_ID } from "utils/env";
import { v4 as uuidv4 } from "uuid";
import { apiRequest } from "../requests";

export const actionCreator = <T>(type: string, payload?: T) => {
  return {
    type,
    payload
  };
};

export const createHeader = () => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId()
  };
};

export const callApi = <T = any>(
  method: Method = "GET",
  url = "",
  data = {},
  _isPrivateHeaders = true,
  headersData = {},
  _isPay = false,
  source = null,
  handleError = true
): AxiosPromise<T> => {
  const header = {
    "content-type": "application/json",
    ...headersData
  };

  return apiRequest(method, url, data, undefined, source, header, handleError);
};

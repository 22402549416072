import { lazy } from "react";

export const NewspaperSearch = lazy(() => import("pages/SearchPage/NewspaperSearch"));
export const LpNewspaper = lazy(() => import("pages/LpNewspaper"));
export const NewspapperRecords = lazy(() => import("pages/SearchPage/NewspapperRecords"));
export const NewspapperSearchBrowse = lazy(() => import("pages/SearchPage/NewspapperSearchBrowse"));

export const DynamicSearch = lazy(() => import("pages/DynamicSearch/pages/DynamicSearch"));
export const DynamicSearchResults = lazy(
  () => import("pages/DynamicSearch/pages/DynamicSearchResults")
);

import React from "react";
import { pdfjs } from 'react-pdf';
import Prod from "pages/MainEntry/Prod";
import Test from "pages/MainEntry/Test";
import { REACT_APP_ENV } from "utils/env";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const App = ({ appErrorState, isAccessed, isAccessToken, allRoutes, isLoading, handleLogout }) => {

  const prodENV = REACT_APP_ENV.toLowerCase() === "production" || REACT_APP_ENV.toLowerCase() === "automation";


  if (prodENV) {
    return <Prod
      appErrorState={appErrorState}
      isAccessToken={isAccessToken}
      allRoutes={allRoutes}
      isLoading={isLoading}
      handleLogout={handleLogout}
    />;
  }
  return <Test
    appErrorState={appErrorState}
    isAccessed={isAccessed}
    isAccessToken={isAccessToken}
    allRoutes={allRoutes}
    isLoading={isLoading}
    handleLogout={handleLogout}
  />;
};

export default App;

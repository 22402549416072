import AuthenticatedTemplate from "components/AuthenticatedTemplate";

import ProtectedRoutes from "pages/Routes/ProtectedRoutes";

import { SignalRContext } from "contexts";
import usePrivateInit from "pages/MainEntry/hooks/usePrivateInit";
import { clearAppErrorState } from "redux/actions/user";
import { getAccessToken } from "services";
import { REACT_APP_API } from "utils/env";

const Private = ({ appErrorState }) => {
  const { dispatch, appError } = usePrivateInit();
  const token = getAccessToken();
  const clearServerErrorState = () => {
    dispatch(clearAppErrorState());
  };

  return (
    <AuthenticatedTemplate>
      <SignalRContext.Provider
        connectEnabled={!!token}
        accessTokenFactory={() => token}
        dependencies={[token]} //remove previous connection and create a new connection if changed
        url={`${REACT_APP_API}/notificationHub`}
        onError={(e) => {
          console.log('Error SignalR',e);
        }}
        automaticReconnect={[2000, 4000, 6000]}
      >
        <ProtectedRoutes
          appErrorState={appErrorState}
          appError={appError}
          clearServerErrorState={clearServerErrorState}
        />
      </SignalRContext.Provider>
    </AuthenticatedTemplate>
  );
};

export default Private;

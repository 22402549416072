import { ADD_PET_HERO_THUMBNAIL, ADD_PET_THUMBNAIL } from "redux/constants";
import * as CONSTANTS from "../constants/actionTypes";
const initialState = {
  loading: true,
  petInfo: null,
  error: false
};

const pet = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CONSTANTS.PET_INFO.REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case CONSTANTS.PET_INFO.SUCCESS:
      return {
        ...state,
        loading: false,
        petInfo: payload,
      };
    case CONSTANTS.PET_INFO.FAILURE:
      return {
        ...state,
        loading: false,
        petInfo: payload,
        error: true,
      };
    case ADD_PET_THUMBNAIL:
      return {
        ...state,
        petInfo: {
          ...state.petInfo,
          profileImageUrl: payload.fileUrl,
        },
      };
    case ADD_PET_HERO_THUMBNAIL:
      return {
        ...state,
        petInfo: {
          ...state.petInfo,
          backgroundImageUrl: payload.fileUrl,
        },
      };
    default:
      return state;
  }
};

export default pet;

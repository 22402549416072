import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { addOfferEvent, addRegisterType, getAccessToken, removeGroupPage } from "services";
import { offerTypes, pathTypes } from "utils/constant";

const usePathSave = (logout) => {
  const { pathname } = useLocation();
  const accessToken = getAccessToken();

  useEffect(() => {
    const trimmedPath = pathname?.split("/")[1];
    const excludedPaths = ["redirector", "plus", "freetrials", "payment", "group-process", "story-share-auth"];

    if (trimmedPath !== "groups" && trimmedPath !== "plans" && logout) {
      removeGroupPage();
    }

    if (!excludedPaths.includes(trimmedPath)) {
      if (!accessToken && logout) {
        const regType = pathTypes[trimmedPath] ?? "self select";
        pathname && addRegisterType(regType);
      }
      const offerType = offerTypes[trimmedPath] ?? "";
      pathname && addOfferEvent(offerType);
    }
  }, [accessToken, logout, pathname]);
};
export default usePathSave;

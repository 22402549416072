import * as CONSTANTS from "../constants/actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
    isLoading: true,
    paginationLoading: false,
    list: [],
    totalRecords: 0,
    frontPageCount: 0
};
const publication = (state = initialState, { type = null, payload = null } = {}) => {
    switch (type) {
        case CONSTANTS.GETPUBLICTAION.REQUEST:
            return {
                ...state,
                isLoading: true,
                paginationLoading: false
            }
        case CONSTANTS.GETPUBLICTAIONPAGINATION.REQUEST:
            return {
                ...state,
                paginationLoading: true,
                isLoading: true,
            }
        case CONSTANTS.GETPUBLICTAION.SUCCESS: 
            return {
              ...state,
              list: (payload?.searchResults || [])?.map((item) => ({
                ...item,
                keyId: uuidv4(),
              })),
              totalRecords: payload?.resultCount,
              frontPageCount: payload?.frontPageCount,
              paginationLoading: false,
              isLoading: false,
            };
        case CONSTANTS.GETPUBLICTAION.FAILURE: 
            return {
                ...state,
                paginationLoading: false,
                isLoading: false,
            }
        default:
            return state;
        }
}
export default publication


import { DEFAULT } from "./constant";
export const defaultStoryState = {
  storyId: "",
  authorId: "",
  title: "",
  placeId: "",
  date: "",
  content: "",
  layoutId: "",
  storyCategories: [],
  person: [],
  NamedAuthor: [],
  type: DEFAULT,
  recipesIngredients: "",
  recipesDirections: "",
  recipesAdditionalDetails: "",
  place: { id: "", name: "" },
  publisher_title: "",
  status: "",
  groupIds: [],
  imageChange: false,
  cropVal: {},
  privacy: "Public",
};

export const showBirthandDeath = (birthYear, deathYear, isLiving, isOwner, isPrivate) =>{
    if (birthYear === null || birthYear === undefined)
        birthYear = ""
    if (deathYear === null || deathYear === undefined)
        deathYear = ""
    switch(true){
        case(isLiving === true && !isOwner):
            return "Living"
        case(isLiving === false && !isOwner && isPrivate):
            return "Deceased"
        case((birthYear === " ") && (deathYear === " ")):
            return " "
        case((birthYear === "") && (deathYear === "") && isLiving === true):
        case((birthYear === null) && (deathYear === null)):
            return `Living`;
        case((birthYear !== "") && (deathYear !== "")):
            return `${birthYear}\u2013${deathYear}`;
        case((birthYear === "") && (deathYear !== "")):
            return `\u2013${deathYear}`;
        case((birthYear !== "") && isLiving === false && deathYear ==="" && !isOwner):
            return `${birthYear}\u2013`;   
        case((birthYear !== "") && isLiving === true && deathYear ==="" && !isOwner):
            return "Living";
        case((birthYear !== "") && isLiving === false && deathYear ===""):
            return `${birthYear}\u2013`;   
        case((birthYear !== "") && (deathYear ==="")):
            return `${birthYear}\u2013Living`
        case((birthYear === "") && (deathYear === "") && isLiving === false):
            return ""
    }
    return "";
}

import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccessToken,
  getVisitorIDCookie,
  removeInvitationStoryID,
  setInvitationStoryID,
} from "services";
import useAuthUtils from "hooks/useAuthUtils";

const useIndex = () => {
  const { instance } = useMsal();
  const history = useHistory();
  const dispatch = useDispatch();
  const { messageID, storyId } = useParams();
  const [openWaitList, setOpenWaitList] = useState(false);
  const [step, setStep] = useState(1);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showStory, setShowStory] = useState(false);
  const isAccessTokenTerms = getAccessToken();
  const visitorID = getVisitorIDCookie();
  const { sharedStory } = useSelector((state) => state.story);
  const { handleStoryLogin, handleStorySignup } = useAuthUtils();
  const redirectUri = "/verify-story-permission";

  useEffect(() => {
    removeInvitationStoryID();
  }, []);

  const setStepFunction = (data) => {
    if (data) {
      setStep(data);
    } else {
      setOpenWaitList(false);
      setStep(0);
    }
  };

  const handleLoginFunc = () => {
    setInvitationStoryID(sharedStory.storyId);
    handleStoryLogin(redirectUri);
  };

  const handleSignupFunc = () => {
    setInvitationStoryID(sharedStory.storyId);
    handleStorySignup(redirectUri);
  };

  return {
    instance,
    history,
    dispatch,
    messageID,
    storyId,
    openWaitList,
    setOpenWaitList,
    step,
    setStep,
    showAccountModal,
    setShowAccountModal,
    showStory,
    setShowStory,
    isAccessTokenTerms,
    visitorID,
    setStepFunction,
    sharedStory,
    handleLoginFunc,
    handleSignupFunc,
  };
};

export default useIndex;

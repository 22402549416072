import { ReactComponent as CloseIcon } from "assets/images/close_32_32.svg";
import className from "classnames";
import ModalTooltip from "components/TailwindModal/ModalTooltip";
import Typography from "components/Typography";

const getCloseButton = ({ showClose, setShowModal, cancelMember, history }) => {
  const closeButton = showClose ? (
    <button
      className="outline-none focus:outline-none relative z-50"
      onClick={(e) => {
        e.stopPropagation();
        setShowModal(false);
        if (cancelMember) {
          history.replace("/settings/manage");
        }
      }}
    >
      <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
        <CloseIcon />
      </span>
    </button>
  ) : null;

  return closeButton;
};

export const modal = ({
  innerClasses,
  modalWrap,
  modalHead,
  titleFontWeight,
  title,
  subTitle,
  modalPadding,
  content,
  showInfoTooltip,
  ...rest
}) => (
  <div
    className={className(
      `tw-modal-dialog pointer-events-none w-full relative my-6 mx-auto flex items-center ${innerClasses}`
    )}
  >
    <div className="flex flex-col w-full pointer-events-auto relative">
      <div
        className={`border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none ctw-modal-wrap my-auto ${modalWrap}`}
      >
        <div className={`flex items-start justify-between rounded-t ctw-modal-head ${modalHead}`}>
          <h3 className={`text-2xl inline mb-0 text-gray-7 ${titleFontWeight}`}>
            {title}
            {subTitle && (
              <p>
                <Typography size={14}>
                  <span className="block">{subTitle}</span>
                </Typography>
              </p>
            )}
          </h3>
          {getCloseButton({ ...rest })}
        </div>
        <div className={`relative flex-auto ctw-modal-body ${modalPadding}`}>{content}</div>
      </div>
      {showInfoTooltip && <ModalTooltip />}
    </div>
  </div>
);

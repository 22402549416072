import Loader from "components/Loader";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getFamilySearchAccessToken, checkStoriedAccount } from "redux/actions/gedcom";
import {
    getFamilySearchToken,
    setFamilySearchAuthCode,
    setFamilySearchIdToken,
    setFamilySearchToken,
    decodeJWTtoken
} from "services";
import { REACT_APP_ENV_URL } from "utils/env";

const SignUpWithFamilySearch = () => {
    const history = useHistory();
    const familySearchToken = getFamilySearchToken();
    const planId = decodeJWTtoken()?.extension_PlanId;

    useEffect(() => {
        async function checkAccount() {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");
            if (code) {
                setFamilySearchAuthCode(code);
                const FSAccessToken = await getFamilySearchAccessToken(code);
                setFamilySearchToken(FSAccessToken?.access_token);

                if (FSAccessToken) {
                    const decodedToken = jwt_decode(FSAccessToken?.id_token);
                    const isStoried = await checkStoriedAccount(decodedToken?.sub);
                    if (isStoried) {
                        //Fs email is on storied so we redirect them to LOHP
                        history.push("/");
                    } else {
                        if (decodedToken) {
                            setFamilySearchIdToken(FSAccessToken?.id_token);
                            //Fs user is affliated and is not on storied so we redirect them to signup
                            if (decodedToken?.qualifies_for_affiliate_account === "true") {
                                history.push("/signup-with-familysearch/plans");
                            }
                            //Fs user is not affliated so we redirect them to LIHP
                            else {
                                history.push("/");
                            }
                        }
                    }
                }
            } else {
                const url = `https://ident.familysearch.org/cis-web/oauth2/v3/authorization?response_type=code&scope=openid%20profile%20email%20qualifies_for_affiliate_account%20country%20offline_access&client_id=QVB8-LX1H-ZB2S-MRL6-CVPA-NDDN-XHCO-BUZL&redirect_uri=${REACT_APP_ENV_URL}/signup-with-familysearch`;
                window.open(url, "_self", `width=399,height=759`);
            }
        }
        if (planId === '19681') {
            history.push('/');

        }
        else {
            checkAccount();
        }

    }, []);

    return (
        <div>
            <Loader />
        </div>
    );
};

export default SignUpWithFamilySearch;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { V2ContributorNameData } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Contributor extends HttpClient {
  /**
   * No description
   *
   * @tags Contributor
   * @name V2ContributorName
   * @request GET:/api/v2/Contributor/{contributorId}/name
   * @secure
   */
  v2ContributorName = (contributorId: string, params: RequestParams = {}) =>
    this.request<V2ContributorNameData, any>({
      path: `/api/v2/Contributor/${contributorId}/name`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

import { useEffect } from "react";
import { clearAppErrorState } from "redux/actions/user";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { NavLink } from "react-router-dom";
//Components
import Icon from "components/Icon";
import Translator from "components/Translator";
import { usePeoplePopover } from "./hooks/usePeoplePopover";
import getSearchNavs from "components/PeoplePopover/components/GetSearchNavs";
//Menu
import { searchTabMenu } from "pages/PersonViewPage/menus";
import { useMouseEventHandler } from "hooks/useMouseEventHandler";
//Utils
import { removeSelectedStyle } from "utils";

export default function PeoplePopover({ type }) {
  const { open, subnavMenu, anchorRef, prevOpen, dropdownClass, isSearchTab, setIsSearchTab, handleToggle, handleClose, handleListKeyDown, handleSelect, getActiveClass } = usePeoplePopover(type);
  const { onMouseEnterHandler, onMouseLeaveHandler } = useMouseEventHandler();

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [anchorRef, open, prevOpen]);

  useEffect(() => {
    if (open) removeSelectedStyle();
  }, [open]);

  return (
    <>
      <div
        id={type}
        className={`${getActiveClass()} drop-down`}
        ref={anchorRef}
        aria-haspopup="true"
        onClick={() => {
          handleToggle();
        }}
      >
        <Translator tkey={`header.${type}`}></Translator>
        <figure className="m-icon">
          <Icon type="mIconChevronDown" color="default" size="small" />
        </figure>
        <div className={dropdownClass(isSearchTab, true)}>{getSearchNavs(searchTabMenu, clearAppErrorState, setIsSearchTab)}</div>
      </div>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-start" className="loggedout-dropmenu">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "bottom start" }}>
            <Paper
              sx={{
                "&.MuiPaper-root": {
                  marginLeft: "-16px",
                  background: "#FFFFFF",
                  boxShadow: "0px 4px 24px -4px rgba(0, 0, 0, 0.15)",
                  borderRadius: "8px",
                  marginTop: "12px",
                  minWidth: "90px",
                  maxWidth: "150px",
                }
              }}
              style={{ minWidth: 180, maxWidth: 224 }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocus={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {subnavMenu?.map((ele, _key) => (
                    <MenuItem
                      key={ele}
                      sx={{
                        "&.MuiMenuItem-root": {
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          color: "#000",
                          "&:focus:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04) !important"
                          },
                          "&:focus": {
                            backgroundColor: "rgba(0, 0, 0, 0.04) !important"
                          },
                        }
                      }}
                      onMouseEnter={onMouseEnterHandler}
                      onMouseLeave={onMouseLeaveHandler}
                      onClick={() => handleSelect(ele)}
                    >
                      <NavLink to={ele.navigateTo} className="nav-sublink">
                        <Icon size="medium" type={ele.itemIcon} />
                        {ele.itemName}
                      </NavLink>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

PeoplePopover.propTypes = {
  type: PropTypes.oneOf(["image", "avatar", "people", "search"]),
  avatarName: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.object),
  handleMenu: PropTypes.func,
  showFooter: PropTypes.bool,
};

PeoplePopover.defaultProps = {
  type: "image",
  avatarName: "avatarName",
  menu: [
    {
      id: 1,
      name: "PeoplePopover",
    },
  ],
  handleMenu: undefined,
};
import { useGlobalLoaderStore } from "globalLoaderStore";
import { memo } from "react";
import { __RouterContext as RouterContext } from "react-router";
import { forceViteEnabled, getAccessToken } from "services";
import { REACT_APP_API, REACT_APP_CLIENT_ID } from "utils/env";
import { getCompiledNextRoutes } from "./nextJsRoutes";

const NextjsLocationListener = memo(function NextjsLocationListener() {
  const setGlobalLoader = useGlobalLoaderStore((state) => state.setGlobalLoader);
  return (
    <RouterContext.Consumer>
      {({ location }) => {
        async function redirectToNextjsIfRequired() {
          const nextRoute = getCompiledNextRoutes(REACT_APP_CLIENT_ID, REACT_APP_API).find(
            (route) => route.path.test(location.pathname)
          );
          if (!nextRoute) return;

          const canBeLoggedIn =
            typeof nextRoute.canBeLoggedIn === "boolean"
              ? nextRoute.canBeLoggedIn
              : await nextRoute.canBeLoggedIn();
          if (canBeLoggedIn || !getAccessToken()) {
            setGlobalLoader(true);
            window.location.href = `${location.pathname}${location.search}`;
          }
        }

        if (forceViteEnabled() || process.env.NODE_ENV !== "production") return null;
        redirectToNextjsIfRequired();

        return null;
      }}
    </RouterContext.Consumer>
  );
});

export default NextjsLocationListener;

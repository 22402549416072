import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
// hooks
import useWindowDimensions from "hooks/useWindowDimensions";
// assets
import StoriedBFBanner from "assets/images/promotionalBanner/StoriedBFBanner.webp";
import StoriedBFBannerMobile from "assets/images/promotionalBanner/StoriedBFBannerMobile.webp";
import StoriedCMBanner from "assets/images/promotionalBanner/StoriedCMBanner.webp";
import StoriedCMBannerMobile from "assets/images/promotionalBanner/StoriedCMBannerMobile.webp";
// utils
import { REACT_APP_BLACK_FRIDAY_URL, REACT_APP_CYBER_MONDAY_URL } from "utils/env";
// contexts
import { MainsPageContext } from "contexts";
// services
import { getAccessToken } from "services";

const LogoutRoutes = ["groups", "plans", "newspaper-search"];

const PromotionalBanner = ({ showBanner = false }) => {
  const location = useLocation();
  const isLoggedIn = getAccessToken();
  const { FF_BlackFriday, FF_CyberMonday } = useContext(MainsPageContext);
  const showPromotionalBanner = FF_BlackFriday || FF_CyberMonday;
  const MOBILE_WIDTH = 512;
  const { width } = useWindowDimensions();
  const pathNames = location.pathname?.split("/");
  pathNames?.shift();

  const getImageSrc = () => {
    const alt = FF_BlackFriday ? "Black Friday" : "Cyber Monday";
    const isMobile = width < MOBILE_WIDTH;
    const webImgBanner = FF_BlackFriday ? StoriedBFBanner : StoriedCMBanner;
    const mobileImgBanner = FF_BlackFriday ? StoriedBFBannerMobile : StoriedCMBannerMobile;
    const src = isMobile ? mobileImgBanner : webImgBanner;
    return {
      src,
      alt,
    };
  };

  const imageSource = getImageSrc();

  if (isLoggedIn) {
    return null;
  }

  if (!showPromotionalBanner || (!showBanner && LogoutRoutes.includes(pathNames?.[0]))) {
    return null;
  }

  return (
    <div className="bg-black pt-14 mb-auto promotional-banner">
      <a
        href={FF_BlackFriday ? REACT_APP_BLACK_FRIDAY_URL : REACT_APP_CYBER_MONDAY_URL}
        rel="noopener noreferrer"
        className="flex justify-center w-full"
      >
        <img
          className="w-full max-w-320"
          src={imageSource.src}
          alt={imageSource.alt}
        />
      </a>
    </div>
  );
};
PromotionalBanner.propTypes = {
  showBanner: PropTypes.bool,
};
export default PromotionalBanner;

import { actionCreator, callApi } from "redux/utils";
import * as API_URLS from "redux/constants/apiUrl";
import { createHeader } from "redux/actions/utils/customHeader";
import { POST, GET } from "redux/constants";
import * as CONSTANTS from "redux/constants/actionTypes";

export const COUPON_STATUS = {
  NotGranted: "NotGranted",
  Active: "Active",
};

export const grantUserCoupon = () => {
  return async (dispatch, getState) => {
    const url = API_URLS.GRANTUSERCOUPON;
    const staticHeader = createHeader();
    try {
      await callApi(POST, url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.USER_COUPON_STATUS.SUCCESS, "Active"));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUserCouponStatus = () => {
  return async (dispatch, getState) => {
    const url = API_URLS.GETUSERCOUPONSTATUS;
    const staticHeader = createHeader();
    try {
      const res = await callApi(GET, url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.USER_COUPON_STATUS.SUCCESS, res.data));
    } catch (error) {
      console.error(error);
      dispatch(actionCreator(CONSTANTS.USER_COUPON_STATUS.FAILURE));
    }
  };
};

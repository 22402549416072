import { EMPTYGROUPDETAILS, EMPTYGROUPMEMBERS, INITIALUSERROLE, RESETINVITEDETAILS } from "../constants";
import * as CONSTANTS from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  existingGroupUrls: [],
  groupDetails: {},
  error: false,
  groupError: null,
  isGroupLoading: true,
  inviteLoading: false,
  inviteSent: false,
  inviteUrlLoading: true,
  inviteURLError: false,
  isURLInvalid: null,
  groupInfo: {},
  groupInfoLoading: true,
  groupMembers: [],
  groupMembersLoading: true,
  groupsList: [],
  groupsListLoading: true,
  groupsSponsoredList: [],
  groupsSponsoredListLoading: true,
  groupInvitation: false,
  groupJoin: false,
  userRole: "Member",
  roleLoading: true,
  skipping: false,
  followLoading: false,
  isMemberRoleChange: false,
  isMemberDelete: false,
  pendingInvites: [],
  pendingLoading: true,
  inviterInfo: {},
  inviterInfoLoading: true,
  isLeaveGroup: false,
  isClaimSponsorship: false,
  groupUrl: "",
  checkingPersonStatus: false,
  checkPersonStatus: null,
  isConfirming: false,
  isCancelInviteLoading: false,
};

const group = (state = initialState, { type = null, payload = null } = {}) => {
  const request = {
    ...state,
    existingGroupUrls: [],
    isLoading: true,
  };

  switch (type) {
    case CONSTANTS.SKIPINVITE.REQUEST:
    case CONSTANTS.CREATEGROUP.REQUEST:
    case CONSTANTS.CREATEGROUP.SUCCESS:
    case CONSTANTS.CREATEGROUP.FAILURE:
    case CONSTANTS.LEAVEGROUP.REQUEST:
    case CONSTANTS.LEAVEGROUP.SUCCESS:
    case CONSTANTS.LEAVEGROUP.FAILURE:
      switch (type) {
        case CONSTANTS.SKIPINVITE.REQUEST:
          return {
            ...state,
            existingGroupUrls: [],
            isLoading: true,
            skipping: true,
          };
        case CONSTANTS.CREATEGROUP.REQUEST:
          return {
            ...state,
            existingGroupUrls: [],
            isLoading: true,
            inviteLoading: true,
          };
        case CONSTANTS.CREATEGROUP.SUCCESS:
          return {
            ...initialState,
            skipping: false,
          };
        case CONSTANTS.LEAVEGROUP.REQUEST:
          return {
            ...state,
            isLeaveGroup: true,
          };
        case CONSTANTS.LEAVEGROUP.SUCCESS:
          return {
            ...state,
            groupsSponsoredList: state.groupsSponsoredList.filter((y) => y.groupId !== payload),
            isLeaveGroup: false,
            groupDetails : {}
          };
        case CONSTANTS.LEAVEGROUP.FAILURE:
          return {
            ...state,
            isLeaveGroup: false,
          };
        case CONSTANTS.CREATEGROUP.FAILURE:
          return initialState;
        default:
          return state;
      }

    case CONSTANTS.GETUSERGROUPSLIST.REQUEST:
    case CONSTANTS.GETUSERGROUPSLIST.SUCCESS:
    case CONSTANTS.GETUSERGROUPSLIST.FAILURE:
    case CONSTANTS.GETUSERGROUPSSPONSOREDLIST.REQUEST:
    case CONSTANTS.GETUSERGROUPSSPONSOREDLIST.SUCCESS:
    case CONSTANTS.GETUSERGROUPSSPONSOREDLIST.FAILURE:
    case CONSTANTS.CHECKEXISTINGROUP.REQUEST:
    case CONSTANTS.CHECKEXISTINGROUP.SUCCESS:
    case CONSTANTS.CHECKEXISTINGROUP.FAILURE:
      switch (type) {
        case CONSTANTS.GETUSERGROUPSLIST.REQUEST:
          return {
            ...state,
            groupsListLoading: true,
          };
        case CONSTANTS.GETUSERGROUPSLIST.SUCCESS:
          return {
            ...state,
            groupsListLoading: false,
            groupsList: payload,
          };
        case CONSTANTS.GETUSERGROUPSLIST.FAILURE:
          return {
            ...state,
            groupsListLoading: false,
          };

        case CONSTANTS.GETUSERGROUPSSPONSOREDLIST.REQUEST:
          return {
            ...state,
            groupsSponsoredListLoading: true,
          };
        case CONSTANTS.GETUSERGROUPSSPONSOREDLIST.SUCCESS:
          return {
            ...state,
            groupsSponsoredListLoading: false,
            groupsSponsoredList: payload,
          };
        case CONSTANTS.GETUSERGROUPSSPONSOREDLIST.FAILURE:
          return {
            ...state,
            groupsSponsoredListLoading: false,
          };

        case CONSTANTS.CHECKEXISTINGROUP.REQUEST:
          return request;
        case CONSTANTS.CHECKEXISTINGROUP.SUCCESS:
          return {
            ...state,
            isLoading: false,
            existingGroupUrls: payload,
          };
        case CONSTANTS.CHECKEXISTINGROUP.FAILURE:
          return {
            ...state,
            isLoading: false,
          };
        default:
          return state;
      }

    case CONSTANTS.GROUPDETAILS.REQUEST:
    case CONSTANTS.GROUPDETAILS.SUCCESS:
    case CONSTANTS.GROUPDETAILS.FAILURE:
    case CONSTANTS.CLAIMSPONSORSHIP.REQUEST:
    case CONSTANTS.CLAIMSPONSORSHIP.SUCCESS:
    case CONSTANTS.CLAIMSPONSORSHIP.FAILURE:
      switch (type) {
        case CONSTANTS.GROUPDETAILS.REQUEST:
          return {
            ...state,
            isGroupLoading: true,
            error: false,
            groupDetails: {},
          };
        case CONSTANTS.GROUPDETAILS.SUCCESS:
          return {
            ...state,
            isGroupLoading: false,
            groupDetails: payload,
            groupError: null,
            error: false,
          };
        case CONSTANTS.GROUPDETAILS.FAILURE:
          return {
            ...state,
            isGroupLoading: false,
            error: true,
            groupError: payload,
            groupDetails: {},
          };
        case CONSTANTS.UPDATEGROUPDETAILS.SUCCESS:
          return {
            ...state,
            groupDetails: { ...state.groupDetails, sponsorHasRespondedToStoryImportPrompt: payload },
          };
        case CONSTANTS.CLAIMSPONSORSHIP.REQUEST:
          return {
            ...state,
            isClaimSponsorship: true,
          };
        case CONSTANTS.CLAIMSPONSORSHIP.SUCCESS:
          return {
            ...state,
            groupDetails: { ...state.groupDetails, isSponsorshipActive: payload },
            isClaimSponsorship: false,
          };
        case CONSTANTS.CLAIMSPONSORSHIP.FAILURE:
          return {
            ...state,
            isClaimSponsorship: false,
          };
        default:
          return state;
      }

    case CONSTANTS.CREATEINVITE.REQUEST:
      return {
        ...state,
        inviteLoading: true,
      };

    case CONSTANTS.CREATEINVITE.SUCCESS:
      return {
        ...state,
        inviteSent: true,
        inviteLoading: false,
        error: null,
      };

    case CONSTANTS.CREATEINVITE.FAILURE:
      return {
        ...state,
        inviteLoading: false,
        error: payload,
      };

    case CONSTANTS.RESETINVITE.REQUEST:
      return {
        ...state,
        inviteSent: false,
      };

    case CONSTANTS.ISGROUPINVITATIONVALID.SUCCESS:
      return {
        ...state,
        inviteUrlLoading: false,
        inviteURLError: false,
        isURLInvalid: payload,
      };

    case CONSTANTS.ISGROUPINVITATIONVALID.FAILURE:
      return {
        ...state,
        inviteUrlLoading: false,
        inviteURLError: true,
      };
    case CONSTANTS.GETGROUPINFO.SUCCESS:
      return {
        ...state,
        groupInfo: payload,
        groupInfoLoading: false,
      };

    case CONSTANTS.GETGROUPINFO.FAILURE:
      return {
        ...state,
        groupInfo: {},
        groupInfoLoading: false,
      };

    case CONSTANTS.GETGROUPMEMBERS.SUCCESS:
      return {
        ...state,
        groupMembers: payload,
        groupMembersLoading: false,
      };

    case CONSTANTS.GETGROUPMEMBERS.FAILURE:
      return {
        ...state,
        groupMembers: [],
        groupMembersLoading: false,
      };

    case CONSTANTS.ACCEPTGROUPINVITE.SUCCESS:
      return {
        ...state,
        groupInvitation: true,
      };
    case CONSTANTS.ACCEPTGROUPJOIN.SUCCESS:
      return {
        ...state,
        groupJoin: true,
      };
    case CONSTANTS.GETUSERROLE.SUCCESS:
      return {
        ...state,
        userRole: payload?.value,
        roleLoading: false,
      };
    case CONSTANTS.GETUSERROLE.FAILURE:
    case INITIALUSERROLE:
      return {
        ...state,
        userRole: payload,
      };
    case EMPTYGROUPDETAILS:
      return {
        ...state,
        groupDetails: {},
        groupMembers: [],
      };
    case CONSTANTS.FOLLOWUSER.REQUEST:
    case CONSTANTS.UNFOLLOWUSER.REQUEST:
    case CONSTANTS.FOLLOWUSER.FAILURE:
    case CONSTANTS.UNFOLLOWUSER.FAILURE:
      return {
        ...state,
        followLoading: payload,
      };
    case CONSTANTS.FOLLOWUSER.SUCCESS:
    case CONSTANTS.UNFOLLOWUSER.SUCCESS: {
      const index = state.groupMembers.findIndex((m) => m.userId === payload.userId);
      const newArray = [...state.groupMembers];
      newArray[index].isFollowing = payload.isFollowing;
      return {
        ...state,
        groupMembers: newArray,
        followLoading: false,
      };
    }

    case CONSTANTS.UPGRADEMEMBERROLE.REQUEST:
    case CONSTANTS.UPGRADEMEMBERROLE.SUCCESS:
    case CONSTANTS.UPGRADEMEMBERROLE.FAILURE:
      switch (type) {
        case CONSTANTS.UPGRADEMEMBERROLE.REQUEST:
          return {
            ...state,
            isMemberRoleChange: true,
          };
        case CONSTANTS.UPGRADEMEMBERROLE.SUCCESS:
        case CONSTANTS.UPGRADEMEMBERROLE.FAILURE:
          return {
            ...state,
            isMemberRoleChange: false,
          };
        default:
          return state;
      }

    case CONSTANTS.REMOVEFROMGROUP.REQUEST:
    case CONSTANTS.REMOVEFROMGROUP.SUCCESS:
    case CONSTANTS.REMOVEFROMGROUP.FAILURE:
    case CONSTANTS.GETPENDINGINVITES.SUCCESS:
    case CONSTANTS.GETPENDINGINVITES.FAILURE:
    case CONSTANTS.GETINVITERINFO.SUCCESS:
    case CONSTANTS.GETINVITERINFO.FAILURE:
      switch (type) {
        case CONSTANTS.REMOVEFROMGROUP.REQUEST:
          return {
            ...state,
            isMemberDelete: true,
          };
        case CONSTANTS.REMOVEFROMGROUP.SUCCESS:
          return {
            ...state,
            groupMembers: state.groupMembers.filter((item) => item.userId !== payload.memberId),
            isMemberDelete: false,
          };
        case CONSTANTS.REMOVEFROMGROUP.FAILURE:
          return {
            ...state,
            isMemberDelete: false,
          };
        case CONSTANTS.GETPENDINGINVITES.SUCCESS:
          return {
            ...state,
            pendingInvites: payload,
            pendingLoading: false,
          };
        case CONSTANTS.GETPENDINGINVITES.FAILURE:
          return {
            ...state,
            pendingInvites: [],
            pendingLoading: false,
          };
        case CONSTANTS.GETINVITERINFO.SUCCESS:
          return {
            ...state,
            inviterInfo: payload,
            inviterInfoLoading: false,
          };
        case CONSTANTS.GETINVITERINFO.FAILURE:
          return {
            ...state,
            inviterInfo: {},
            inviterInfoLoading: false,
          };

        default:
          return state;
      }

    case RESETINVITEDETAILS:
      return {
        ...state,
        groupInfo: {},
        inviterInfo: {},
        inviterInfoLoading: true,
        groupsListLoading: true,
      };

    case CONSTANTS.UPLOAD_GROUP_IMAGE.REQUEST:
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          groupBackgroundImage: {
            ...state.groupDetails?.groupBackgroundImage,
            url: payload.fileUrl,
          },
        },
      };

    case CONSTANTS.DELETE_GROUP_IMAGE.REQUEST:
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          groupBackgroundImage: {
            ...state.groupDetails?.groupBackgroundImage,
            url: null,
          },
        },
      };
    case CONSTANTS.GETGROUPURL.SUCCESS:
      return {
        ...state,
        groupUrl: payload,
      };
    case EMPTYGROUPMEMBERS:
      return {
        ...state,
        groupMembers: [],
        groupMembersLoading: true,
      };

    case CONSTANTS.CANCELINVITE.REQUEST:
    case CONSTANTS.CANCELINVITE.SUCCESS:
    case CONSTANTS.CANCELINVITE.FAILURE:
    case CONSTANTS.CHECK_TREE_PERSON_STATUS.REQUEST:
    case CONSTANTS.CHECK_TREE_PERSON_STATUS.SUCCESS:
    case CONSTANTS.CHECK_TREE_PERSON_STATUS.FAILURE:
    case CONSTANTS.CONFIRM_MEMBER_STATUS.REQUEST:
    case CONSTANTS.CONFIRM_MEMBER_STATUS.SUCCESS:
    case CONSTANTS.CONFIRM_MEMBER_STATUS.FAILURE:
      switch (type) {
        case CONSTANTS.CANCELINVITE.REQUEST:
          return {
            ...state,
            isCancelInviteLoading: true,
          };
        case CONSTANTS.CANCELINVITE.SUCCESS:
          return {
            ...state,
            groupMembers: state.groupMembers.filter((item) => item.invitationId !== payload),
            isCancelInviteLoading: false,
          };
        case CONSTANTS.CANCELINVITE.FAILURE:
          return {
            ...state,
            isCancelInviteLoading: false,
          };
        case CONSTANTS.CHECK_TREE_PERSON_STATUS.REQUEST:
          return {
            ...state,
            checkingPersonStatus: true,
          };
        case CONSTANTS.CHECK_TREE_PERSON_STATUS.SUCCESS:
          return {
            ...state,
            checkingPersonStatus: false,
            checkPersonStatus: payload,
          };

        case CONSTANTS.CHECK_TREE_PERSON_STATUS.FAILURE:
          return {
            ...state,
            checkingPersonStatus: false,
          };
        case CONSTANTS.CONFIRM_MEMBER_STATUS.REQUEST:
          return {
            ...state,
            isConfirming: true,
          };

        case CONSTANTS.CONFIRM_MEMBER_STATUS.SUCCESS:
          return {
            ...state,
            isConfirming: false,
            checkPersonStatus: null,
          };

        case CONSTANTS.CONFIRM_MEMBER_STATUS.FAILURE:
          return {
            ...state,
            isConfirming: false,
          };
        default:
          return state;
      }

    default:
      return state;
  }
};
export default group;

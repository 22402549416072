import { UPDATE_USER_CACHE, UPDATE_USER_FOLLOW_CACHE } from "../../constants";

const initialState = {
  userInfo: {},
  followInfo: {}
};

const globalCache = (state = initialState, { type = null, payload = null } = {}) => {
  switch (type) {
    case UPDATE_USER_CACHE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          [payload.userId]: payload
        }
      };
    case UPDATE_USER_FOLLOW_CACHE:
      return {
        ...state,
        followInfo: {
          ...state.followInfo,
          [payload.id]: payload.value
        }
      };
    default:
      return state;
  }
};

export default globalCache;

import { basicPlanName, getPlanName } from "redux/helpers/dataLayerHelper";
import {  getSubscriptionDetails } from "services";
import { planDetail } from "utils/constant";

export const UseDataLayer = () => {
    let subscriptionDetails = getSubscriptionDetails();
    let planObj = Object?.values(planDetail)
    let basicName = basicPlanName(planObj,subscriptionDetails)
    let planName = getPlanName(basicName)
    return {basicName,planName}
}
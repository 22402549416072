import { FooterLinks } from "components/Footer";
import { getAccessToken } from "services";
import { copyright } from "shared-logics";


const SearchFooter = () => {    
  return (
    <>
      {!getAccessToken() && (
        <section className="lohp-footer">
          <div className={`footer`}>
            <div className="lohp-footer-links">
              <div className="flex justify-center px-4">
                <FooterLinks />
              </div>
              <div className="mt-2 text-xs copy">{copyright()}</div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SearchFooter
export const REACT_APP_API: string = (import.meta as any).env.REACT_APP_API;
export const REACT_APP_ENV: string = (import.meta as any).env.REACT_APP_ENV;
export const REACT_APP_CLIENT_ID: string = (import.meta as any).env.REACT_APP_CLIENT_ID;
export const IS_DEV_MODE: string = (import.meta as any).env.DEV;
export const REACT_APP_URL: string = (import.meta as any).env.REACT_APP_URL;
export const REACT_APP_COMMUNICATION_GRAPH: string = (import.meta as any).env
  .REACT_APP_COMMUNICATION_GRAPH;
export const REACT_APP_API_BASEPATH_NEWSPAPER: string = (import.meta as any).env
  .REACT_APP_API_BASEPATH_NEWSPAPER;
export const REACT_APP_PAYMENT_URL: string = (import.meta as any).env.REACT_APP_PAYMENT_URL;
export const REACT_APP_SIGNUPSIGNINUP: string = (import.meta as any).env.REACT_APP_SIGNUPSIGNINUP;
export const REACT_APP_SIGNUP: string = (import.meta as any).env.REACT_APP_SIGNUP;
export const REACT_APP_FORGOTPASSWORD: string = (import.meta as any).env.REACT_APP_FORGOTPASSWORD;
export const REACT_APP_EDITPROFILE: string = (import.meta as any).env.REACT_APP_EDITPROFILE;
export const REACT_APP_AUTHORITY: string = (import.meta as any).env.REACT_APP_AUTHORITY;
export const REACT_APP_INSTANCE: string = (import.meta as any).env.REACT_APP_INSTANCE;
export const REACT_APP_MSAL_ID: string = (import.meta as any).env.REACT_APP_MSAL_ID;
export const REACT_APP_SCOPE: string = (import.meta as any).env.REACT_APP_SCOPE;
export const REACT_APP_MEDIA_URL: string = (import.meta as any).env.REACT_APP_MEDIA_URL;
export const REACT_APP_FAMILY_SEARCH_ENDPOINT: string = (import.meta as any).env
  .REACT_APP_FAMILY_SEARCH_ENDPOINT;
export const REACT_APP_SALES_FORCE_ENDPOINT: string = (import.meta as any).env
  .REACT_APP_SALES_FORCE_ENDPOINT;
export const REACT_APP_SALES_FORCE_GET_ENDPOINT: string = (import.meta as any).env
  .REACT_APP_SALES_FORCE_GET_ENDPOINT;
export const REACT_APP_AZURE_FEATURE_FLAG: string = (import.meta as any).env
  .REACT_APP_AZURE_FEATURE_FLAG;
export const REACT_APP_INSTRUMENTATION_KEY: string = (import.meta as any).env
  .REACT_APP_INSTRUMENTATION_KEY;
export const REACT_APP_AUTH0_DOMAIN: string = (import.meta as any).env.REACT_APP_AUTH0_DOMAIN;
export const REACT_APP_AUTH0_CLIENTID: string = (import.meta as any).env.REACT_APP_AUTH0_CLIENTID;
export const REACT_APP_AUTH0_AUDIENCE: string = (import.meta as any).env.REACT_APP_AUTH0_AUDIENCE;
export const REACT_APP_ENV_URL: string = (import.meta as any).env.REACT_APP_ENV_URL;
export const REACT_APP_BLACK_FRIDAY_URL: string = "https://get.storied.com/blackfriday";
export const REACT_APP_CYBER_MONDAY_URL: string = "https://get.storied.com/cyber";
export const REACT_APP_ROOTS_TECH_24: string = "https://get.storied.com/rootstech24/";
export const REACT_APP_IS_DEV_MODE: boolean =
  (import.meta as any).env.REACT_APP_IS_DEV_MODE !== "false";

import i18n from "i18next";
import LanguageDetector, { DetectorOptions } from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

const options: DetectorOptions = {
  // order and from where user language should be detected
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain"
  ],
  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "myDomain",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: "/", sameSite: "strict" }
};

const fallbackLng = ["en"];

const availableLanguages = ["en"];

const NAMESPACE = "storied";

function normalizedBasePath() {
  // @ts-ignore
  const basePath = import.meta.env.BASE_URL as string;
  return basePath.endsWith("/") ? basePath : `${basePath}/`;
}

async function getTranslationJson(language: string, namespace: string) {
  const response = await fetch(`${normalizedBasePath()}locales/${language}/${namespace}.json`);
  const data = await response.json();
  return data;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(resourcesToBackend(getTranslationJson))
  .init({
    ns: NAMESPACE,
    fallbackLng,
    detection: options,
    supportedLngs: availableLanguages,
    interpolation: { escapeValue: false }
  });

export default i18n;

import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
//Services
import { setUserFlow, getViaMarketingURL } from "services";

export const useLoggedOutHeader = () => {
  const marketingURL = getViaMarketingURL();
  const [isBetaClicked, setIsBetaClicked] = useState(false);
  const [isActive, setActive] = useState("false");
  const [authCheck, setAuthCheck] = useState(false);
  const [authMode, setAuthMode] = useState("");
  const { loginWithRedirect: auth0LoginWithRedirect } = useAuth0();

  const checkAuthentication = () => {
    setAuthCheck(true);
  };

  const handleAuthCancel = () => {
    setAuthCheck(false);
  };

  const onStoriedBetaClick = () => {
    setIsBetaClicked(true);
  };

  const handleCancel = () => {
    setIsBetaClicked(false);
  };

  const handleToggle = () => {
    setActive(!isActive);
    if (isActive) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const handleSignUpAuth = () => {
    setAuthMode("signUp");
    setUserFlow("signUp");
    auth0LoginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  const handleSignInAuth = () => {
    setUserFlow("signInUp");
    auth0LoginWithRedirect();
  };

  const handleAuthentication = (type) => {
    switch (true) {
      // case ((authMode === "Sign In") && (type === "azure")):
      //   setAuthProvider("azure");
      //   setUserFlow("signInUp");
      //   instance.loginRedirect();
      //   break

      case authMode === "Sign In" && type === "auth0":
        setUserFlow("signInUp");
        auth0LoginWithRedirect();
        break;

      case authMode === "Sign Up" && type === "auth0":
        setUserFlow("signUp");
        auth0LoginWithRedirect({
          authorizationParams: {
            screen_hint: "signup",
          },
        });
        break;

      default: // do nothing
    }
  };

  return {
    marketingURL,
    isBetaClicked,
    isActive,
    onStoriedBetaClick,
    handleCancel,
    handleToggle,
    handleSignInAuth,
    handleSignUpAuth,
    handleAuthentication,
    checkAuthentication,
    handleAuthCancel,
    authCheck,
    authMode,
  };
};

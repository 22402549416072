import { create } from "zustand";

type GlobalLoaderStore = {
  globalLoader: boolean;
  setGlobalLoader: (value: boolean) => void;
};

export const globalLoaderStore = create<GlobalLoaderStore>((set) => ({
  globalLoader: false,
  setGlobalLoader: (value: boolean) => set({ globalLoader: value })
}));

export const useGlobalLoaderStore = globalLoaderStore;

import { REORDER_STORY_BOOK_ITEM, UPDATE_DRAFT_STORY_COUNT, UPDATE_SELECTED_STORY_COUNT } from "redux/constants";
import * as CONSTANTS from "redux/constants/actionTypes";
import _ from "lodash";

const initialState = {
  specificStoryCount:0,
  draftStoryCount:0,
  specificStoryCountLoading:false,
  storyLoading: true,
  storyList: [],
  bookList: [],
  isLoadingBookList: false,
  isLoadingSelectStory: false,
  isLoadingShippingOrder: false,
  selectedStoryList: [],
  isLoading: false,
  selectedStories: [],
  storyBookDetails: null,
  tryGrantCouponLoading: false,
  hasTriedToGrantCoupon: false,
  storyBookInfo: null,
  selectedStoryCount: 0,
};

const printBookStories = (state = initialState, { type = null, payload = null } = {}) => {
  switch (type) {
    case CONSTANTS.GET_PRINT_BOOK_STORY.REQUEST:
      return {
        ...state,
        storyLoading: true,
      };
    case CONSTANTS.CREATE_STORY_BOOK.REQUEST:
    case CONSTANTS.UPDATE_STORY_BOOK.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CONSTANTS.GET_STORY_BOOK_INFO.REQUEST:
      return {
        ...state,
        isLoadingSelectStory: true,
      };
    case CONSTANTS.GET_STORY_BOOK_LIST.REQUEST:
      return {
        ...state,
        isLoadingBookList: true,
      };
    case CONSTANTS.ORDER_PRINT_BOOK.REQUEST:
      return {
        ...state,
        isLoadingShippingOrder: true,
      };
    case CONSTANTS.GET_PRINT_BOOK_STORY.SUCCESS:
      const mergedStories = _.unionBy([...state.storyList, ...payload], "storyId");
      return {
        ...state,
        storyLoading: false,
        storyList: mergedStories,
      };
    case CONSTANTS.CREATE_STORY_BOOK.SUCCESS:
    case CONSTANTS.UPDATE_STORY_BOOK.SUCCESS:
    case CONSTANTS.CREATE_STORY_BOOK.FAILURE:
    case CONSTANTS.UPDATE_STORY_BOOK.FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case CONSTANTS.GET_STORY_BOOK_INFO.SUCCESS:
      return {
        ...state,
        selectedStoryList: payload,
        isLoadingSelectStory: false,
      };
    case CONSTANTS.SET_STORY_BOOK_INFO.SUCCESS:
      return {
        ...state,
        storyBookDetails: payload,
        isLoadingSelectStory: false,
      };
    case CONSTANTS.SET_STORY_BOOK_DETAIL.SUCCESS:
      return {
        ...state,
        storyBookInfo: payload,
      };
    case CONSTANTS.SET_STORY_BOOK_DETAIL:
        return {
          ...state,
          storyBookInfo: null,
        };
    case CONSTANTS.GET_STORY_BOOK_LIST.SUCCESS:
      return {
        ...state,
        bookList: payload,
        isLoadingBookList: false,
      };
    case CONSTANTS.ORDER_PRINT_BOOK.SUCCESS:
      return {
        ...state,
        isLoadingShippingOrder: false,
      };
    case CONSTANTS.GET_PRINT_BOOK_STORY.FAILURE:
    case CONSTANTS.GET_SELECTED_STORIES.FAILURE:
      return {
        ...state,
        storyLoading: false,
      };
    case CONSTANTS.GET_SELECTED_STORIES.REQUEST:
      return {
        ...state,
        storyLoading: true,
        selectedStories: [],
      };
    case CONSTANTS.GET_SELECTED_STORIES.SUCCESS:
      return {
        ...state,
        storyLoading: false,
        selectedStories: payload,
      };
    case CONSTANTS.GET_STORY_BOOK_INFO.FAILURE:
      return {
        ...state,
        isLoadingSelectStory: false,
      };
    case CONSTANTS.GET_STORY_BOOK_LIST.FAILURE:
      return {
        ...state,
        isLoadingBookList: false,
      };
    case CONSTANTS.GET_PRINT_BOOK_STORY.RESET:
      return {
        ...state,
        storyList: [],
        selectedStoryList: [],
        storyLoading: false,
      };

    case REORDER_STORY_BOOK_ITEM:
      return {
        ...state,
        selectedStories: payload,
      };
    case CONSTANTS.TRY_GRANT_COUPON.REQUEST:
      return {
        ...state,
        tryGrantCouponLoading: true,
      };
    case CONSTANTS.TRY_GRANT_COUPON.SUCCESS:
    case CONSTANTS.TRY_GRANT_COUPON.FAILURE:
      return {
        ...state,
        tryGrantCouponLoading: false,
        hasTriedToGrantCoupon: true,
      };
    case CONSTANTS.GETSPECIFICSTORYCOUNT.REQUEST:
      return {
        ...state,
        specificStoryCountLoading:true,
      };
    case CONSTANTS.GETSPECIFICSTORYCOUNT.SUCCESS:
      return {
        ...state,
        specificStoryCount:payload,
        specificStoryCountLoading:false,
      }
    case CONSTANTS.GETSPECIFICSTORYCOUNT.FAILURE:
      return {
        ...state,
        specificStoryCountLoading:false,
      }
    case UPDATE_DRAFT_STORY_COUNT :
      return {
        ...state,
        draftStoryCount:payload
      }
    case UPDATE_SELECTED_STORY_COUNT :
      return {
        ...state,
        selectedStoryCount:payload
      }
    default:
      return state;
  }
};

export default printBookStories;

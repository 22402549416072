export const copyright = () =>{
    const currentYear = new Date().getFullYear()
    if (new Date().getFullYear() === 2022)
    {
      return  currentYear + " \u00a9 Storied" 
    }
    else 
    {
      return "2022-" + currentYear + " \u00a9 Storied"
    }
}
import reducers from "./reducers";
import thunk, { ThunkDispatch } from "redux-thunk";
import { applyMiddleware, createStore, Dispatch } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const middleware = [thunk];

export const storeFn = (initialState = {}) =>
  createStore(reducers, initialState, composeWithDevTools(applyMiddleware(...middleware)));
const store = storeFn();
export default store;

export type GetState = typeof store.getState;
export type RootState = ReturnType<typeof store.getState>;
type RawAppDispatch = typeof store.dispatch;
type AppActions = RawAppDispatch extends Dispatch<infer S> ? S : never;
export type AppDispatch = ThunkDispatch<RootState, void, AppActions>;

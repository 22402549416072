export const handleFocus = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) element.focus();
};

export const actions1 = {
  cancelAction: () => handleFocus("addAction"),
  firstName: () => {
    const cancelActionElement = document.getElementById("cancelAction");
    if (cancelActionElement) handleFocus("cancelAction");
    else handleFocus("addAction");
  },
  addAction: () => {
    const lastNameInput = document.getElementById("plastName");
    if (lastNameInput) handleFocus("plastName");
    else {
      const parentCheckbox = document.getElementById("Parents");
      const childrenCheckbox = document.querySelector(
        "#buttomPeronIncluded .modal-row:last-child input[type='checkbox']"
      );
      if (parentCheckbox) handleFocus("Parents");
      else if (childrenCheckbox) childrenCheckbox.focus();
      else handleFocus("isLiving");
    }
  },
  pfirstName: () => {
    const parentCheckbox = document.getElementById("Parents");
    if (parentCheckbox?.disabled) handleFocus("isLiving");
    else handleFocus("Parents");
  },
  parents: () => handleFocus("isLiving"),
};

export const actions2 = {
  cancelAction: () => handleFocus("firstName"),
  addAction: () => {
    const cancelActionElement = document.getElementById("cancelAction");
    if (cancelActionElement) handleFocus("cancelAction");
    else handleFocus("firstName");
  },
  birthPlace: () => {
    const deathElement = document.getElementById("death");
    if (deathElement) handleFocus("death");
    else handleFocus("isLiving");
  },
  deathPlace: () => handleFocus("isLiving"),
  isLiving: () => {
    setTimeout(() => {
      const parentCheckbox = document.getElementById("Parents");
      const childrenCheckbox = document.querySelectorAll("input[type='checkbox']")[1];
      if (parentCheckbox && !parentCheckbox.disabled) handleFocus("Parents");
      else if (parentCheckbox?.disabled) handleFocus("pfirstName");
      else if (childrenCheckbox) childrenCheckbox.focus();
      else handleFocus("addAction");
    }, 100);
  },
  plastName: () => handleFocus("addAction"),
  parents: () => {
    const pFirstNameElement = document.getElementById("pfirstName");
    if (pFirstNameElement) handleFocus("pfirstName");
    else handleFocus("addAction");
  },
};

import * as CONSTANTS from "../constants/actionTypes";

const initState = {
  isLoading: false,
  show: true,
  error: false,
  detail: "",
};

const storyAssist = (state = initState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case CONSTANTS.GET_STORY_ASSIST.REQUEST:
      return { ...state, isLoading: true };
    case CONSTANTS.GET_STORY_ASSIST.SUCCESS:
      return { ...state, show: payload, isLoading: false, error: false };
    case CONSTANTS.GET_STORY_ASSIST.FAILURE:
      return { ...state, isLoading: true, error: true };
    case CONSTANTS.UPDATE_STORY_ASSIST.REQUEST:
      return { ...state, isLoading: true };
    case CONSTANTS.UPDATE_STORY_ASSIST.SUCCESS:
      return { ...state, show: !!payload, isLoading: false, error: false };
    case CONSTANTS.UPDATE_STORY_ASSIST.FAILURE:
      return { ...state, isLoading: true, error: true };
    case CONSTANTS.GET_STORY.REQUEST:
      return { ...state, isLoading: true };
    case CONSTANTS.GET_STORY.SUCCESS:
      return { ...state, detail: payload, isLoading: false, error: false };
    case CONSTANTS.GET_STORY.FAILURE:
      return { ...state, isLoading: true, error: true };
    default:
      return state;
  }
};

export default storyAssist;

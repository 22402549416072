import { useEffect } from "react";
import "pages/Privacy/index.css";
import FreeAccountModal from "pages/ShareStory/FreeAccountModal";
import LoggedOutHeader from "components/Header/LoggedOutHeader";
import StoriesView from "pages/ShareStory/Components/StoriesView";
import useStoryShareableIndex from "../Hooks/useStoryShareableIndex";
// contexts

const StorySharePreview = () => {
  const {
    history,
    storyId,
    showAccountModal,
    setShowAccountModal,
    showStory,
    setShowStory,
    isAccessTokenTerms,
    loggedInViaShareableStoryPreviewID,
    handleLoginFunc,
    handleSignupFunc,
  } = useStoryShareableIndex();

  useEffect(() => {
    if (storyId) {
      const storyUrl = "view";
      if (isAccessTokenTerms) {
        history.replace(`/stories/${storyUrl}/0/${storyId}`);
      } else {
        history.replace(`/stories/${storyUrl}/0/${storyId}`);
        setShowStory(true);
      }
    }
  }, [history, isAccessTokenTerms, loggedInViaShareableStoryPreviewID, setShowStory, storyId]);

  const handleModal = () => {
    if (!isAccessTokenTerms) {
      setShowAccountModal(true);
      return false;
    }
    return true;
  };

  const commonProps = {
    handleLogin: handleLoginFunc,
    handleSignup: handleSignupFunc,
  };

  return (
    <div>
      {!isAccessTokenTerms && <LoggedOutHeader {...commonProps} />}
      <StoriesView
        showStory={showStory}
        handleModal={handleModal}
        previewShareable={true}
      />
      <FreeAccountModal
        setShowAccountModal={setShowAccountModal}
        show={showAccountModal}
        {...commonProps}
      />
    </div>
  );
};
export default StorySharePreview;

const initialState = {
  name: "Isaac Leroy Hammack",
  address: "1940 United States Federal Census",
  tree: "-1957 • Mortensen Family Tree"
};

const clues = (state = initialState, action = {}) => {
  return state;
};

export default clues;

import {
    GET_RELATIONSHIP,
    APPEND_RELATIONSHIP,
    GET_RELATIONSHIP_FAILURE
} from "../constants";

const initialState = {
    relationship: [],
    loading:false
}

const relationship = (state = initialState, action = {}) => {
  
    const { type, payload } = action;
    const mapping = {
        [GET_RELATIONSHIP_FAILURE]: {
            ...state,
            loading: true,
        },
        [GET_RELATIONSHIP]: {
            ...state,
            relationship: payload,
            loading: false,
        },
        [APPEND_RELATIONSHIP]: {
            ...state,
            relationship: [...state.relationship, payload],
            loading: false,
        },
    };
    return mapping[type] || state;
}

export default relationship;
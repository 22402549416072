import React from "react";
import { useDispatch } from "react-redux";

import Typography from "components/Typography";
import Icon from "components/Icon";
import Translator from "components/Translator";

import "./modalTooltip.css";

import { dismissquickviewmodaltooltip } from "redux/actions/user";

const ModalTooltip = () => {
  const dispatch = useDispatch();

  const dismissInfoTooltip = () => {
    dispatch(dismissquickviewmodaltooltip());
  };

  return (
    <div className="modal-snakbar">
      <div className="text-green-4">
        <Icon
          size="medium"
          type="lightBulb"
        />
      </div>
      <div className="ml-3">
        <p className="ttl-tooltip leading-none mb-0">
          <Typography
            size={12}
            weight="medium"
            text="secondary"
          >
            <Translator tkey="dialog.info.tooltip.title" />
          </Typography>
        </p>
        <p className="mb-0 leading-none">
          <Typography
            size={12}
            text="gray-5"
          >
            <Translator tkey="dialog.info.tooltip.description" />
          </Typography>
        </p>
      </div>
      <div className="ml-5 min-w-5">
        <Icon
          size="small"
          type="delete"
          color="secondary"
          handleClick={dismissInfoTooltip}
        />
      </div>
    </div>
  );
};

export default ModalTooltip;

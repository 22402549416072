import React from "react";

import storyblurred from "assets/images/storyblurred.jpg";
import Waitlist from "pages/LoggedOutHomepage/WaitList";

const Waiting = ({ waitingProps }) => {
  const { openWaitList } = waitingProps || {};
  if (!openWaitList) return null;

  return (
    <div>
      <div className="absolute top-0 left-0 w-full h-full bg-white z-50 mt-12">
        <img src={storyblurred} />
      </div>
      <Waitlist {...waitingProps} />
    </div>
  );
};

export default Waiting;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Group2Data,
  GroupAcceptinviteData,
  GroupAcceptinviteParams,
  GroupAddMemberData,
  GroupAddMemberPayload,
  GroupCancelInviteData,
  GroupCancelInviteParams,
  GroupCancelSponsorshipData,
  GroupCancelSponsorshipParams,
  GroupCheckInvitationExpirationData,
  GroupCheckInvitationExpirationParams,
  GroupCheckUrlExistenceData,
  GroupClaimSponsorshipData,
  GroupClaimSponsorshipParams,
  GroupConfirmMemberIsTreePersonData,
  GroupConfirmMemberIsTreePersonParams,
  GroupData,
  GroupDeletegroupbackgroundimageData,
  GroupGetInviterInfoData,
  GroupGetInviterInfoParams,
  GroupGetMembersData,
  GroupGetMembersParams,
  GroupGetUrlData,
  GroupGetUserRoleData,
  GroupGetUserRoleParams,
  GroupGroupDetailsData,
  GroupGroupDetailsParams,
  GroupGroupStoriesData,
  GroupGroupStoriesParams,
  GroupGroupmedialistData,
  GroupGroupmedialistParams,
  GroupGrouppersonstoriesData,
  GroupGrouppersonstoriesParams,
  GroupGrouppersonstoriescategorieswithcountData,
  GroupImportStoriesData,
  GroupImportStoriesDismissPromptData,
  GroupInfoData,
  GroupInviteData,
  GroupInvitePayload,
  GroupJoinData,
  GroupJoinParams,
  GroupLeaveGroupData,
  GroupLeaveGroupParams,
  GroupMemberIsTreePersonStatusData,
  GroupMemberIsTreePersonStatusParams,
  GroupNonLoggedInStoriesData,
  GroupNonLoggedInStoriesParams,
  GroupPayload,
  GroupPendingInvitationsData,
  GroupRemoveMemberData,
  GroupRemoveMemberParams,
  GroupStoryCategoriesWithCountData,
  GroupUpdateData,
  GroupUpdateMemberRoleData,
  GroupUpdateMemberRoleParams,
  GroupUpdatePayload,
  V2GroupCancelInviteTokenData,
  V2GroupCancelInviteTokenParams,
  V2GroupGenerateInviteTokenData,
  V2GroupGenerateInviteTokenParams,
  V2GroupGroupMediaListData,
  V2GroupGroupMediaListParams,
  V2GroupJoinData,
  V2GroupJoinParams,
  V2GroupRecentlyViewedData,
  V2GroupStoriesData,
  V2GroupStoriesParams
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Group extends HttpClient {
  /**
   * No description
   *
   * @tags Group
   * @name Group
   * @request POST:/api/Group
   * @secure
   */
  group = (data: GroupPayload, params: RequestParams = {}) =>
    this.request<GroupData, any>({
      path: `/api/Group`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupUpdate
   * @request POST:/api/Group/update
   * @secure
   */
  groupUpdate = (data: GroupUpdatePayload, params: RequestParams = {}) =>
    this.request<GroupUpdateData, any>({
      path: `/api/Group/update`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupImportStories
   * @request POST:/api/Group/importStories/{groupId}/{treeId}
   * @secure
   */
  groupImportStories = (groupId: string, treeId: string, params: RequestParams = {}) =>
    this.request<GroupImportStoriesData, any>({
      path: `/api/Group/importStories/${groupId}/${treeId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupImportStoriesDismissPrompt
   * @request POST:/api/Group/importStories/{groupId}/dismissPrompt
   * @secure
   */
  groupImportStoriesDismissPrompt = (groupId: string, params: RequestParams = {}) =>
    this.request<GroupImportStoriesDismissPromptData, any>({
      path: `/api/Group/importStories/${groupId}/dismissPrompt`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupInvite
   * @request POST:/api/Group/invite
   * @secure
   */
  groupInvite = (data: GroupInvitePayload, params: RequestParams = {}) =>
    this.request<GroupInviteData, any>({
      path: `/api/Group/invite`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupAddMember
   * @request POST:/api/Group/addMember
   * @secure
   */
  groupAddMember = (data: GroupAddMemberPayload, params: RequestParams = {}) =>
    this.request<GroupAddMemberData, any>({
      path: `/api/Group/addMember`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupRemoveMember
   * @request POST:/api/Group/removeMember
   * @secure
   */
  groupRemoveMember = (query: GroupRemoveMemberParams, params: RequestParams = {}) =>
    this.request<GroupRemoveMemberData, any>({
      path: `/api/Group/removeMember`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupLeaveGroup
   * @request POST:/api/Group/leaveGroup
   * @secure
   */
  groupLeaveGroup = (query: GroupLeaveGroupParams, params: RequestParams = {}) =>
    this.request<GroupLeaveGroupData, any>({
      path: `/api/Group/leaveGroup`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupJoin
   * @request POST:/api/Group/join
   * @secure
   */
  groupJoin = (query: GroupJoinParams, params: RequestParams = {}) =>
    this.request<GroupJoinData, any>({
      path: `/api/Group/join`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupUpdateMemberRole
   * @request POST:/api/Group/updateMemberRole
   * @secure
   */
  groupUpdateMemberRole = (query: GroupUpdateMemberRoleParams, params: RequestParams = {}) =>
    this.request<GroupUpdateMemberRoleData, any>({
      path: `/api/Group/updateMemberRole`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupAcceptinvite
   * @request POST:/api/Group/acceptinvite
   * @secure
   */
  groupAcceptinvite = (query: GroupAcceptinviteParams, params: RequestParams = {}) =>
    this.request<GroupAcceptinviteData, any>({
      path: `/api/Group/acceptinvite`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupCancelInvite
   * @request POST:/api/Group/cancelInvite
   * @secure
   */
  groupCancelInvite = (query: GroupCancelInviteParams, params: RequestParams = {}) =>
    this.request<GroupCancelInviteData, any>({
      path: `/api/Group/cancelInvite`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupConfirmMemberIsTreePerson
   * @request POST:/api/Group/confirmMemberIsTreePerson
   * @secure
   */
  groupConfirmMemberIsTreePerson = (
    query: GroupConfirmMemberIsTreePersonParams,
    params: RequestParams = {}
  ) =>
    this.request<GroupConfirmMemberIsTreePersonData, any>({
      path: `/api/Group/confirmMemberIsTreePerson`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupDeletegroupbackgroundimage
   * @request DELETE:/api/Group/deletegroupbackgroundimage/{groupId}
   * @secure
   */
  groupDeletegroupbackgroundimage = (groupId: string, params: RequestParams = {}) =>
    this.request<GroupDeletegroupbackgroundimageData, any>({
      path: `/api/Group/deletegroupbackgroundimage/${groupId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupClaimSponsorship
   * @request POST:/api/Group/claimSponsorship
   * @secure
   */
  groupClaimSponsorship = (query: GroupClaimSponsorshipParams, params: RequestParams = {}) =>
    this.request<GroupClaimSponsorshipData, any>({
      path: `/api/Group/claimSponsorship`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupCancelSponsorship
   * @request POST:/api/Group/cancelSponsorship
   * @secure
   */
  groupCancelSponsorship = (query: GroupCancelSponsorshipParams, params: RequestParams = {}) =>
    this.request<GroupCancelSponsorshipData, any>({
      path: `/api/Group/cancelSponsorship`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name V2GroupGenerateInviteToken
   * @request POST:/api/v2/Group/generate-invite-token
   * @secure
   */
  v2GroupGenerateInviteToken = (
    query: V2GroupGenerateInviteTokenParams,
    params: RequestParams = {}
  ) =>
    this.request<V2GroupGenerateInviteTokenData, any>({
      path: `/api/v2/Group/generate-invite-token`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name V2GroupCancelInviteToken
   * @request POST:/api/v2/Group/cancel-invite-token
   * @secure
   */
  v2GroupCancelInviteToken = (query: V2GroupCancelInviteTokenParams, params: RequestParams = {}) =>
    this.request<V2GroupCancelInviteTokenData, any>({
      path: `/api/v2/Group/cancel-invite-token`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name V2GroupJoin
   * @request GET:/api/v2/Group/join
   * @secure
   */
  v2GroupJoin = (query: V2GroupJoinParams, params: RequestParams = {}) =>
    this.request<V2GroupJoinData, any>({
      path: `/api/v2/Group/join`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupCheckUrlExistence
   * @request GET:/api/Group/checkUrlExistence/{groupUrl}
   * @secure
   */
  groupCheckUrlExistence = (groupUrl: string, params: RequestParams = {}) =>
    this.request<GroupCheckUrlExistenceData, any>({
      path: `/api/Group/checkUrlExistence/${groupUrl}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupGroupStories
   * @request GET:/api/Group/{groupId}/groupStories/{pageNumber}/{pageSize}
   * @secure
   */
  groupGroupStories = (
    { groupId, pageNumber, pageSize, ...query }: GroupGroupStoriesParams,
    params: RequestParams = {}
  ) =>
    this.request<GroupGroupStoriesData, any>({
      path: `/api/Group/${groupId}/groupStories/${pageNumber}/${pageSize}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupNonLoggedInStories
   * @request GET:/api/Group/{invitationId}/nonLoggedInStories/{pageNumber}/{pageSize}
   * @secure
   */
  groupNonLoggedInStories = (
    { invitationId, pageNumber, pageSize, ...query }: GroupNonLoggedInStoriesParams,
    params: RequestParams = {}
  ) =>
    this.request<GroupNonLoggedInStoriesData, any>({
      path: `/api/Group/${invitationId}/nonLoggedInStories/${pageNumber}/${pageSize}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupStoryCategoriesWithCount
   * @request GET:/api/Group/{groupId}/storyCategoriesWithCount
   * @secure
   */
  groupStoryCategoriesWithCount = (groupId: string, params: RequestParams = {}) =>
    this.request<GroupStoryCategoriesWithCountData, any>({
      path: `/api/Group/${groupId}/storyCategoriesWithCount`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupGrouppersonstories
   * @request GET:/api/Group/{groupId}/{personId}/{pageNumber}/{pageSize}/grouppersonstories
   * @secure
   */
  groupGrouppersonstories = (
    { groupId, personId, pageNumber, pageSize, ...query }: GroupGrouppersonstoriesParams,
    params: RequestParams = {}
  ) =>
    this.request<GroupGrouppersonstoriesData, any>({
      path: `/api/Group/${groupId}/${personId}/${pageNumber}/${pageSize}/grouppersonstories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupGrouppersonstoriescategorieswithcount
   * @request GET:/api/Group/{groupId}/{personId}/grouppersonstoriescategorieswithcount
   * @secure
   */
  groupGrouppersonstoriescategorieswithcount = (
    groupId: string,
    personId: string,
    params: RequestParams = {}
  ) =>
    this.request<GroupGrouppersonstoriescategorieswithcountData, any>({
      path: `/api/Group/${groupId}/${personId}/grouppersonstoriescategorieswithcount`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name Group2
   * @request GET:/api/Group/{groupUrl}
   * @originalName group
   * @duplicate
   * @secure
   */
  group2 = (groupUrl: string, params: RequestParams = {}) =>
    this.request<Group2Data, any>({
      path: `/api/Group/${groupUrl}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupCheckInvitationExpiration
   * @request GET:/api/Group/checkInvitationExpiration
   * @secure
   */
  groupCheckInvitationExpiration = (
    query: GroupCheckInvitationExpirationParams,
    params: RequestParams = {}
  ) =>
    this.request<GroupCheckInvitationExpirationData, any>({
      path: `/api/Group/checkInvitationExpiration`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupInfo
   * @request GET:/api/Group/info/{groupUrl}
   * @secure
   */
  groupInfo = (groupUrl: string, params: RequestParams = {}) =>
    this.request<GroupInfoData, any>({
      path: `/api/Group/info/${groupUrl}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupGetMembers
   * @request GET:/api/Group/getMembers
   * @secure
   */
  groupGetMembers = (query: GroupGetMembersParams, params: RequestParams = {}) =>
    this.request<GroupGetMembersData, any>({
      path: `/api/Group/getMembers`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupGetUserRole
   * @request GET:/api/Group/GetUserRole
   * @secure
   */
  groupGetUserRole = (query: GroupGetUserRoleParams, params: RequestParams = {}) =>
    this.request<GroupGetUserRoleData, any>({
      path: `/api/Group/GetUserRole`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupGetInviterInfo
   * @request GET:/api/Group/getInviterInfo
   * @secure
   */
  groupGetInviterInfo = (query: GroupGetInviterInfoParams, params: RequestParams = {}) =>
    this.request<GroupGetInviterInfoData, any>({
      path: `/api/Group/getInviterInfo`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupGroupDetails
   * @request GET:/api/Group/groupDetails
   * @secure
   */
  groupGroupDetails = (query: GroupGroupDetailsParams, params: RequestParams = {}) =>
    this.request<GroupGroupDetailsData, any>({
      path: `/api/Group/groupDetails`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupGroupmedialist
   * @request GET:/api/Group/{groupId}/{treeId}/{pageNumber}/{pageSize}/groupmedialist
   * @secure
   */
  groupGroupmedialist = (
    { groupId, treeId, pageNumber, pageSize, ...query }: GroupGroupmedialistParams,
    params: RequestParams = {}
  ) =>
    this.request<GroupGroupmedialistData, any>({
      path: `/api/Group/${groupId}/${treeId}/${pageNumber}/${pageSize}/groupmedialist`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupGetUrl
   * @request GET:/api/Group/{invitationId}/getUrl
   * @secure
   */
  groupGetUrl = (invitationId: string, params: RequestParams = {}) =>
    this.request<GroupGetUrlData, any>({
      path: `/api/Group/${invitationId}/getUrl`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupPendingInvitations
   * @request GET:/api/Group/{groupId}/pendingInvitations
   * @secure
   */
  groupPendingInvitations = (groupId: string, params: RequestParams = {}) =>
    this.request<GroupPendingInvitationsData, any>({
      path: `/api/Group/${groupId}/pendingInvitations`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name GroupMemberIsTreePersonStatus
   * @request GET:/api/Group/memberIsTreePersonStatus
   * @secure
   */
  groupMemberIsTreePersonStatus = (
    query: GroupMemberIsTreePersonStatusParams,
    params: RequestParams = {}
  ) =>
    this.request<GroupMemberIsTreePersonStatusData, any>({
      path: `/api/Group/memberIsTreePersonStatus`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name V2GroupGroupMediaList
   * @request GET:/api/v2/Group/{groupId}/{treeId}/{pageNumber}/group-media-list
   * @secure
   */
  v2GroupGroupMediaList = (
    { groupId, treeId, pageNumber, ...query }: V2GroupGroupMediaListParams,
    params: RequestParams = {}
  ) =>
    this.request<V2GroupGroupMediaListData, any>({
      path: `/api/v2/Group/${groupId}/${treeId}/${pageNumber}/group-media-list`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name V2GroupStories
   * @request GET:/api/v2/Group/{groupId}/stories
   * @secure
   */
  v2GroupStories = ({ groupId, ...query }: V2GroupStoriesParams, params: RequestParams = {}) =>
    this.request<V2GroupStoriesData, any>({
      path: `/api/v2/Group/${groupId}/stories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Group
   * @name V2GroupRecentlyViewed
   * @request GET:/api/v2/Group/recently-viewed
   * @secure
   */
  v2GroupRecentlyViewed = (params: RequestParams = {}) =>
    this.request<V2GroupRecentlyViewedData, any>({
      path: `/api/v2/Group/recently-viewed`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

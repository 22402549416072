import * as components from "pages/Routes/components/searchPages";

const searchRoutes = [
  {
    path: "/newspaper-search",
    component: components.NewspaperSearch
  },
  {
    path: "/lp/newspaper",
    component: components.LpNewspaper
  },
  {
    path: "/newspaper-search/result",
    component: components.NewspapperRecords
  },
  {
    path: "/newspaper-search/browse",
    component: components.NewspapperSearchBrowse
  },
  {
    path: "/newspaper-search/location",
    component: components.NewspapperSearchBrowse
  },
  {
    path: "/newspaper-search/location/:countryName",
    component: components.NewspapperSearchBrowse
  },
  {
    path: "/newspaper-search/location/:countryName/:stateName",
    component: components.NewspapperSearchBrowse
  },
  {
    path: "/newspaper-search/location/:countryName/:stateName/:cityName",
    component: components.NewspapperSearchBrowse
  },
  {
    path: "/newspaper-search/location/:countryName/:stateName/:cityName/:publicationName",
    component: components.NewspapperSearchBrowse
  }
];

const dynamicHistoricalRecordSearchRoutes = [
  {
    path: "/historical-record-search",
    component: components.DynamicSearch
  },
  {
    path: "/historical-record-search/:collection",
    component: components.DynamicSearch
  },
  {
    path: "/historical-record-search/:collection/result",
    component: components.DynamicSearchResults
  }
];

export function getSearchRoutes() {
  return [...dynamicHistoricalRecordSearchRoutes, ...searchRoutes];
}



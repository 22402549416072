import React, { useCallback, useEffect, useContext } from "react";
import "pages/Privacy/index.css";
import { getStoryIDViaSharableLinkID } from "redux/actions/story";
import FreeAccountModal from "pages/ShareStory/FreeAccountModal";
import Loader from "components/Loader";
import LoggedOutHeader from "components/Header/LoggedOutHeader";
import useStoryShareableIndex from "../Hooks/useStoryShareableIndex";
// contexts

const ShareableStory = () => {
  const {
    history,
    dispatch,
    showAccountModal,
    setShowAccountModal,
    isAccessTokenTerms,
    handleLoginFunc,
    handleSignupFunc,
    shareableID,
    shareableStory,
  } = useStoryShareableIndex();

  useEffect(() => {
    if (shareableID) {
      dispatch(getStoryIDViaSharableLinkID(shareableID));
    }
  }, [dispatch, shareableID]);

  const userFollowViaShareableStoryLink = useCallback(
    (shareableStoryID) => {
      if (isAccessTokenTerms) {
        const storyUrl =  "view";
        history.replace(`/stories/${storyUrl}/0/${shareableStoryID}`);
      } else {
        history.replace(`/story-shared/${shareableStoryID}`);
      }
    },
    [history, isAccessTokenTerms, ]
  );

  useEffect(() => {
    if (shareableStory && shareableStory?.storyId) {
      userFollowViaShareableStoryLink(shareableStory?.storyId);
    }
  }, [shareableStory, userFollowViaShareableStoryLink]);

  const commonProps = {
    handleLogin: handleLoginFunc,
    handleSignup: handleSignupFunc,
  };

  return (
    <div>
      <LoggedOutHeader {...commonProps} />
      <Loader />
      <FreeAccountModal
        setShowAccountModal={setShowAccountModal}
        show={showAccountModal}
        {...commonProps}
      />
    </div>
  );
};

export default ShareableStory;

import axios from "axios";
import { REACT_APP_SALES_FORCE_ENDPOINT, REACT_APP_SALES_FORCE_GET_ENDPOINT } from "utils/env";

const BASE_URL = REACT_APP_SALES_FORCE_ENDPOINT;
const SMFC_BASE_GET_URL = REACT_APP_SALES_FORCE_GET_ENDPOINT;

export const apiSalesForceRequest = (method, url, data, onUploadProgress, source) => {
  const sourceJSON = source
    ? {
        cancelToken: source.token
      }
    : {};

  return axios({
    method,
    url: `${BASE_URL}?${url}`,
    data,
    ...sourceJSON,
    onUploadProgress: function (progressEvent) {
      if (onUploadProgress) onUploadProgress(progressEvent);
    }
  });
};
//To get communication preferences from SalesFore
export const apiToGetPreferences = (method, url, data, onUploadProgress, source, headers = {}) => {
  const sourceJSON = source
    ? {
        cancelToken: source.token
      }
    : {};

  return axios({
    method,
    url: `${SMFC_BASE_GET_URL}?${url}`,
    headers,
    data,
    ...sourceJSON,
    onUploadProgress: function (progressEvent) {
      if (onUploadProgress) onUploadProgress(progressEvent);
    }
  });
};

import * as CONSTANTS from "redux/constants/actionTypes";

const initialState: {
  treePeopleDropdownLoad: boolean;
  treePeople: null | unknown[];
  isLoading: boolean;
  people: unknown[];
  userTrees: unknown[];
  dropDownLoading: boolean;
  collectionLoading: boolean;
  collections: unknown[];
} = {
  treePeopleDropdownLoad: false,
  treePeople: null,
  people: [],
  isLoading: false,
  userTrees: [],
  dropDownLoading: false,
  collectionLoading: false,
  collections: []
};

export default function sidebarLeftovers(
  state = initialState,
  { type = null, payload = null }: { type?: string | null; payload?: unknown } = {}
) {
  switch (type) {
    case CONSTANTS.TREEPEOPLE.REQUEST:
      return {
        ...state,
        treePeopleDropdownLoad: true
      };
    case CONSTANTS.TREEPEOPLE.SUCCESS:
      return {
        ...state,
        treePeople: payload
      };
    case CONSTANTS.TREEPEOPLE.FAILURE:
      return {
        ...state,
        treePeopleDropdownLoad: false
      };
    case CONSTANTS.SEARCHPEOPLE.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case CONSTANTS.SEARCHPEOPLE.SUCCESS:
      return {
        ...state,
        people: payload,
        isLoading: false
      };
    case CONSTANTS.SEARCHPEOPLE.FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case CONSTANTS.SEARCHPEOPLEPAGINATE.SUCCESS:
      return {
        ...state,
        people: state.people.concat(payload),
        isLoading: false
      };
    case CONSTANTS.USERTREES.REQUEST:
      return {
        ...state,
        dropDownLoading: true
      };
    case CONSTANTS.USERTREES.SUCCESS:
      return {
        ...state,
        userTrees: payload
      };
    case CONSTANTS.USERTREES.FAILURE:
      return {
        ...state,
        dropDownLoading: false
      };
    case CONSTANTS.COLEECTIONDROPDOWN.REQUEST:
      return {
        ...state,
        collectionLoading: true
      };
    case CONSTANTS.COLEECTIONDROPDOWN.SUCCESS:
      return {
        ...state,
        collections: payload,
        collectionLoading: false
      };
    case CONSTANTS.COLEECTIONDROPDOWN.FAILURE:
      return {
        ...state,
        collectionLoading: false
      };

    default:
      return state;
  }
}

import AuthProvider from "components/AuthProvider";
import NextjsLocationListener from "pages/Routes/NextjsPathListener";
import SiteScalingPage from "pages/siteScale/SiteScale";
import { Suspense, useCallback } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import MainPage from "./MainPage";

const ErrorWrapper = ({ appErrorState }) => {
  const SiteScalingComponent = useCallback(
    () => (
      <Suspense fallback={""}>
        <SiteScalingPage />
      </Suspense>
    ),
    []
  );

  return (
    <div className="w-full h-full">
      <Router>
        <NextjsLocationListener />
        <Switch>
          <Route path="/sitescaling" component={SiteScalingComponent} />
          <Route
            render={() => (
              <AuthProvider>
                <MainPage appErrorState={appErrorState} />
              </AuthProvider>
            )}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default ErrorWrapper;

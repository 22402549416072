import {
    STORY_STARTERS_TEXT
} from "../constants";
const initialState = {
    storyStartersList: []
};

const featuredPrompts = (state = initialState, action = {}) => {
    const { type, payload } = action;
    if (type === STORY_STARTERS_TEXT) {
        return {
            ...state,
            storyStartersList: payload
        }
    } else {
        return {
            ...state,
            storyStartersList:[]
        }
    }
}
export default featuredPrompts;


import React from "react";

import TailwindModal from "components/TailwindModal";
import FreeAccountModalContent from "pages/ShareStory/Components/FreeAccountModalContent";

const FreeAccountModal = ({ show, handleLogin, handleSignup, setShowAccountModal }) => {
  if (!show) return null;
  return (
    <TailwindModal
      showClose={true}
      innerClasses="max-w-89"
      modalWrap={"px-8 py-12"}
      modalHead={"p-0 absolute right-6 top-6"}
      modalPadding={"p-0"}
      content={
        <FreeAccountModalContent
          handleLogin={handleLogin}
          handleSignup={handleSignup}
        />
      }
      showModal={show}
      setShowModal={setShowAccountModal}
      clickAwayClose={true}
    />
  );
};
export default FreeAccountModal;

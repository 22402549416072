/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ExternalAsyncPersonRecordHintsData,
  ExternalAsyncPersonRecordHintsPayload,
  ExternalHintDeterminationData,
  ExternalHintDeterminationPayload,
  ExternalPartnerHintResultsData,
  ExternalPersonRecordHintsData,
  ExternalPersonRecordHintsPayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerHint extends HttpClient {
  /**
   * No description
   *
   * @tags PartnerHint
   * @name ExternalPersonRecordHints
   * @request POST:/api/external/person-record-hints
   * @secure
   */
  externalPersonRecordHints = (
    data: ExternalPersonRecordHintsPayload,
    params: RequestParams = {}
  ) =>
    this.request<ExternalPersonRecordHintsData, any>({
      path: `/api/external/person-record-hints`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PartnerHint
   * @name ExternalHintDetermination
   * @request POST:/api/external/hint-determination
   * @secure
   */
  externalHintDetermination = (
    data: ExternalHintDeterminationPayload,
    params: RequestParams = {}
  ) =>
    this.request<ExternalHintDeterminationData, any>({
      path: `/api/external/hint-determination`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PartnerHint
   * @name ExternalAsyncPersonRecordHints
   * @request POST:/api/external/async/person-record-hints
   * @secure
   */
  externalAsyncPersonRecordHints = (
    data: ExternalAsyncPersonRecordHintsPayload,
    params: RequestParams = {}
  ) =>
    this.request<ExternalAsyncPersonRecordHintsData, any>({
      path: `/api/external/async/person-record-hints`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PartnerHint
   * @name ExternalPartnerHintResults
   * @request GET:/api/external/partnerHintResults/{requestId}
   * @secure
   */
  externalPartnerHintResults = (requestId: string, params: RequestParams = {}) =>
    this.request<ExternalPartnerHintResultsData, any>({
      path: `/api/external/partnerHintResults/${requestId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

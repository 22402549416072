import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// component
import Button from "../../components/Button";
import Typography from "../../components/Typography";
// redux
import { hideBillingToast } from "../../redux/actions/payments";
// icon
import infoIcon from "../../assets/images/info.svg";
import closeIcon from "../../assets/images/close.svg";

const BillingUpdate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showBillingToast } = useSelector((state) => state.payment);
  const goToBillingPage = () => {
    history.push("/settings/billing");
  };
  if (!showBillingToast) {
    return null;
  }
  return (
    <div className="fixed top-0 billiing-update p-4 bg-redShades-6 w-full text-white flex flex-col lg:flex-row items-center">
      <div>
        <img src={infoIcon} alt="info" />
      </div>
      <div className="text-center lg:text-left mt-2 lg:mt-0">
        <Typography size={16} weight="medium">
          <p className="pl-3.5 text-white">Your subscription payment failed. Please update your billing information. </p>
        </Typography>
      </div>
      <div className="mt-2 lg:mt-0 lg:ml-auto flex items-center">
        <Button type="link-danger" size="medium" title="Update Billing" fontWeight="medium" handleClick={() => goToBillingPage()} />
        <button className="pl-2" onClick={() => dispatch(hideBillingToast())}>
          <img src={closeIcon} alt="info" />
        </button>
      </div>
    </div>
  );
};

export default BillingUpdate;

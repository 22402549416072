import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import SpinnerImg from "../../assets/images/spinner.gif";

type LoaderProps = {
    color?: "default" | "primary" | "secondary" | "danger";
    spinner?: boolean;
    size?: CircularProgressProps["size"];
};

export default function Loader({ color, spinner, size }: LoaderProps) {
    const getLoaderColor = () => {
        switch (color) {
            case "default":
                return "#FAFAFA";
            case "secondary":
                return "#212122";
            case "danger":
                return "#FC4040";
            case "primary":
            default:
                return "#295DA1";
        }
    };

    return (
        <div className="h-full flex justify-center items-center">
            {spinner ? (
                <CircularProgress
                    color="primary"
                    disableShrink
                    sx={{
                        color: getLoaderColor(),
                        animationDuration: "550ms",
                    }}
                    size={size}
                />
            ) : (
                <img src={SpinnerImg} alt="storied loading" className="w-10 h-10" width={40} height={40} />
            )}
        </div>
    );
}
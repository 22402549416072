import * as CONSTANTS from "../constants/actionTypes";
import { DELETE_HINTS } from "redux/constants";

// Define the shape of the state
interface PersonRecordState {
  isLoading: boolean;
  personRecords: any[] | null;
  isPersonClue: boolean;
  personClue: any | null;
  hintRecordLoading: boolean;
  hintRecords: any[];
  hintOriginalRecords: any[];
  savedHintRecords: any[];
  savedHintOriginalRecords: any[];
  savedHintSubscribeIds: any[];
  savedHintRecordsLoading: boolean;
  hintCollectionIds: any[];
  newspaperMatches: any[];
  newspaperMatchesLoading: boolean;
  recentNewspaperMatches: any[];
  recentNewspaperMatchesLoading: boolean;
}

// Define the action types
interface PersonRecordAction {
  type: string;
  payload: any;
}

const initialState: PersonRecordState = {
  isLoading: true,
  personRecords: null,
  isPersonClue: false,
  personClue: null,
  hintRecordLoading: false,
  /*corrected*/hintRecords: [],
  hintOriginalRecords: [],
  hintCollectionIds: [],
  /*corrected*/savedHintRecords: [],
  savedHintOriginalRecords: [],
  savedHintSubscribeIds: [],
  savedHintRecordsLoading: false,
  newspaperMatches: [],
  newspaperMatchesLoading: false,
  recentNewspaperMatches: [],
  recentNewspaperMatchesLoading: false,
};

const personRecord = (
  state: PersonRecordState = initialState,
  { type = '', payload = null }: PersonRecordAction = {} as PersonRecordAction
) => {
  switch (type) {
    case CONSTANTS.PERSONRECORDSEARCH.REQUEST:
      return {
        ...state,
        isLoading: true,
        personRecords: null,
      };
    case CONSTANTS.PERSONRECORDSEARCH.SUCCESS:
      return {
        ...state,
        personRecords: payload,
        isLoading: false,
      };
    case CONSTANTS.PERSONRECORDSEARCH.FAILURE:
      return {
        ...state,
        personRecords: { documents: [], total: 0 },
        isLoading: false,
      };
    case CONSTANTS.PERSONCLUE.REQUEST:
      return {
        ...state,
        isPersonClue: true,
      };
    case CONSTANTS.PERSONCLUE.SUCCESS:
      return {
        ...state,
        personClue: payload,
        isPersonClue: false,
      };
    case CONSTANTS.PERSONCLUE.FAILURE:
      return {
        ...state,
        isPersonClue: false,
      };
    case CONSTANTS.HINTRECORDS.REQUEST:
      return {
        ...state,
        hintRecords: [],
        hintOriginalRecords: [],
        hintCollectionIds: [],
        hintRecordLoading: true,
      };
    case CONSTANTS.HINTRECORDS.SUCCESS:
      return {
        ...state,
        hintRecords: payload.correctedHintRecords,
        hintOriginalRecords: payload.originalHintRecords,
        hintCollectionIds: payload.collectionIds,
        hintRecordLoading: false,
      };
    case CONSTANTS.HINTRECORDS.FAILURE:
      return {
        ...state,
        hintRecordLoading: false,
      };
    case CONSTANTS.UPDATEHINTRECORDS.SUCCESS:
      return {
        ...state,
        hintRecords: state.hintRecords.map((content) =>
          content.hintId === payload.hintId ? { ...content, hintStatus: "rejected" } : content
        ),
      };
    case CONSTANTS.SAVEDHINTRECORDS.REQUEST:
      return {
        ...state,
        savedHintRecords: [],
        savedHintOriginalRecords: [],
        savedHintSubscribeIds: [],
        savedHintRecordsLoading: true,
      };
    case CONSTANTS.SAVEDHINTRECORDS.SUCCESS:
      return {
        ...state,
        savedHintRecords: payload.savedRecordsWithCorrections,
        savedHintOriginalRecords: payload.savedRecordsWithoutCorrections,
        savedHintSubscribeIds: payload.savedHintSubscribeIds,
        savedHintRecordsLoading: false,
      };
    case CONSTANTS.SAVEDHINTRECORDS.FAILURE:
      return {
        ...state,
        savedHintRecordsLoading: false,
      };
    case DELETE_HINTS:
      return {
        ...state,
        hintRecords: [],
      };
    case CONSTANTS.NEWSPAPER_MATCHES.REQUEST:
      return {
        ...state,
        newspaperMatchesLoading: true,
      };
    case CONSTANTS.NEWSPAPER_MATCHES.SUCCESS:
      return {
        ...state,
        newspaperMatches: payload,
        newspaperMatchesLoading: false,
      };
    case CONSTANTS.NEWSPAPER_MATCHES.FAILURE:
      return {
        ...state,
        newspaperMatchesLoading: false,
      };
    case CONSTANTS.RECENT_NEWSPAPER_MATCHES.REQUEST:
      return {
        ...state,
        recentNewspaperMatchesLoading: true,
      };
    case CONSTANTS.RECENT_NEWSPAPER_MATCHES.SUCCESS:
      return {
        ...state,
        recentNewspaperMatches: payload,
        recentNewspaperMatchesLoading: false,
      };
    case CONSTANTS.RECENT_NEWSPAPER_MATCHES.FAILURE:
      return {
        ...state,
        recentNewspaperMatchesLoading: false,
      };
    default:
      return state;
  }
};

export default personRecord;

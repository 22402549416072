/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  TreesAssignhomeandrepresentingpersonData,
  TreesAssignhomeandrepresentingpersonPayload,
  TreesAssignhomepersonData,
  TreesBuildguidancecompletedData,
  TreesData,
  TreesDeletetreeData,
  TreesDeletetreeforuserData,
  TreesDetailData,
  TreesDetailParams,
  TreesFamilysearchmemoriesimportstatusData,
  TreesFamilysearchtreeimportstatusData,
  TreesFfcheckData,
  TreesFinalizeGedcomImportData,
  TreesGetParsedDateData,
  TreesImportGedcomForUserData,
  TreesImportGedcomForUserPayload,
  TreesImportTreeFromFamilySearchData,
  TreesImportgedcomData,
  TreesImportgedcomPayload,
  TreesImportstatusData,
  TreesListPeopleData,
  TreesPayload,
  TreesRepresentingpersoninfoData,
  TreesStartupData,
  TreesTreePersonStoriesData,
  TreesTreePersonStoriesParams,
  TreesTreenameData,
  TreesTreenamePayload,
  TreesTreesettings2Data,
  TreesTreesettingsData,
  TreesTreesettingsPayload,
  V2TreesImportFsTreeData,
  V2TreesImportFsTreeParams
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Trees extends HttpClient {
  /**
   * No description
   *
   * @tags Trees
   * @name TreesStartup
   * @request GET:/api/Trees/startup
   * @secure
   */
  treesStartup = (params: RequestParams = {}) =>
    this.request<TreesStartupData, any>({
      path: `/api/Trees/startup`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesFfcheck
   * @request GET:/api/Trees/ffcheck
   * @secure
   */
  treesFfcheck = (params: RequestParams = {}) =>
    this.request<TreesFfcheckData, any>({
      path: `/api/Trees/ffcheck`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name Trees
   * @request POST:/api/Trees
   * @secure
   */
  trees = (data: TreesPayload, params: RequestParams = {}) =>
    this.request<TreesData, any>({
      path: `/api/Trees`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesAssignhomeperson
   * @request POST:/api/Trees/{treeId}/assignhomeperson/{homePersonId}
   * @secure
   */
  treesAssignhomeperson = (treeId: string, homePersonId: string, params: RequestParams = {}) =>
    this.request<TreesAssignhomepersonData, any>({
      path: `/api/Trees/${treeId}/assignhomeperson/${homePersonId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesBuildguidancecompleted
   * @request POST:/api/Trees/{treeId}/buildguidancecompleted
   * @secure
   */
  treesBuildguidancecompleted = (treeId: string, params: RequestParams = {}) =>
    this.request<TreesBuildguidancecompletedData, any>({
      path: `/api/Trees/${treeId}/buildguidancecompleted`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesAssignhomeandrepresentingperson
   * @request POST:/api/Trees/assignhomeandrepresentingperson
   * @secure
   */
  treesAssignhomeandrepresentingperson = (
    data: TreesAssignhomeandrepresentingpersonPayload,
    params: RequestParams = {}
  ) =>
    this.request<TreesAssignhomeandrepresentingpersonData, any>({
      path: `/api/Trees/assignhomeandrepresentingperson`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesImportGedcomForUser
   * @request POST:/api/Trees/importGedcomForUser
   * @secure
   */
  treesImportGedcomForUser = (data: TreesImportGedcomForUserPayload, params: RequestParams = {}) =>
    this.request<TreesImportGedcomForUserData, any>({
      path: `/api/Trees/importGedcomForUser`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesImportgedcom
   * @request POST:/api/Trees/importgedcom
   * @secure
   */
  treesImportgedcom = (data: TreesImportgedcomPayload, params: RequestParams = {}) =>
    this.request<TreesImportgedcomData, any>({
      path: `/api/Trees/importgedcom`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesTreename
   * @request PUT:/api/Trees/treename
   * @secure
   */
  treesTreename = (data: TreesTreenamePayload, params: RequestParams = {}) =>
    this.request<TreesTreenameData, any>({
      path: `/api/Trees/treename`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesTreesettings
   * @request PUT:/api/Trees/treesettings
   * @secure
   */
  treesTreesettings = (data: TreesTreesettingsPayload, params: RequestParams = {}) =>
    this.request<TreesTreesettingsData, any>({
      path: `/api/Trees/treesettings`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesImportstatus
   * @request GET:/api/Trees/{treeId}/importstatus
   * @secure
   */
  treesImportstatus = (treeId: string, params: RequestParams = {}) =>
    this.request<TreesImportstatusData, any>({
      path: `/api/Trees/${treeId}/importstatus`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesFinalizeGedcomImport
   * @request GET:/api/Trees/{treeId}/finalizeGedcomImport
   * @secure
   */
  treesFinalizeGedcomImport = (treeId: string, params: RequestParams = {}) =>
    this.request<TreesFinalizeGedcomImportData, any>({
      path: `/api/Trees/${treeId}/finalizeGedcomImport`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesImportTreeFromFamilySearch
   * @request POST:/api/Trees/{externalId}/{treeName}/{isSharedWithPartners}/{accessToken}/{treeId}/{homePersonId}/importTreeFromFamilySearch
   * @secure
   */
  treesImportTreeFromFamilySearch = (
    externalId: string,
    treeName: string,
    isSharedWithPartners: boolean,
    accessToken: string,
    treeId: string,
    homePersonId: string,
    params: RequestParams = {}
  ) =>
    this.request<TreesImportTreeFromFamilySearchData, any>({
      path: `/api/Trees/${externalId}/${treeName}/${isSharedWithPartners}/${accessToken}/${treeId}/${homePersonId}/importTreeFromFamilySearch`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesFamilysearchtreeimportstatus
   * @request GET:/api/Trees/{requestId}/familysearchtreeimportstatus
   * @secure
   */
  treesFamilysearchtreeimportstatus = (requestId: string, params: RequestParams = {}) =>
    this.request<TreesFamilysearchtreeimportstatusData, any>({
      path: `/api/Trees/${requestId}/familysearchtreeimportstatus`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesFamilysearchmemoriesimportstatus
   * @request GET:/api/Trees/{requestId}/familysearchmemoriesimportstatus
   * @secure
   */
  treesFamilysearchmemoriesimportstatus = (requestId: string, params: RequestParams = {}) =>
    this.request<TreesFamilysearchmemoriesimportstatusData, any>({
      path: `/api/Trees/${requestId}/familysearchmemoriesimportstatus`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesDeletetree
   * @request DELETE:/api/Trees/deletetree/{treeId}
   * @secure
   */
  treesDeletetree = (treeId: string, params: RequestParams = {}) =>
    this.request<TreesDeletetreeData, any>({
      path: `/api/Trees/deletetree/${treeId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesDeletetreeforuser
   * @request DELETE:/api/Trees/deletetreeforuser/{treeId}
   * @secure
   */
  treesDeletetreeforuser = (treeId: string, params: RequestParams = {}) =>
    this.request<TreesDeletetreeforuserData, any>({
      path: `/api/Trees/deletetreeforuser/${treeId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name V2TreesImportFsTree
   * @request POST:/api/v2/Trees/import-fs-tree
   * @secure
   */
  v2TreesImportFsTree = (query: V2TreesImportFsTreeParams, params: RequestParams = {}) =>
    this.request<V2TreesImportFsTreeData, any>({
      path: `/api/v2/Trees/import-fs-tree`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesListPeople
   * @request GET:/api/Trees/{treeId}/listPeople
   * @secure
   */
  treesListPeople = (treeId: string, params: RequestParams = {}) =>
    this.request<TreesListPeopleData, any>({
      path: `/api/Trees/${treeId}/listPeople`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesDetail
   * @request GET:/api/Trees/detail
   * @secure
   */
  treesDetail = (query: TreesDetailParams, params: RequestParams = {}) =>
    this.request<TreesDetailData, any>({
      path: `/api/Trees/detail`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesRepresentingpersoninfo
   * @request GET:/api/Trees/{userId}/{treeId}/representingpersoninfo
   * @secure
   */
  treesRepresentingpersoninfo = (userId: string, treeId: string, params: RequestParams = {}) =>
    this.request<TreesRepresentingpersoninfoData, any>({
      path: `/api/Trees/${userId}/${treeId}/representingpersoninfo`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesTreePersonStories
   * @request GET:/api/Trees/treePersonStories
   * @secure
   */
  treesTreePersonStories = (query: TreesTreePersonStoriesParams, params: RequestParams = {}) =>
    this.request<TreesTreePersonStoriesData, any>({
      path: `/api/Trees/treePersonStories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesTreesettings2
   * @request GET:/api/Trees/{treeId}/treesettings
   * @originalName treesTreesettings
   * @duplicate
   * @secure
   */
  treesTreesettings2 = (treeId: string, params: RequestParams = {}) =>
    this.request<TreesTreesettings2Data, any>({
      path: `/api/Trees/${treeId}/treesettings`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Trees
   * @name TreesGetParsedDate
   * @request GET:/api/Trees/GetParsedDate/{date}
   * @secure
   */
  treesGetParsedDate = (date: string, params: RequestParams = {}) =>
    this.request<TreesGetParsedDateData, any>({
      path: `/api/Trees/GetParsedDate/${date}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

import { v4 as uuidv4 } from "uuid";
import { AppDispatch, AppThunk } from "./payments";
import { ThunkAction } from "redux-thunk";
import {
  decodeJWTtoken,
  getGoogleSessionId,
  getOwner,
  getSubscriptionDetails,
  setGroupInfoCookie
} from "services";
import {
  DELETE,
  GET,
  INITIALUSERROLE,
  POST,
  RESETINVITEDETAILS,
  SAVE_HERO_IMAGE,
  THUMBNAIL_UPLOAD_COMPLETE,
  EMPTYGROUPMEMBERS
} from "redux/constants";
import * as CONSTANTS from "redux/constants/actionTypes";
import * as API_URLS from "redux/constants/apiUrl";
import { getUpdateGroupInfo, groupImageUploadPayload } from "redux/helpers";
import { combineGroupMembers } from "redux/helpers/groupMembers";
import { apiRequest } from "redux/requests";
import { actionCreator, callApi } from "redux/utils";
import { basicPlanName, checkWebsitePlan, getPlanName } from "redux/helpers/dataLayerHelper";
import { planDetail } from "utils/constant";
import { REACT_APP_CLIENT_ID } from "utils/env";
import { dismissGroupPrompt, openCloseGroupModalViaHomepagePrompt } from "./homepagePrompts";
import { getUserGroups as getSideBarUserGroups, leaveUserGroup as leaveSidebarUserGroup } from "./leftSideNav";
import { addMessage } from "./toastr";
import { dataLayerGroup } from "./utils/datalayer";

const createHeader = (): { [key: string]: string } => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId()
  };
};

interface CreateGroupParams {
  data: any;
  callback: (data: any) => void;
  skip?: boolean;
}

export const createGroup = ({ data, callback, skip }: CreateGroupParams): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const url = API_URLS.CreateGroupApi;
    const staticHeader = createHeader();

    if (skip) dispatch(actionCreator(CONSTANTS.SKIPINVITE.REQUEST));
    else dispatch(actionCreator(CONSTANTS.CREATEGROUP.REQUEST));

    try {
      const res = await callApi("POST", url, data, false, staticHeader);
      callback(res.data);

      const getToken = decodeJWTtoken();
      const planDetails = getSubscriptionDetails();
      const plans = Object?.values(planDetail);
      const getPlan = basicPlanName(plans, planDetails);
      const planName = getPlanName(getPlan);
      const checkUserType = checkWebsitePlan(getToken, planName);
      dataLayerGroup(getOwner(), checkUserType);

      setTimeout(() => {
        dispatch(getSideBarUserGroups());
      }, 1000);

      dispatch(actionCreator(CONSTANTS.CREATEGROUP.SUCCESS, res.data));
      dispatch(dismissGroupPrompt());
      await dispatch(addMessage("Your group has been created.", "success"));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.CREATEGROUP.FAILURE, err?.response?.data));
      await dispatch(addMessage("Sorry, your group couldn't be saved. Please try again.", "error"));
    }
  };
};

export const editGroupApi = (payload: any, cb: () => void): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.UpdateGroupApi;
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.CREATEGROUP.REQUEST));

    try {
      await callApi("POST", url, payload, false, staticHeader);
      cb();
      dispatch(getSideBarUserGroups());
      dispatch(actionCreator(CONSTANTS.CREATEGROUP.SUCCESS));
      await dispatch(addMessage("Your group has been updated.", "success"));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.CREATEGROUP.FAILURE));
      await dispatch(addMessage("Sorry, your group couldn't be updated. Please try again.", "error"));
    }
  };
};

export const getGroupDetails = (groupUrl: string, refetch = false): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const url = API_URLS.getGroupDetails(groupUrl);
    const staticHeader = createHeader();

    if (getState().group.groupDetails?.url !== groupUrl || refetch) {
      if (!refetch) dispatch(actionCreator(CONSTANTS.GROUPDETAILS.REQUEST));
      try {
        const res = await callApi("GET", url, groupUrl, false, staticHeader);
        dispatch(actionCreator(CONSTANTS.GROUPDETAILS.SUCCESS, res.data || {}));
        return res.data;
      } catch (err: any) {
        dispatch(actionCreator(CONSTANTS.GROUPDETAILS.FAILURE, err?.response?.data));
      }
    }
  };
};

interface CheckExistingGroupParams {
  groupUrl: string;
  callback: (data: any) => void;
}

export const checkExistingGroup = ({ groupUrl, callback }: CheckExistingGroupParams): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.checkExistingGroup(groupUrl);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.CHECKEXISTINGROUP.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.CHECKEXISTINGROUP.SUCCESS, res.data));
      callback(res.data);
    } catch (err: any) {
      callback([]);
      dispatch(actionCreator(CONSTANTS.CHECKEXISTINGROUP.FAILURE, err?.response?.data));
    }
  };
};

export const clearExistingGroup = (): AppThunk => {
  return (dispatch: AppDispatch) => {
    dispatch(actionCreator(CONSTANTS.CHECKEXISTINGROUP.SUCCESS, []));
    dispatch(openCloseGroupModalViaHomepagePrompt(false));
  };
};

export const createInvite = (data: any, resetForm: () => void): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.createInvite;
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.CREATEINVITE.REQUEST));

    try {
      const res = await callApi("POST", url, data, false, staticHeader);
      resetForm();
      dispatch(actionCreator(CONSTANTS.CREATEINVITE.SUCCESS, res.data));
      await dispatch(addMessage("Invite sent successfully.", "success"));
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
      dispatch(actionCreator(CONSTANTS.CREATEINVITE.FAILURE, (err as any)?.response?.data));
      await dispatch(addMessage(`Sorry, invite sending failed. Please try again. ${errorMessage}`, "error"));
    }
  };
};

export const resetInvite = (): AppThunk => {
  return (dispatch: AppDispatch) => {
    dispatch(actionCreator(CONSTANTS.RESETINVITE.REQUEST));
  };
};

export const isGroupInvitationValid = (invitationId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.isGroupInvitationValid(invitationId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.ISGROUPINVITATIONVALID.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.ISGROUPINVITATIONVALID.SUCCESS, res.data));
    } catch (err: any) {
      dispatch(
        actionCreator(CONSTANTS.ISGROUPINVITATIONVALID.FAILURE, err?.response?.data?.status)
      );
    }
  };
};

export const getGroupInfo = (groupUrl: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.getGroupInfo(groupUrl);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.GETGROUPINFO.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.GETGROUPINFO.SUCCESS, res.data));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.GETGROUPINFO.FAILURE, err?.response?.data?.status));
    }
  };
};

export const getAllMembers = (groupId: string, isSponsor: boolean): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(actionCreator(CONSTANTS.GETGROUPMEMBERS.REQUEST));
      const groupMembersUrl = API_URLS.getGroupMembers(groupId);
      const pendingMembersUrl = API_URLS.getPendingInvitations(groupId);
      const staticHeader = createHeader();

      const accepted = await callApi(GET, groupMembersUrl, {}, false, staticHeader);
      const pending = isSponsor
        ? await callApi(GET, pendingMembersUrl, {}, false, staticHeader)
        : { data: [] };

      const pendingData = typeof pending.data === "string" ? [] : pending.data;

      // combining data & adding additional keys according to requirement
      const payload = combineGroupMembers(accepted.data, pendingData);
      dispatch(actionCreator(CONSTANTS.GETGROUPMEMBERS.SUCCESS, payload));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.GETGROUPMEMBERS.FAILURE, err?.response?.data?.status));
    }
  };
};

export const getUserGroups = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.getUserGroupsListApi();
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.GETUSERGROUPSLIST.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.GETUSERGROUPSLIST.SUCCESS, res.data));
      return res.data;
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.GETUSERGROUPSLIST.FAILURE));
    }
  };
};

export const getUserGroupSponsoredList = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.getUserGroupsSponsoredApi();
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.GETUSERGROUPSSPONSOREDLIST.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.GETUSERGROUPSSPONSOREDLIST.SUCCESS, res.data));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.GETUSERGROUPSSPONSOREDLIST.FAILURE));
    }
  };
};

export const groupAcceptInvite = (inviteId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.acceptGroupInviteApi(inviteId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.ACCEPTGROUPINVITE.REQUEST));

    try {
      const res = await callApi("POST", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.ACCEPTGROUPINVITE.SUCCESS, res.data));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.ACCEPTGROUPINVITE.FAILURE));
    }
  };
};

export const groupAcceptJoin = (inviteId: string, groupInfo: any): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.acceptGroupJoinApi(inviteId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.ACCEPTGROUPJOIN.REQUEST));

    try {
      const res = await callApi("POST", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.ACCEPTGROUPJOIN.SUCCESS, res.data));

      const response = await apiRequest(GET, `Users/sessiongroupmemberinfo`);
      setGroupInfoCookie(response.data?.groupMemberRole);

      if (response.data?.groupMemberRole) {
        const finalGroupPayload = getUpdateGroupInfo(groupInfo, response.data?.groupMemberRole);
        dispatch(actionCreator("UPDATE_COOKIE_GROUP_INFO", finalGroupPayload));
      }
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.ACCEPTGROUPJOIN.FAILURE));
    }
  };
};

export const addRecentlyViewedGroup = (groupId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.addRecentlyViewedGroup(groupId);
    const staticHeader = createHeader();

    try {
      const res = await callApi("POST", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.ADDRECENTLYVIEWEDGROUP.SUCCESS));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.ADDRECENTLYVIEWEDGROUP.FAILURE));
    }
  };
};

export const getUserRole = (groupId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.getUserRole(groupId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.GETUSERROLE.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.GETUSERROLE.SUCCESS, res.data));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.GETUSERROLE.FAILURE, err?.response?.data?.status));
    }
  };
};

export const initialUserRole = (): AppThunk => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: INITIALUSERROLE,
      payload: ""
    });
  };
};

export const followUser = (
  userId: string,
  username: string,
  index: number,
  setFilteredMembers: (state: any) => void,
  fromModal = false
): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.followUser(userId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.FOLLOWUSER.REQUEST, true));

    try {
      await callApi("POST", url, {}, false, staticHeader);

      if (!fromModal) {
        setFilteredMembers((state: any[]) =>
          state.map((item) => (item.userId === userId ? { ...item, isFollowing: true } : item))
        );
      }

      dispatch(actionCreator(CONSTANTS.FOLLOWUSER.SUCCESS, { userId, index, isFollowing: true }));
      await dispatch(addMessage(`You are now following ${username}`, "success"));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.FOLLOWUSER.FAILURE, false));
    }
  };
};

export const unfollowUser = (
  userId: string,
  username: string,
  index: number,
  setFilteredMembers: (state: any) => void,
  fromModal = false
): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.unfollowUser(userId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.UNFOLLOWUSER.REQUEST, true));

    try {
      await callApi("POST", url, {}, false, staticHeader);

      if (!fromModal) {
        setFilteredMembers((state: any[]) =>
          state.map((item) => (item.userId === userId ? { ...item, isFollowing: false } : item))
        );
      }

      dispatch(
        actionCreator(CONSTANTS.UNFOLLOWUSER.SUCCESS, { userId, index, isFollowing: false })
      );
      await dispatch(addMessage(`You are no longer following ${username}`, "success"));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.UNFOLLOWUSER.FAILURE, false));
    }
  };
};

interface RemoveFromGroupPayload {
  groupId: string;
  userId: string;
  memberId: string;
}

export const removeFromGroupApi = (
  payload: RemoveFromGroupPayload,
  callback: (payload: RemoveFromGroupPayload) => void,
  leaveGroup = false
): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.RemoveFromGroupApi(payload);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.REMOVEFROMGROUP.REQUEST));

    try {
      await callApi("POST", url, {}, false, staticHeader);
      callback(payload);
      dispatch(actionCreator(CONSTANTS.REMOVEFROMGROUP.SUCCESS, payload));

      const message = leaveGroup ? `You left the group` : `Group member removed successfully`;
      await dispatch(addMessage(message, "success"));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.REMOVEFROMGROUP.FAILURE));
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const checkGroupCreationRightsApi = (
  successCallback: (success: boolean) => void,
  errorCallback: (error: any) => void
): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.CheckGroupCreationRightsApi();
    const staticHeader = createHeader();

    try {
      await callApi("GET", url, {}, false, staticHeader);
      successCallback(true);
    } catch (err: any) {
      const statusCode = [402, 403];
      if (statusCode.includes(err.response.status)) {
        return errorCallback(err.response);
      }
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const getPendingInvites = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.getPendingInvites;
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.GETPENDINGINVITES.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.GETPENDINGINVITES.SUCCESS, res.data));
    } catch (error: any) {
      dispatch(actionCreator(CONSTANTS.GETPENDINGINVITES.FAILURE));
    }
  };
};

export const getInviterInfo = (inviteId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.getInviterInfo(inviteId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.GETINVITERINFO.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.GETINVITERINFO.SUCCESS, res.data));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.GETINVITERINFO.FAILURE));
    }
  };
};

export const resetInviteDetails = (): AppThunk => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: RESETINVITEDETAILS
    });
  };
};

interface ImportStoriesTreeParams {
  groupId: string;
  treeId: string;
}

export const importStoriesTree = (
  param: ImportStoriesTreeParams,
  setShowImportDialog: (value: boolean) => void,
  setLoadStoriesData: (data: any) => void
): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    if (param.groupId !== getState().feeds.groupId) {
      const url = API_URLS.getTreeUserpageStories(param);
      const staticHeader = createHeader();

      dispatch(actionCreator(CONSTANTS.GETSTORIESFEED.REQUEST));

      try {
        const res = await callApi("GET", url, {}, false, staticHeader);
        const dataRespo = res.data;
        if (dataRespo.length > 0) {
          setShowImportDialog(true);
          setLoadStoriesData(dataRespo);
        }
      } catch (err: any) {
        dispatch(actionCreator(CONSTANTS.GETSTORIESFEED.FAILURE, err?.response?.data));
      }
    }
  };
};

interface GroupImportStoriesApiParams {
  groupId: string;
  treeId: string;
  isImportSotries?: boolean;
}

export const groupImportStoriesApi = (
  param: GroupImportStoriesApiParams,
  type: "YES" | "NO",
  callback: (status: boolean) => void,
  storiesImported: any[] = []
): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const url =
      type === "YES"
        ? API_URLS.GroupImportStoriesApi(param)
        : API_URLS.GroupImportStoriesDismisApi(param);
    const staticHeader = createHeader();

    try {
      await callApi("POST", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.UPDATEGROUPDETAILS.SUCCESS, param.isImportSotries));
      if (type === "YES") {
        dispatch(
          actionCreator(CONSTANTS.GETSTORIESFEED.SUCCESS, [
            ...storiesImported,
            ...getState().feeds.stories
          ])
        );
      }
      callback(false);
    } catch (err: any) {
      callback(false);
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const uploadGroupImage = (imagePayload: any): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: CONSTANTS.UPLOAD_GROUP_IMAGE.REQUEST, payload: imagePayload });
      dispatch({ type: THUMBNAIL_UPLOAD_COMPLETE });
      const payload = groupImageUploadPayload(imagePayload);
      await apiRequest(POST, `Media/uploadgroupbackgroundimage`, payload);
      dispatch(getSideBarUserGroups());
      dispatch({ type: SAVE_HERO_IMAGE });
      await dispatch(addMessage("Group image uploaded successfully.", "success"));
    } catch (err: any) {
      dispatch({ type: CONSTANTS.UPLOAD_GROUP_IMAGE.FAILURE });
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const checkTreePersonStatus = (groupId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: CONSTANTS.CHECK_TREE_PERSON_STATUS.REQUEST });
      const res = await apiRequest(GET, `Group/memberIsTreePersonStatus?groupId=${groupId}`);
      if (res.data?.treePersonId && !res.data.isConfirmed) {
        const personId = res.data.treePersonId;
        const res1 = await apiRequest(GET, `Persons/${personId}/treePersonInfo`);
        dispatch({ type: CONSTANTS.CHECK_TREE_PERSON_STATUS.SUCCESS, payload: res1.data });
      } else {
        dispatch({ type: CONSTANTS.CHECK_TREE_PERSON_STATUS.FAILURE });
      }
    } catch (err: any) {
      dispatch({ type: CONSTANTS.CHECK_TREE_PERSON_STATUS.FAILURE });
    }
  };
};

export const confirmMemberStatus = (groupId: string, isAccepted: boolean): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: CONSTANTS.CONFIRM_MEMBER_STATUS.REQUEST });
      await apiRequest(
        POST,
        `Group/confirmMemberIsTreePerson?groupId=${groupId}&isAccepted=${isAccepted}`
      );
      dispatch({ type: CONSTANTS.CONFIRM_MEMBER_STATUS.SUCCESS });
      await dispatch(addMessage("Confirmed successfully.", "success"));
    } catch (err: any) {
      dispatch({ type: CONSTANTS.CONFIRM_MEMBER_STATUS.FAILURE });
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const editGroupImage = (imagePayload: any): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: CONSTANTS.UPLOAD_GROUP_IMAGE.REQUEST, payload: imagePayload });
      dispatch({ type: THUMBNAIL_UPLOAD_COMPLETE });
      const payload = groupImageUploadPayload(imagePayload, true);
      await apiRequest(POST, `Media/resizegroupbackgroundimage`, payload);
      dispatch(getSideBarUserGroups());
      dispatch({ type: SAVE_HERO_IMAGE });
      await dispatch(addMessage("Group image updated successfully", "success"));
    } catch (err: any) {
      dispatch({ type: CONSTANTS.UPLOAD_GROUP_IMAGE.FAILURE });
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const deleteGroupImage = (groupId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: CONSTANTS.DELETE_GROUP_IMAGE.REQUEST });
      await apiRequest(DELETE, `Group/deletegroupbackgroundimage/${groupId}`);

      dispatch(getSideBarUserGroups());
      dispatch({ type: CONSTANTS.DELETE_GROUP_IMAGE.SUCCESS });
      await dispatch(addMessage("Group image removed successfully.", "success"));
    } catch (err: any) {
      dispatch({ type: CONSTANTS.DELETE_GROUP_IMAGE.FAILURE });
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const leaveGroupApi = (groupId: string, callback: () => void): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.LeaveGroupApi(groupId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.LEAVEGROUP.REQUEST));

    try {
      await callApi("POST", url, {}, false, staticHeader);
      dispatch(leaveSidebarUserGroup({ groupId }));
      dispatch(actionCreator(CONSTANTS.LEAVEGROUP.SUCCESS, groupId));
      await dispatch(addMessage("Group removed successfully.", "success"));
      callback();
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.LEAVEGROUP.FAILURE));
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const cancelGroupSponsorShipApi = (
  groupId: string,
  callback: () => void
): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.CancelGroupSponsorShipApi(groupId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.LEAVEGROUP.REQUEST));

    try {
      await callApi("POST", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.LEAVEGROUP.SUCCESS, groupId));
      await dispatch(addMessage("Group sponsorship removed successfully.", "success"));
      callback();
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.LEAVEGROUP.FAILURE));
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const getGroupUrl = (invitationId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.getGroupUrl(invitationId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.GETGROUPURL.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.GETGROUPURL.SUCCESS, res.data));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.GETGROUPURL.FAILURE, err?.response?.data?.status));
    }
  };
};

export const emptyGroupMembers = (): AppThunk => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: EMPTYGROUPMEMBERS });
  };
};

export const cancelInvite = (
  invitationId: string,
  callback: (invitationId: string) => void
): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = API_URLS.cancelInvite(invitationId);
    const staticHeader = createHeader();

    dispatch(actionCreator(CONSTANTS.CANCELINVITE.REQUEST));

    try {
      await callApi("POST", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.CANCELINVITE.SUCCESS, invitationId));
      callback(invitationId);
      await dispatch(addMessage("Cancelled invite successfully.", "success"));
    } catch (err: any) {
      dispatch(actionCreator(CONSTANTS.CANCELINVITE.FAILURE));
      await dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const claimSponsorshipApi = (
  groupId: string,
  groupName: string,
  callback?: () => void
): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState) => {
    const url = API_URLS.ClaimSponsorshipApi(groupId);
    const staticHeader = createHeader();
    dispatch({ type: CONSTANTS.CLAIMSPONSORSHIP.REQUEST });

    try {
      await callApi("POST", url, {}, false, staticHeader);
      const isSponsorshipActive = true;
      dispatch({ type: CONSTANTS.CLAIMSPONSORSHIP.SUCCESS, payload: isSponsorshipActive });
      await dispatch(addMessage(`You are now the sponsor for ${groupName} Group`, "success"));
      if (callback) callback();
    } catch (err: any) {
      await dispatch(addMessage(err.response?.data?.[0]?.description || "Something went wrong", "error"));
      dispatch({ type: CONSTANTS.CLAIMSPONSORSHIP.FAILURE });
    }
  };
};
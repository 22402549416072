import * as CONSTANTS from "../constants/actionTypes";

const initialState = {
  report: [],
  loading: false,
  error: false,
  forbidden: false
};
const adminForbidden = (payload) => (payload === 403 ? true : false);


const Reportstory = (state = initialState, { type = null, payload = null } = {}) => {
  switch (type) {
    case CONSTANTS.REPORTSTORY.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        forbidden: false,
      };

    case CONSTANTS.REPORTSTORY.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        report: payload,
        forbidden: false,
      };

    case CONSTANTS.REPORTSTORY.FAILURE:
      return {
        ...state,
        loading: true,
        error: true,
        forbidden: adminForbidden(payload),
      };
    default:
      return state;
  }
};

export default Reportstory;
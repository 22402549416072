import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal, useAccount } from "@azure/msal-react";

import { loginRequest } from "../../../authConfig";

import {
  getUserFlow,
  removeFamilySearchTreeId,
  removeFamilySearchHomePersonId,
  removeFamilySearchRequestId,
} from "services";

import { getSnackBarStatus, getFsTreeSnackBarStatus } from "redux/helpers";
import { getImportStatus, triggerPendingSnackbar } from "redux/actions/gedcom";
import {
  getFamilySearchTreeStatus,
  getFSMemoriesImportStatus,
  displayMemoriesSuccessSnackbar,
  displayFSTreeSuccessSnackbar,
} from "redux/actions/familySearch";
import { useAllUsersTypeaheadCache } from "components/SearchPeople/useAllUsersTypeaheadCache";

let statusTimer;
let intervalId = null, timeoutId, memoryIntervallId = null;

const usePrivateInit = () => {
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { invalidateAllUsersTypeaheadQuery } = useAllUsersTypeaheadCache();
  const { appError } = useSelector((state) => state.user);
  const { assigned, importInProgress, newTreeId, selectedHomePerson, pendingNotificationSnackbar } =
    useSelector((state) => state.gedcom);
  const {
    importedFamilySearchTree,
    familySearchImportProgressComplete,
    showMemoriesSnackbar,
    FSMemoriesImportComplete,
    FSMemoryImportCheck,
  } = useSelector((state) => state.familySearch);

  const authRequest = { ...loginRequest };
  const [userFlow] = useState(getUserFlow());

  const showSnackBar = getSnackBarStatus(window.location.href, newTreeId);
  const showFsTreeSnackBar = getFsTreeSnackBarStatus(window.location.href);

  useEffect(() => {
    if (!assigned && importInProgress) {
      statusTimer = setInterval(() => {
        dispatch(getImportStatus(newTreeId, selectedHomePerson, invalidateAllUsersTypeaheadQuery));
      }, 3000);
    }
    return () => {
      clearInterval(statusTimer);
    };
  }, [
    dispatch,
    assigned,
    importInProgress,
    newTreeId,
    selectedHomePerson,
    invalidateAllUsersTypeaheadQuery,
  ]);

  useEffect(() => {
    if (
      pendingNotificationSnackbar &&
      pendingNotificationSnackbar.pendingSnackbar &&
      showSnackBar
    ) {
      setTimeout(() => {
        if (getSnackBarStatus(window.location.href, newTreeId)) {
          dispatch(triggerPendingSnackbar(pendingNotificationSnackbar));
        }
        // This needs to be updated to rely on SignalR to determine when the thing is done uploading rather than the polling that is happening here.
      }, 100);
    }
  }, [dispatch, newTreeId, pendingNotificationSnackbar, selectedHomePerson, showSnackBar]);

  const checkImportedTreeStatus = () => {
    intervalId = setInterval(() => {
      // Call your function here at every 15 seconds interval
      if (!familySearchImportProgressComplete) {
        dispatch(getFamilySearchTreeStatus());
      } else {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      }
    }, 15000); // 15 seconds
  };

  useEffect(() => {
    if (importedFamilySearchTree) {
      timeoutId = setTimeout(() => {
        // Call your function here after 2.5 minutes
        dispatch(getFamilySearchTreeStatus());
        checkImportedTreeStatus();
      }, 60000); // call after 1 minute
    }
    return () => {
      clearInterval(intervalId); // Cleanup function to clear the interval
      clearTimeout(timeoutId); // Cleanup function to clear the timeout
    };
  }, [importedFamilySearchTree]);

  //To check the status of FS memories import
  useEffect(() => {
    if (showMemoriesSnackbar || FSMemoryImportCheck) {
      // Check FS memories import status every 15 seconds
      memoryIntervallId = setInterval(() => {
        dispatch(getFSMemoriesImportStatus());
      }, 15000); // 15secs
      if (FSMemoriesImportComplete) {
        clearInterval(memoryIntervallId);
      }
      return () => clearInterval(memoryIntervallId);
    }
  }, [showMemoriesSnackbar, FSMemoryImportCheck]);

  useEffect(() => {
    if (FSMemoriesImportComplete) {
      dispatch(displayMemoriesSuccessSnackbar(FSMemoriesImportComplete.treeId, FSMemoriesImportComplete.id));
      removeFamilySearchRequestId();
      removeFamilySearchTreeId();
      removeFamilySearchHomePersonId();
      clearInterval(memoryIntervallId);
    }
  }, [FSMemoriesImportComplete, dispatch]);

  useEffect(() => {
    if (familySearchImportProgressComplete && showFsTreeSnackBar) {
      dispatch(displayFSTreeSuccessSnackbar(familySearchImportProgressComplete?.treeId, familySearchImportProgressComplete?.id));

    }
  }, [familySearchImportProgressComplete]);

  return {
    dispatch,
    account,
    appError,
    assigned,
    importInProgress,
    newTreeId,
    selectedHomePerson,
    pendingNotificationSnackbar,
    authRequest,
    userFlow,
    showSnackBar,
  };
};

export default usePrivateInit;

import React from "react";
import Button from "../../components/Button";
import TailwindModal from "../../components/TailwindModal";
import {  useHistory } from "react-router-dom";
import PermissionCard from "components/PermissionCard";

const getTitle = (values) => {
  let title;
  switch (values) {
    case 1:
      title = "";
      break;
    case 4:
      title = "Well, well, well....";
      break;
    default:
      title = "";
      break;
  }
  return title;
};
const getClass = (values, type) => {
  let innerClasses;
  let modalHead;
  switch (values) {
    case 1:
      innerClasses = "max-w-2xl";
      modalHead = "sm:pb-6 pb-10 sml:ml-auto sml:w-2/4 sml:pl-2 pl-0";
      break;
    case 4:
      innerClasses = "max-w-sm";
      modalHead = "pb-6";
      break;
    default:
      innerClasses = "";
      modalHead = "";
      break;
  }
  return type === 1 ? innerClasses : modalHead;
};
const Waitlist = ({
  step,
  setOpenWaitList,
  openWaitList,
  storyId,
  alreadyViewed = false,
}) => {
  const history = useHistory();

  const handleCancelButton = () => {
    setOpenWaitList(false);
    if (alreadyViewed) {
      history.push("/");
    }
  };
  return (
    <>
      <TailwindModal
        title={getTitle(step)}
        innerClasses={getClass(step, 1)}
        titleFontWeight={"text-xl typo-font-bold"}
        modalWrap={"sm:px-8 px-6 py-6"}
        modalHead={getClass(step, 2)}
        modalPadding={"p-0"}
        content={
          <>
            {step === 1 &&
              <>
                {alreadyViewed && (
                  <PermissionCard openWaitList={true} storyId={storyId} />
                )}
                <div className="w-full mt-10 flex justify-center">
                  <Button
                    onClick={() => handleCancelButton()}
                    size="large"
                    fontWeight="medium"
                    width="full"
                    title="Close"
                  />
                </div>
              </>
            }
          </>
        }
        showModal={openWaitList}
        setShowModal={handleCancelButton}
        clickAwayClose={false}
      />
    </>
  );
};

export default Waitlist;

import { EventType, InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { FF_PlusPlansContext, MainsPageContext } from "contexts";
import Pages from "pages";
import { useEffect, useMemo } from "react";
import { getUserFlow } from "services";
import { useFeatureFlags } from "services/featureFlag";
import { b2cPolicies } from "./authConfig";

const MainPage = ({ ...props }) => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing.
   */
  const { instance } = useMsal();
  const { enabled: FF_PlusPlans } = useFeatureFlags("FF_PlusPlans");
  const { enabled: FF_BlackFriday } = useFeatureFlags("FF_BlackFriday");
  const { enabled: FF_CyberMonday } = useFeatureFlags("FF_CyberMonday");
  const { enabled: anniversaryPlanEnabled } = useFeatureFlags("FF_AnniversaryPlan");
  const { enabled: twoPhotoLayoutViewEnabled } = useFeatureFlags("FF_TwoPhotoLayoutDetailsView");
  const { enabled: FF_Recipes } = useFeatureFlags("FF_Recipes");
  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   */
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      let userflow = getUserFlow();
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(b2cPolicies.authorities.forgotPassword).catch((e) => {
              return;
            });
          }
        }
        if (
          event.error &&
          userflow !== "signUp" &&
          event.error.errorMessage.indexOf("AADB2C90091") > -1
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.signUpSignInUp);
          }
        }
      }

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           */
          const logOutCase =
            event.payload.account.idTokenClaims["tfp"] === b2cPolicies.names.forgotPassword ||
            event.payload.account.idTokenClaims["tfp"] === b2cPolicies.names.editProfile;
          if (logOutCase) {
            instance.logout();
          }
        }
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        instance.logout();
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  const mainsPageContextValue = useMemo(
    () => ({
      FF_BlackFriday,
      FF_CyberMonday,
      anniversaryPlanEnabled,
      twoPhotoLayoutViewEnabled,
      FF_Recipes
    }),
    [FF_BlackFriday, FF_CyberMonday, anniversaryPlanEnabled, twoPhotoLayoutViewEnabled, FF_Recipes]
  );

  return (
    <MainsPageContext.Provider value={mainsPageContextValue}>
      <FF_PlusPlansContext.Provider value={{ FF_PlusPlans }}>
        <Pages {...props} />
      </FF_PlusPlansContext.Provider>
    </MainsPageContext.Provider>
  );
};

export default MainPage;

import { useAuth0 } from "@auth0/auth0-react";
import Loader from "components/Loader";

const AuthenticatedTemplate = ({ children }) => {
    const { isAuthenticated } = useAuth0();
    return (
        !isAuthenticated ? <Loader /> : <> {children}  </>
    )
}

export default AuthenticatedTemplate;
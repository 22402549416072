import * as CONSTANTS from "../../constants/actionTypes";
import { CLEARCOMMENTS } from "../../constants/index";

type State = {
  isLoading: boolean;
  isListLoading: boolean;
  list: Array<any>;
  error: boolean;
  count: number;
};

const initialState: State = {
  isLoading: false,
  isListLoading: false,
  list: [],
  error: false,
  count: 0,
};

type Action = { type: string; payload: any };

const comments = (state: State = initialState, action: Action) => {
  const { type, payload } = action;
  const request = {
    ...state,
    isLoading: true,
    error: false,
  },
    failure = {
      ...state,
      isLoading: false,
      error: true,
    };
  switch (type) {
    case CONSTANTS.GETCOMMENTS.REQUEST:
      return {
        ...state,
        isListLoading: true,
        error: false,
      };
    case CONSTANTS.GETCOMMENTS.SUCCESS:
      const previousId = [...state.list].map((item) => item.commentId);
      const prevComments = [...payload].filter((item) => !previousId.includes(item.commentId));
      return {
        ...state,
        list: [...state.list, ...prevComments],
        isListLoading: false,
      };
    case CLEARCOMMENTS:
      return {
        ...state,
        list: [],
      };
    case CONSTANTS.GETCOMMENTS.FAILURE:
      return {
        ...state,
        isListLoading: false,
        error: true,
      };
    case CONSTANTS.ADDCOMMENTS.REQUEST:
      return request;
    case CONSTANTS.ADDCOMMENTS.SUCCESS:
      return {
        ...state,
        list: [payload, ...state?.list],
        count: state.count + 1,
        isLoading: false,
      };
    case CONSTANTS.ADDCOMMENTS.FAILURE:
      return failure;
    case CONSTANTS.COUNTCOMMENTS.REQUEST:
      return request;
    case CONSTANTS.COUNTCOMMENTS.SUCCESS:
      return {
        ...state,
        count: payload,
        isLoading: false,
        error: false,
      };
    case CONSTANTS.COUNTCOMMENTS.FAILURE:
      return failure;
    default:
      return state;
  }
};

export default comments;

import {
  CLEAR_MEMORIES_SNACKBAR,
  FAMILY_SEARCH_IMPORT_PROGRESS_COMPLETE,
  FAMILY_SEARCH_MEMORIES_IMPORT_COMPLETE,
  GET_FS_USER,
  GET_IMPORTED_TREE,
  SHOW_HIDE_VIDEO_FOR_LDS_USER
} from "../constants";

const initialState = {
  isLoading: false,
  fsUserDetails: null,
  fsUserId: null,
  importedFamilySearchTree: null,
  familySearchImportProgressComplete: null,
  FSMemoriesImportComplete: null,
  showMemoriesSnackbar: false,
  FSMemoryImportCheck: false,
  showVideoForLdsUser: false
};

const familySearch = (state = initialState, { type = null, payload = null } = {}) => {
  switch (type) {
    case GET_FS_USER: {
      return {
        ...state,
        fsUserDetails: payload,
        fsUserId: payload?.persons[0]?.id,
        showMemoriesSnackbar: false,
        FSMemoriesImportComplete: null
      };
    }
    case GET_IMPORTED_TREE: {
      return {
        ...state,
        importedFamilySearchTree: payload
      };
    }
    case FAMILY_SEARCH_IMPORT_PROGRESS_COMPLETE: {
      return {
        ...state,
        familySearchImportProgressComplete: payload,
        showMemoriesSnackbar: true,
        FSMemoryImportCheck: true,
        importedFamilySearchTree: null
      };
    }
    case FAMILY_SEARCH_MEMORIES_IMPORT_COMPLETE: {
      return {
        ...state,
        FSMemoriesImportComplete: payload,
        showMemoriesSnackbar: false,
        FSMemoryImportCheck: false
      };
    }
    case CLEAR_MEMORIES_SNACKBAR: {
      return {
        ...state,
        showMemoriesSnackbar: false
      };
    }
    case SHOW_HIDE_VIDEO_FOR_LDS_USER: {
      return {
        ...state,
        showVideoForLdsUser: payload
      };
    }
    default:
      return state;
  }
};

export default familySearch;

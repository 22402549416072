import { FEATURED_STORY_ID_REDIRECT, RESET_MEDIA } from "../constants";
import * as CONSTANTS from "../constants/actionTypes";

const initialState = {
  isLoading: true,
  isLoadingMedia: true,
  mediaDetails: {},
  error: false,
  userDetails: {},
  featuredStoryIdRedirect: "",
  mediaList: [],
  mediaListLoading: true,
  paginationLoading: false,
  mediaPostLoading: false,
  selectedIndex: 0,
  galleryType: "User",
  galleryId: "",
  mediaListItems: [],
};

const media = (state = initialState, { type = null, payload = null } = {}) => {
  const mediaDetail = state?.mediaDetails || {};
  const taggedPersons = mediaDetail?.taggedPersons || [];
  const personDetail = mediaDetail?.personDetail || [];
  const petDetail = mediaDetail?.petDetails || [];
  switch (type) {
    case CONSTANTS.MEDIALIST.REQUEST:
      return {
        ...state,
        mediaListLoading: true,
        mediaList: [],
      };
    case CONSTANTS.MEDIALIST.RESET:
      return {
        ...state,
        mediaList: [],
        mediaListLoading: true,
      };
    case CONSTANTS.MEDIALIST.SET:
      return {
        ...state,
        mediaList: [],
        mediaListLoading: false,
      };
    case CONSTANTS.MEDIALIST.SUCCESS:
      return {
        ...state,
        mediaListLoading: false,
        mediaList: payload,
      };
    case CONSTANTS.MEDIALIST.FAILURE:
      return {
        ...state,
        mediaListLoading: false,
      };
    case CONSTANTS.MEDIALISTPAGINATION.REQUEST:
    case CONSTANTS.MEDIALISTPAGINATION.FAILURE:
      return {
        ...state,
        paginationLoading: true,
      };
    case CONSTANTS.MEDIALISTPAGINATION.SUCCESS:
      return {
        ...state,
        paginationLoading: false,
        mediaList: [...state.mediaList, ...payload],
      };
    case CONSTANTS.MEDIA.REQUEST:
      return {
        ...state,
        isLoadingMedia: true,
        error: false,
        mediaDetails: {},
      };
    case CONSTANTS.GETUSERDETAILS.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case CONSTANTS.MEDIA.SUCCESS:
      return {
        ...state,
        isLoadingMedia: false,
        mediaDetails: payload,
        error: false,
      };
    case CONSTANTS.GETUSERDETAILS.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case CONSTANTS.MEDIA.FAILURE:
      return {
        ...state,
        isLoadingMedia: false,
        error: true,
      };
    case CONSTANTS.GETUSERDETAILS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: payload,
        error: false,
      };
    case RESET_MEDIA:
      return {
        ...state,
        mediaDetails: payload,
      };
    case FEATURED_STORY_ID_REDIRECT:
      return {
        ...state,
        featuredStoryIdRedirect: payload,
      };
    case CONSTANTS.ADDPERSONTOMEDIA.SUCCESS:
      return {
        ...state,
        mediaDetails: {
          ...mediaDetail,
          taggedPersons: [...taggedPersons, payload?.id],
          personDetail: [...personDetail, ...payload],
        },
      };
    case CONSTANTS.ADDPETFORMEDIA.SUCCESS:
      return {
        ...state,
        mediaDetails: {
          ...mediaDetail,
          petDetails: [...petDetail, ...payload],
        },
      };
    case CONSTANTS.REMOVEPERSONFROMMEDIA.SUCCESS: {
      return {
        ...state,
        mediaDetails: {
          ...mediaDetail,
          personDetail: !payload.isPet
            ? mediaDetail?.personDetail?.filter((item) => item.id !== payload.personId)
            : mediaDetail?.personDetail,
          petDetails: payload.isPet
            ? mediaDetail?.petDetails?.filter((item) => item.id !== payload.personId)
            : mediaDetail?.petDetails,
        },
      };
    }
    case CONSTANTS.POSTMEDIA.REQUEST:
      return {
        ...state,
        mediaPostLoading: true,
        error: false,
      };
    case CONSTANTS.POSTMEDIA.SUCCESS:
      return {
        ...state,
        mediaPostLoading: false,
        error: false,
      };
    case CONSTANTS.POSTMEDIA.FAILURE:
      return {
        ...state,
        mediaPostLoading: false,
        error: true,
      };
    case CONSTANTS.SET_CURRENT_INDEX:
      return {
        ...state,
        selectedIndex: payload,
      };
    case CONSTANTS.SET_GALLERY_TYPE:
      return {
        ...state,
        galleryType: payload,
      };
    case CONSTANTS.SET_GALLERY_ID:
      return {
        ...state,
        galleryId: payload,
      };
    case CONSTANTS.SET_MEDIA_ITEMS:
      return {
        ...state,
        mediaListItems: payload,
      };
    default:
      return state;
  }
};

export default media;

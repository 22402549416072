import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccessToken,
  getLoggedInViaShareableID,
  removeLoggedInViaShareableID,
  setShareableStoryID,
} from "services";
import useAuthUtils from "hooks/useAuthUtils";

const useStoryShareableIndex = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { storyId, shareableID } = useParams();
  const [step, setStep] = useState(1);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showStory, setShowStory] = useState(false);
  const isAccessTokenTerms = getAccessToken();
  const { shareableStory } = useSelector((state) => state.story);
  const { personalInfo  } = useSelector((state) => state.person);
  const loggedInViaShareableStoryPreviewID = getLoggedInViaShareableID();
  const { handleStoryLogin, handleStorySignup } = useAuthUtils();
  const redirectUri = "/story-share-auth";
  const personProfileUrl = `/person/profile/${personalInfo?.userId}`;

  const setStepFunction = (data) => {
    if (data) {
      setStep(data);
    } else {
      setStep(0);
    }
  };
  useEffect(() => {
    removeLoggedInViaShareableID();
  }, []);
  const handleLoginFunc = () => {
    setShareableStoryID(storyId);
    handleStoryLogin(redirectUri);
  };

  const handleSignupFunc = () => {
    setShareableStoryID(storyId);
    handleStorySignup(redirectUri);
  };

  const handleProfilePageLoginFunc = () => {
    setShareableStoryID(storyId);
    handleStoryLogin(personProfileUrl);
  };

  const handleProfilePageSignupFunc = () => {
    setShareableStoryID(storyId);
    handleStorySignup(personProfileUrl);
  };

  return {
    history,
    dispatch,
    storyId,
    step,
    setStep,
    showAccountModal,
    setShowAccountModal,
    showStory,
    setShowStory,
    isAccessTokenTerms,
    setStepFunction,
    handleLoginFunc,
    handleSignupFunc,
    shareableID,
    shareableStory,
    loggedInViaShareableStoryPreviewID,
    redirectUri,
    handleProfilePageLoginFunc,
    handleProfilePageSignupFunc
  };
};

export default useStoryShareableIndex;

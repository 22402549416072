
import * as CONSTANTS from "../constants/actionTypes";
const initialState = {
    list: []
};
  
const storyDelete = (state = initialState, { type = null, payload = null } = {}) => {
    let initialState={...state}
    switch (type) {
        case CONSTANTS.DELETESTORYID.REQUEST:
            return initialState;
            
        case CONSTANTS.DELETESTORYID.SUCCESS:
            let list = state.list
            return {
                ...state,
                list: [ payload, ...list ],
            };
            case CONSTANTS.DELETESTORYID.FAILURE:
                return {
                    ...state,
                    list: [ ],
                };
        default:
            return state;
    }
}
export default storyDelete;
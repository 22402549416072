import Loader from "components/Loader";
import { useGlobalLoaderStore } from "globalLoaderStore";

export default function GlobalLoader() {
  const globalLoader = useGlobalLoaderStore((state) => state.globalLoader);
  if (!globalLoader) return null;

  return (
    <div
      style={{
        zIndex: 9999999,
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "white"
      }}
    >
      <Loader />
    </div>
  );
}

import Button from "components/Button";
import Translator from "components/Translator";
import Typography from "components/Typography";
import useAuthUtils from "hooks/useAuthUtils";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { getAccessToken, storyAssociationCookie } from "services";
import PermissionVector from "./../../assets/images/noPermissionVector.svg";
const PermissionCard = ({ openWaitList, storyId: propStoryId, storySharePreview }) => {
  const history = useHistory();
  const { handleSignUpAuth, handleStorySignup } = useAuthUtils();
  const accessToken = getAccessToken();
  const { storyId } = useParams();

  const handleAction = () => {
    if (accessToken || !storySharePreview) {
      history.push("/");
    } else {
      if (storyId || propStoryId) {
        storyAssociationCookie("add");
        handleStorySignup(`/stories/view/0/${storyId || propStoryId}`);
      } else {
        handleSignUpAuth();
      }
    }
  };
  return (
    <div className="no-permission-block bg-gray-1 pt-30 h-full">
      <div className="flex justify-center flex-col items-center">
        <div className="vector mb-6">
          <img src={PermissionVector} alt="" />
        </div>
        <div className="info mb-4 text-center px-2">
          <Typography size={14} text="secondary" weight="medium">
            {accessToken || !storySharePreview ? (
              <Translator
                tkey={openWaitList ? "alreadyViewedStory" : "private.story.permisssionINVITE"}
              />
            ) : (
              <Translator tkey="private.story.permisssionLOHP" />
            )}
          </Typography>
        </div>
        <div className="bottom">
          <Button
            handleClick={() => handleAction()}
            tkey={accessToken || !storySharePreview ? "goToHomepage" : "Create account"}
            fontWeight="medium"
          />
        </div>
      </div>
    </div>
  );
};
export default PermissionCard;

import { combineReducers } from "redux";
import browseLocation from "./browseLocation";
import clues from "./clues";
import compareRecord from "./compareRecord";
import family from "./family";
import familySearch from "./familySearch";
import featuredPrompts from "./featuredPrompts";
import feeds from "./feeds";
import follow from "./follow";
import gedcom from "./gedcom";
import globalCache from "./globalCache";
import group from "./group";
import homepage from "./homepage";
import layout from "./layout";
import leftSideNav from "./leftSideNav";
import location from "./location";
import media from "./media";
import notification from "./notification";
import payment from "./payment";
import paymenttax from "./paymenttax";
import person from "./person";
import personRecord from "./personRecord";
import Pet from "./pet";
import printBookOrder from "./printBook/order";
import printBookStories from "./printBook/printBookStories";
import publication from "./publication";
import relationship from "./relationship";
import Reportstory from "./reportstory";
import sidebarLeftovers from "./sidebarLeftovers";
import story from "./story";
import comments from "./story/comments";
import storyAssist from "./storyAssist";
import storyDelete from "./storyDelete";
import toastr from "./toastr";
import topic from "./topic";
import user from "./user";

export default combineReducers({
  globalCache,
  paymenttax,
  layout,
  relationship,
  homepage,
  user,
  family,
  person,
  story,
  media,
  toastr,
  payment,
  personRecord,
  location,
  publication,
  notification,
  follow,
  topic,
  browseLocation,
  comments,
  Reportstory,
  group,
  feeds,
  gedcom,
  leftSideNav,
  storyDelete,
  featuredPrompts,
  Pet,
  clues,
  compareRecord,
  storyAssist,
  familySearch,
  // print book
  printBookStories,
  printBookOrder,
  sidebarLeftovers
});

export const StoriesRoutesv1 = [
  {
    path: "/stories/v1/add",
    redirectPath: "/stories/add"
  },
  {
    path: "/stories/v1/add/:refType/pet/:petId",
    redirectPath: "/stories/add/:refType/pet/:petId"
  },
  {
    path: "/stories/v1/add/:refType/group/:groupName",
    redirectPath: "/stories/add/:refType/group/:groupName"
  },
  {
    path: "/stories/v1/add/:refType/:treeId/:primaryPersonId",
    redirectPath: "/stories/add/:refType/:treeId/:primaryPersonId"
  },
  {
    path: "/stories/v1/edit/:refType/:storyId/group/:groupName",
    redirectPath: "/stories/edit/:refType/:storyId/group/:groupName"
  },
  {
    path: "/stories/v1/edit/:refType/:storyId/:treeId/:primaryPersonId",
    redirectPath: "/stories/edit/:refType/:storyId/:treeId/:primaryPersonId"
  },
  {
    path: "/stories/v1/add/newspaper/:recordId",
    redirectPath: "/stories/add/newspaper/:recordId"
  },
  {
    path: "/stories/v1/add/:refType",
    redirectPath: "/stories/add/:refType"
  },
  {
    path: "/stories/v1/edit/:refType/:storyId",
    redirectPath: "/stories/edit/:refType/:storyId"
  },
  {
    path: "/stories/v1/add-from-media/:refType/:mediaId",
    redirectPath: "/stories/add-from-media/:refType/:mediaId"
  },
  {
    path: "/stories/v1/add-from-external-media/:refType/:mediaId/:recordId",
    redirectPath: "/stories/add-from-external-media/:refType/:mediaId/:recordId"
  },
  {
    path: "/stories/v1/view/:refType/:storyId",
    redirectPath: "/stories/view/:refType/:storyId"
  },
  {
    path: "/stories/v1/view/:refType/:storyId/:treeId/:primaryPersonId",
    redirectPath: "/stories/view/:refType/:storyId/:treeId/:primaryPersonId"
  },
  {
    path: "/stories/v1/view/:refType/:storyId/group/:groupName",
    redirectPath: "/stories/view/:refType/:storyId/group/:groupName"
  }
];

import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  palette: {
    success: {
      main: "#388367"
    },
    info: {
      main: "#212122"
    },
    warning: {
      main: "#B02A4C"
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: (theme) => ({
          backgroundColor: "black",
          padding: theme.theme.spacing(1)
        })
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px"
        }
      }
    },
    MuiSlider: {
      defaultProps: {
        size: "small"
      },
      styleOverrides: {
        thumb: {
          color: "#3f51b5"
        },
        root: {
          color: "#3f51b5",
          height: "1.5px"
        }
      }
    }
  }
});

export default muiTheme;

import { useTranslation } from "react-i18next";
//Services
import useWindowDimensions from "hooks/useWindowDimensions";
//Images
import Logo from "assets/images/logo_transparent.svg";
import SiteScaling from "assets/images/sitescaling.svg";
//Components
import Typography from "components/Typography";
import { tr } from "components/utils";

const SiteScale = () => {
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const className = height < 800 && width >= 568 ? "py-10" : "h-screen";

  return (
    <div className={`bg-gray-2 ${className} flex justify-center items-center`}>
      <div className="text-center mx-10 smm:mx-12 md:mx-0">
        <div className="flex justify-center">
          <img
            className="h-8"
            src={Logo}
            alt="storied"
          />
        </div>
        <div className="mt-12 mb-6">
          <img
            src={SiteScaling}
            alt="siteScale"
          />
        </div>
        <Typography
          text="text-blue-4"
          weight="bold"
        >
          <span className="text-8 smm:text-5xl">{tr(t, "siteScale.title")} </span>
        </Typography>
        <div className="max-w-xl mt-6">
          <Typography size={18}>{tr(t, "siteScale.subTitle")}</Typography>
        </div>
      </div>
    </div>
  );
};

export default SiteScale;

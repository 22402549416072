/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Project2Data,
  Project2Params,
  ProjectAddStoriesData,
  ProjectAddStoriesPayload,
  ProjectData,
  ProjectPayload,
  ProjectRemoveStoriesData,
  ProjectRemoveStoriesPayload,
  ProjectTemplatesData,
  ProjectUpdateData,
  ProjectUpdatePayload,
  V2ProjectRecentlyViewedData
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Project extends HttpClient {
  /**
   * No description
   *
   * @tags Project
   * @name Project
   * @request POST:/api/Project
   * @secure
   */
  project = (data: ProjectPayload, params: RequestParams = {}) =>
    this.request<ProjectData, any>({
      path: `/api/Project`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name Project2
   * @request GET:/api/Project
   * @originalName project
   * @duplicate
   * @secure
   */
  project2 = (query: Project2Params, params: RequestParams = {}) =>
    this.request<Project2Data, any>({
      path: `/api/Project`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectUpdate
   * @request POST:/api/Project/update
   * @secure
   */
  projectUpdate = (data: ProjectUpdatePayload, params: RequestParams = {}) =>
    this.request<ProjectUpdateData, any>({
      path: `/api/Project/update`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectAddStories
   * @request POST:/api/Project/add-stories
   * @secure
   */
  projectAddStories = (data: ProjectAddStoriesPayload, params: RequestParams = {}) =>
    this.request<ProjectAddStoriesData, any>({
      path: `/api/Project/add-stories`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectRemoveStories
   * @request POST:/api/Project/remove-stories
   * @secure
   */
  projectRemoveStories = (data: ProjectRemoveStoriesPayload, params: RequestParams = {}) =>
    this.request<ProjectRemoveStoriesData, any>({
      path: `/api/Project/remove-stories`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectTemplates
   * @request GET:/api/Project/templates
   * @secure
   */
  projectTemplates = (params: RequestParams = {}) =>
    this.request<ProjectTemplatesData, any>({
      path: `/api/Project/templates`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name V2ProjectRecentlyViewed
   * @request GET:/api/v2/Project/recently-viewed
   * @secure
   */
  v2ProjectRecentlyViewed = (params: RequestParams = {}) =>
    this.request<V2ProjectRecentlyViewedData, any>({
      path: `/api/v2/Project/recently-viewed`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

export const LAYOUT_ID = {
    FIT: "8b3584fb-7547-4240-a826-ce176a91ac2a",
    FILL: "9b6c4867-c77f-4817-991f-d64e175b2471",
    SQUARE: "a8ddfd67-8e0b-45fc-84b2-2c72fab1d66a",
    BORDER: "91875ad8-9ffe-4f2f-8ee2-cd42ea96778c",
    TWO_IMAGE: "5f529565-6817-4fa6-b631-2d9623b86f7f",
    DEFAULT: "48b601e9-9ce2-4055-9ba7-1370643cea92",
  };

  export const getWidgetOption = ({ width, height }) => {
    let aspectRatio = 5 / 7,
      result = {};
    let imageRatio = width / height === aspectRatio;
    if (!(height >= 700 && width >= 500 && imageRatio)) {
      result[LAYOUT_ID.FIT] = "fit-lt-btn widget-btn";
    }
    if (height >= 700 && width >= 500) {
      result[LAYOUT_ID.FILL] = "fill-lt-btn widget-btn";
    }
    if (height >= 400 && width >= 400) {
      result[LAYOUT_ID.SQUARE] = "square-lt-btn widget-btn";
    }
    if (height >= 600 && width >= 400) {
      result[LAYOUT_ID.BORDER] = "border-lt-btn widget-btn";
    }
    result[LAYOUT_ID.TWO_IMAGE] = "multi-lt-btn widget-btn";
    return result;
  };

  const getRound = (mediaSize, width, height) => {
    let round1 = 0, round2 = 0;
    
    round1 = Math.round(mediaSize.width / width * 100) / 100
    round2 = Math.round(mediaSize.height / height * 100) / 100
    return Math.min(round1, round2)
}

const layoutCropedArea = {
    [LAYOUT_ID.FIT]: {
      width: 300,
      height: 420,
      mobile: {
        width: "w-full",
        height: 525,
      },
    },
    [LAYOUT_ID.FILL]: {
      width: 300,
      height: 420,
      mobile: {
        width: "w-full",
        height: 525,
      },
    },
    [LAYOUT_ID.SQUARE]: {
      width: 368,
      height: 368,
      mobile: {
        width: 300,
        height: 300,
      },
    },
    [LAYOUT_ID.BORDER]: {
      width: 368,
      height: 368,
      mobile: {
        width: 300,
        height: 300,
      },
    },
    [LAYOUT_ID.TWO_IMAGE]: {
      width: 400,
      height: 287,
      mobile: {
        width: 300,
        height: 215,
      },
    },
  };
export const getLayoutAspect = (layout) => {
    return layoutCropedArea[layout];
  };
const isCropFn = (mediaSize, layoutFit) => {
    return layoutFit && mediaSize.width > layoutFit.width && mediaSize.height > layoutFit.height
}
 export  const onMediaLoaded = (mediaSize, layout, layoutFit=null) => {
    let round = 0
    let isCrop = false;
    if(!layoutFit){
        layoutFit=  getLayoutAspect(layout);
    }
    if ([LAYOUT_ID.FILL].includes(layout)) {
        round = getRound(mediaSize, 750, 1050)
        isCrop = isCropFn(mediaSize, layoutFit)
    } else if ([LAYOUT_ID.SQUARE].includes(layout)) {
        round = getRound(mediaSize, 600, 600)
        isCrop = isCropFn(mediaSize, layoutFit)
    } else if ([LAYOUT_ID.TWO_IMAGE].includes(layout)) {
        round = getRound(mediaSize, 600, 430)
        isCrop = isCropFn(mediaSize, layoutFit)
    }
    return { maxZoom: round, isCrop: isCrop }
}

import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";
import Translator from "components/Translator";
import { copyright } from "shared-logics";
const FooterRoutes = [""];

export const FooterLinks = ({ pathName, onNavigation = undefined }) => (
  <>
    <Link to={"/about"} onClick={onNavigation} className="nav-link text-xs hover:underline focus:outline-none">
      <Translator tkey="loggedoutHomepage.nav.about"/>
    </Link>
    <Link to={"/resources"} onClick={onNavigation} className="nav-link text-xs hover:underline focus:outline-none">
      <Translator tkey="loggedoutHomepage.nav.resources"/>
    </Link>
    <Link to={"/privacy"} onClick={onNavigation} className="nav-link text-xs hover:underline focus:outline-none">
      <Translator tkey="loggedoutHomepage.nav.privacy"/>
    </Link>
    <Link to={"/terms"} onClick={onNavigation} className="nav-link text-xs hover:underline focus:outline-none">
      <Translator tkey="loggedoutHomepage.nav.terms"/>
    </Link>
  </>
);

const Footer = () => {
  const { footer } = useSelector((state) => state.layout);
  const [showFooter, setShowFooter] = useState(true);
  const location = useLocation();
  let pathArr = location.pathname.split("/");
  pathArr.shift();
  const footerContainer = FooterRoutes.includes(pathArr[0].toLowerCase()) ? "footer-right" : "footer-center";
  useEffect(() => {
    let condArr = ["ut", "ssdi", "newspaper", "census"];
    if (condArr.includes(pathArr[0].toLowerCase())) setShowFooter(false);
  }, [location]);
  return showFooter ? (
    <div id="footer" className={`py-4 footer ${footer.bgClass}`}>
      <div className={`${footerContainer}`}>
        <div className="items-center md:space-x-2 space-x-4 footer-links">
          <FooterLinks pathName={location.pathname} />
        </div>
        <div className="mt-1.5 mb-2 text-xs copy">{copyright()}</div>
      </div>
    </div>
  ) : null;
};

export default Footer;

import API from 'API/__generated__';
import { useMemo, useState } from 'react';
import { REACT_APP_AZURE_FEATURE_FLAG } from 'utils/env';

import { AppConfigurationClient } from '@azure/app-configuration';
import { useQuery } from '@tanstack/react-query';

const client = new AppConfigurationClient(REACT_APP_AZURE_FEATURE_FLAG);

/**
 * Returns the status of the specified feature flag.
 * @param flag The flag name.
 */
export function useFeatureFlags(flag: string) {
  const data = useQuery({
    queryKey: ["featureFlags"] as const,
    queryFn: async () => {
      const results = await API.FeatureFlag.featureFlagListFeatures();
      const caseInsensitiveResults = Object.fromEntries(
        Object.entries(results).flatMap(([key, value]) => [
          [key, value],
          [key.toLowerCase(), value]
        ])
      );

      return caseInsensitiveResults;
    },
    staleTime: 5 * 1000 * 60
  });

  const trimmedFlag = flag.trim();
  return {
    enabled: data.data?.[trimmedFlag] ?? data.data?.[trimmedFlag.toLowerCase()] ?? false,
    loading: data.isLoading
  };
}

/**
 * Retrieves the specified configuration from Azure App Configuration.
 * @param {string} configKey App Config Key
 * @returns the configuration for the specified key
 */
const useConfiguration = (configKey = "") => {
  const [config, setConfig] = useState("");

  useMemo(async () => {
    if (!configKey || !configKey.toString().trim().length) {
      console.error("A config key must be supplied.");
    } else {
      try {
        const result = await client.getConfigurationSetting({ key: configKey.toString().trim() });
        if (result) {
          setConfig(result.value as any);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [configKey]);

  return { config };
};

import { getGoogleSessionId, getOwner, getRecentTree, setRecentTree } from "services";
import { BLANKID } from "utils";
import { REACT_APP_CLIENT_ID } from "utils/env";
import { v4 as uuidv4 } from "uuid";
import {
  CLEAR_REFETCH_TREE_FLAG,
  CLEAR_STORY_LIKES_PERSONS,
  GET,
  HOMESCROLLCORD,
  MILO_MODAL,
  POST,
  RESET_OWN_STORY_OFFSET,
  RETRY_FAILED_STORY,
  UPDATE_PROMPTS_FEATURE_STORY,
  UPDATE_USER_CACHE,
  UPDATE_USER_FOLLOW_CACHE
} from "../constants";
import { updateTreesOptimistically } from "../helpers/homePagePayloads";
import { apiRequest } from "../requests";
import { actionCreator, callApi } from "../utils";
import * as CONSTANTS from "./../constants/actionTypes";
import * as API_URLS from "./../constants/apiUrl";
import { addMessage } from "./toastr";
import { DeletedFilterStory } from "./utils/storyHelper";

const createHeader = () => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId()
  };
};

const getTotalStoriesCount = (dispatch, requestId) => {
  apiRequest(GET, `Topic/storiesCount/${requestId}`).then((res) => {
    const totalCount = res.data;
    dispatch(actionCreator(CONSTANTS.GETSTORIESCOUNT.SUCCESS, totalCount));
  });
};
const getStoriesApiSuccess = ({
  dataRespo,
  moreDataAvail,
  newRequest,
  getTotalStories,
  dispatch,
  requestData,
  stories,
  isPaginationLoading
}) => {
  if (!dataRespo.length && moreDataAvail?.current) {
    moreDataAvail.current = false;
  }
  if (newRequest) {
    dispatch(actionCreator(CONSTANTS.GETSTORIES.SUCCESS, dataRespo));
    if (getTotalStories) {
      getTotalStoriesCount(dispatch, requestData.requestId);
    }
  } else {
    if (requestData.pageNumber === 2 && stories.length === 0) {
      setTimeout(() => {
        dispatch(actionCreator(CONSTANTS.GETSTORIESPAGINATION.SUCCESS, dataRespo));
        isPaginationLoading.current = false;
      }, 3500);
    } else {
      dispatch(actionCreator(CONSTANTS.GETSTORIESPAGINATION.SUCCESS, dataRespo));
      isPaginationLoading.current = false;
    }
  }
};

const getStoriesAPICall = (
  getState,
  url,
  requestData,
  staticHeader,
  newRequest,
  dispatch,
  { isPaginationLoading, moreDataAvail, getTotalStories = false }
) => {
  isPaginationLoading.current = true;
  if (getState().homepage.stories.length > 0 && newRequest && !requestData.forceFully) {
    let deleteStoryList = getState().storyDelete.list;
    if (deleteStoryList.length) {
      let filterStory = getState().homepage.stories.filter((elem) =>
        DeletedFilterStory(elem, deleteStoryList)
      );
      dispatch(actionCreator(CONSTANTS.GETSTORIES.SUCCESS, filterStory));
    }
  } else {
    callApi("GET", url, requestData, false, staticHeader)
      .then((res) => {
        const { stories } = getState().homepage;
        const dataRespo = res.data;
        getStoriesApiSuccess({
          dataRespo,
          moreDataAvail,
          newRequest,
          getTotalStories,
          dispatch,
          requestData,
          stories,
          isPaginationLoading
        });
      })
      .catch((err) => {
        if (newRequest) {
          dispatch(actionCreator(CONSTANTS.GETSTORIES.FAILURE, err?.response?.data));
        } else {
          dispatch(actionCreator(CONSTANTS.GETSTORIESPAGINATION.FAILURE, err));
          isPaginationLoading.current = false;
        }
      });
  }
};
const getOwnStoriesAPICall = (
  getState,
  url,
  requestData,
  staticHeader,
  newRequest,
  dispatch,
  { isPaginationLoading, moreDataAvail, getTotalStories = false }
) => {
  isPaginationLoading.current = true;
  callApi("GET", url, requestData, false, staticHeader, false, null, { errorType: "AuthorStories" })
    .then((res) => {
      const { ownStories, ownStoryOffset } = getState().homepage;

      let dataRespo = res.data;
      if (!dataRespo.length && moreDataAvail?.current) {
        moreDataAvail.current = false;
      }
      let deleteStoryList = getState().storyDelete.list;
      if (deleteStoryList.length) {
        dataRespo = dataRespo.filter((elem) => DeletedFilterStory(elem, deleteStoryList));
      }
      if (newRequest) {
        if (ownStoryOffset > 0 && !requestData.forceFully) {
          dispatch(actionCreator(CONSTANTS.GETOWNSTORIESPAGINATION.SUCCESS, dataRespo));
        } else {
          dispatch(actionCreator(CONSTANTS.GETOWNSTORIES.SUCCESS, dataRespo));
        }
        if (getTotalStories) {
          getTotalStoriesCount(dispatch, requestData.requestId);
        }
        requestData.forceFully && dispatch(resetOwnStoryOffsetAction());
      } else {
        if (requestData.pageNumber === 2 && ownStories.length === 0) {
          setTimeout(() => {
            dispatch(actionCreator(CONSTANTS.GETOWNSTORIESPAGINATION.SUCCESS, dataRespo));
            isPaginationLoading.current = false;
          }, 3500);
        } else {
          dispatch(actionCreator(CONSTANTS.GETOWNSTORIESPAGINATION.SUCCESS, dataRespo));
          isPaginationLoading.current = false;
        }
      }
    })
    .catch((err) => {
      if (newRequest) {
        dispatch(actionCreator(CONSTANTS.GETOWNSTORIES.FAILURE, err?.response?.data));
      } else {
        dispatch(actionCreator(CONSTANTS.GETOWNSTORIESPAGINATION.FAILURE, err));
        isPaginationLoading.current = false;
      }
    });
};
const RequestLoader = (newRequest, dispatch) => {
  if (newRequest) {
    dispatch(actionCreator(CONSTANTS.GETSTORIES.REQUEST));
  } else {
    dispatch(actionCreator(CONSTANTS.GETSTORIESPAGINATION.REQUEST));
  }
};
export const getStories = (
  data,
  newRequest = true,
  isPaginationLoading = {},
  homepage = false,
  moreDataAvail = true
) => {
  return (dispatch, getState) => {
    const { ownStories, ownStoryOffset, stories, groupStoryOffset } = getState().homepage;
    const deleteStoryList = getState().storyDelete.list;
    const totalOffset = ownStoryOffset - deleteStoryList?.length;
    const totalGroupOffset = groupStoryOffset ? groupStoryOffset - deleteStoryList?.length : 0;
    const combineOffset = totalGroupOffset + totalOffset;
    let url = API_URLS.getOwnStories(data);
    let staticHeader = createHeader();
    if (homepage) {
      url = API_URLS.getHomepageStories(data);
    }
    if (data.treeId) {
      url = API_URLS.getStories(data);
    }
    if (data.categoryName) {
      if (data.categoryName === "Drafts") {
        url = url + `?storyStatus=Draft`;
      } else {
        url = url + `?categoryName=${data.categoryName}`;
      }
    }
    const queryExist = url.indexOf("?") === -1;
    if (queryExist) {
      url += `?offset=${combineOffset}`;
    }
    if (!(stories.length > 0 && newRequest) && homepage) {
      RequestLoader(newRequest, dispatch);
    }
    if (!(ownStories.length > 0 && newRequest) && !homepage) {
      RequestLoader(newRequest, dispatch);
    }
    if (homepage) {
      getStoriesAPICall(getState, url, data, staticHeader, newRequest, dispatch, {
        isPaginationLoading,
        moreDataAvail,
        getTotalStories: false
      });
    } else {
      if (
        !(
          ownStories.length > 0 &&
          newRequest &&
          !data.forceFully &&
          combineOffset !== ownStories.length
        )
      ) {
        getOwnStoriesAPICall(getState, url, data, staticHeader, newRequest, dispatch, {
          isPaginationLoading,
          moreDataAvail,
          getTotalStories: false
        });
      }
    }
  };
};
export const resetOwnStoryOffsetAction = () => {
  return (dispatch) => {
    dispatch({ type: RESET_OWN_STORY_OFFSET });
  };
};
export const resetOwnStoryAction = () => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.GETOWNSTORIES.REQUEST));
  };
};
export const resetGroupStoryAction = () => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.GETGROUPSTORIES.REQUEST));
  };
};

export const resetRefetchTreePostDeletetionFlag = () => (dispatch) => {
  dispatch({ type: CLEAR_REFETCH_TREE_FLAG });
};

const getViewStoryUrl = (data, isLoggedIn, type) => {
  let url;
  if (isLoggedIn && data.feed) {
    url = API_URLS.viewStoryforFeed(data);
  } else if (isLoggedIn && data.collection) {
    url = API_URLS.viewStoryCollection(data);
  } else {
    url = API_URLS.viewNonLoggedInStory(data);
  }
  if (type === "GETFEEDSTORYANDUPDATELIST") {
    url = API_URLS.viewStoryforGroupFeed(data);
  }
  return url;
};

const updateStorySuccess = ({ data, type, res, dispatch }) => {
  const typeMap = {
    GETOWNSTORYANDUPDATELIST: "ownStories",
    GETGROUPSTORYANDUPDATELIST: "groupStories",
    GETHOMESTORYANDUPDATELIST: "stories"
  };
  if (data.retry) {
    dispatch({
      type: RETRY_FAILED_STORY,
      payload: { ...res.data, updateKey: typeMap[type] || "stories" }
    });
  } else {
    dispatch(actionCreator(CONSTANTS[type].SUCCESS, res.data));
  }
  data.setRetryLoading && data.setRetryLoading(false);
};

export const getHomeStoryAndUpdateList = (
  data,
  type = "GETHOMESTORYANDUPDATELIST",
  isLoggedIn = true
) => {
  return (dispatch, getState) => {
    const globalCache = getState().globalCache;
    let url = getViewStoryUrl(data, isLoggedIn, type);
    let staticHeader = createHeader();
    return callApi("GET", url, data, false, staticHeader, false, null, false)
      .then(async (res) => {
        if (res.status !== 500) {
          if (res.data.layoutId && res.data.layoutId !== BLANKID && res.data.storyImages?.length) {
            res.data.layoutId = BLANKID;
            res.data.storyImages = res.data.storyImages.map((image) => ({
              ...image,
              url: image.originatingMediaURL
            }));
          }
          if (data.home) {
            const reportUrl = API_URLS.countcomments(res?.data?.storyId);
            let commentsapi = null;
            const userUrl =
                !isLoggedIn && data.invitationId
                  ? API_URLS.getMemberInfoLOHP({
                      memberId: res?.data?.authorId,
                      invitationId: data.invitationId
                    })
                  : API_URLS.getMemberInfo(res?.data?.authorId),
              folloInfoUrl = `${API_URLS.getFollowUnfollowDetail}/${res?.data?.authorId}`;
            let followbool, authorData;
            try {
              if (globalCache.userInfo?.[res?.data?.authorId]) {
                authorData = globalCache.userInfo?.[res?.data?.authorId];
              } else {
                const authRes = await callApi(
                  "GET",
                  userUrl,
                  {},
                  false,
                  staticHeader,
                  false,
                  null,
                  false
                );
                dispatch({
                  type: UPDATE_USER_CACHE,
                  payload: authRes?.data
                });
                authorData = authRes?.data;
              }
            } catch {
              authorData = {};
            }
            if (isLoggedIn) {
              try {
                commentsapi = await callApi(
                  "GET",
                  reportUrl,
                  {},
                  false,
                  staticHeader,
                  false,
                  null,
                  true
                );
              } catch {
                commentsapi = { data: 0 };
              }

              try {
                const authorFollowInfo = globalCache.followInfo?.[res?.data?.authorId];
                if (authorFollowInfo || authorFollowInfo === false) {
                  followbool = authorFollowInfo;
                } else {
                  const followBoolRes = await callApi(
                    "GET",
                    folloInfoUrl,
                    {},
                    false,
                    staticHeader,
                    false,
                    null,
                    false
                  );
                  dispatch({
                    type: UPDATE_USER_FOLLOW_CACHE,
                    payload: { id: res?.data?.authorId, value: followBoolRes?.data }
                  });
                  followbool = followBoolRes?.data;
                }
              } catch {
                followbool = false;
              }
            }
            res.data.author = authorData;
            res.data.isFollow = followbool;
            res.data.isReportCount = commentsapi?.data;
            updateStorySuccess({ data, type, res, dispatch });
          } else {
            updateStorySuccess({ data, type, res, dispatch });
          }
        } else {
          dispatch(
            actionCreator(CONSTANTS[type].SUCCESS, {
              apiError: true,
              storyId: data.storyId,
              retryLoading: false
            })
          );
          data.setRetryLoading && data.setRetryLoading(false);
        }
      })
      .catch((err) => {
        dispatch(
          actionCreator(CONSTANTS[type].SUCCESS, {
            apiError: true,
            storyId: data.storyId,
            retryLoading: false
          })
        );
        data.setRetryLoading && data.setRetryLoading(false);
      });
  };
};
const getStoriesCountAPICall = (getState, url, requestData, staticHeader, dispatch) => {
  callApi("GET", url, requestData, false, staticHeader)
    .then((res) => {
      dispatch(
        actionCreator(CONSTANTS.GETSTORIESCOUNT.SUCCESS, res.data?.resultData?.AllStoryCount || 0)
      );
    })
    .catch((_err) => {
      dispatch(actionCreator(CONSTANTS.GETSTORIESCOUNT.FAILURE, {}));
    });
};

export const getStoriesCount = () => {
  let requestData = { authorId: getOwner() };
  return (dispatch, getState) => {
    let url = API_URLS.getStoriesCount(requestData),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.GETSTORIESCOUNT.REQUEST));
    getStoriesCountAPICall(getState, url, requestData, staticHeader, dispatch);
  };
};

export const getTreesListAsync = () => {
  let requestData = { authorId: getOwner() };
  let url = API_URLS.getTreesASYNC(requestData),
    staticHeader = createHeader();
  return apiRequest("GET", url, requestData, false, staticHeader)
    .then((res) => {
      return res.data || [];
    })
    .catch((_err) => {
      return [];
    });
};

const showTreeSettingMessage = (treeValCondition, treePrivacyCondition) => {
  switch (true) {
    case treeValCondition && treePrivacyCondition:
      return "updateTreeNameSetting.success";
    case treeValCondition:
      return "updateTreeName.success";
    case treePrivacyCondition:
      return "updateTreePrivacySetting.success";
    default: //do nothing
  }
};

export const updateTreeName =
  (
    trees,
    treeId,
    newTreeName,
    oldTreeName,
    treeValCondition,
    treePrivacyCondition,
    treePrivacySetting
  ) =>
  async (dispatch) => {
    try {
      const payload = updateTreesOptimistically(trees, treeId, newTreeName);
      const treeNamePayload = {
        treeId: treeId,
        treeName: newTreeName,
        IsPrivate: treePrivacySetting.privateTree,
        IsSharedWithPartners: treePrivacySetting.isHint
      };
      dispatch({
        type: CONSTANTS.UPDATE_TREENAME_SUCCESS,
        payload: payload
      });
      await apiRequest("PUT", `Trees/treesettings`, treeNamePayload);
      let message = showTreeSettingMessage(treeValCondition, treePrivacyCondition);
      dispatch(addMessage(message, "success"));
    } catch (err) {
      const oldpayload = updateTreesOptimistically(trees, treeId, oldTreeName);
      dispatch({
        type: CONSTANTS.UPDATE_TREENAME_SUCCESS,
        payload: oldpayload
      });
      dispatch(addMessage("updateTreeName.failure", "error"));
      dispatch({
        type: CONSTANTS.UPDATE_TREENAME_FAILURE,
        payload: { msg: err }
      });
    }
  };

export const getTreeSettings = (treeId) => {
  return apiRequest("GET", `Trees/${treeId}/treesettings`)
    .then((res) => {
      return res.data || [];
    })
    .catch((_err) => {
      return [];
    });
};

export const getTreesList = (treesCount, type = "NORMAL") => {
  if (type === "GROUP") {
    let requestData = { authorId: getOwner() };
    return (dispatch, getState) => {
      let url = API_URLS.getTreesList,
        staticHeader = createHeader();
      dispatch(actionCreator(CONSTANTS.GETTREESLISTFORGROUP.REQUEST));
      callApi("GET", url, requestData, false, staticHeader)
        .then((res) => {
          dispatch(actionCreator(CONSTANTS.GETTREESLISTFORGROUP.SUCCESS, res.data || []));
        })
        .catch((_err) => {
          dispatch(actionCreator(CONSTANTS.GETTREESLISTFORGROUP.FAILURE, {}));
        });
    };
  } else {
    let requestData = { authorId: getOwner() };
    return (dispatch, getState) => {
      let url = API_URLS.getTreesList,
        staticHeader = createHeader();
      dispatch(actionCreator(CONSTANTS.GETTREESLIST.REQUEST));
      callApi("GET", url, requestData, false, staticHeader)
        .then((res) => {
          const latestTree = getRecentTree();
          if (latestTree === null && res.data && res.data.length > 0) {
            let recentTree = "";
            recentTree = {
              treeId: res.data[0].treeId,
              primaryPersonId: res.data[0].homePersonId,
              level: 4
            };
            setRecentTree(recentTree);
          }
          dispatch(actionCreator(CONSTANTS.GETTREESLIST.SUCCESS, res.data || []));
        })
        .catch((_err) => {
          dispatch(actionCreator(CONSTANTS.GETTREESLIST.FAILURE, {}));
        });
    };
  }
};
export const getNewsPaperFreeViewsCount = (setViewCount) => {
  return (dispatch, getState) => {
    let url = API_URLS.getNewsPaperFreeViewsCount(),
      staticHeader = createHeader();
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        localStorage.setItem("allocatedViews", parseInt(res?.data?.allocatedViews || 0));
        localStorage.setItem("viewCount", parseInt(res?.data?.freeViewsConsumed || 0));
        localStorage.setItem("remainingFreeViews", parseInt(res?.data?.remainingFreeViews || 0));
        setViewCount && setViewCount(parseInt(res?.data?.freeViewsConsumed || 0));
        dispatch(actionCreator(CONSTANTS.GETNEWSPAPERFREEVIEWSCOUNT.SUCCESS, res.data || []));
      })
      .catch((_err) => {
        dispatch(actionCreator(CONSTANTS.GETNEWSPAPERFREEVIEWSCOUNT.FAILURE, {}));
      });
  };
};
export const updateNewsPaperViewPageUrl = (pageURL, setOpenUpgradeModal, setViewCount) => {
  return (dispatch, getState) => {
    let url = API_URLS.registerUpdateNewsPaperView(pageURL),
      staticHeader = createHeader();
    let viewCount = parseInt(localStorage.getItem("viewCount"));
    let allocatedViews = parseInt(localStorage.getItem("allocatedViews"));
    if (viewCount < allocatedViews) {
      callApi("PUT", url, {}, false, staticHeader)
        .then((res) => {
          dispatch(getNewsPaperFreeViewsCount(setViewCount));
          dispatch(actionCreator(CONSTANTS.UPDATENEWSPAPERVIEWSPAGEURL.SUCCESS, res.data || []));
        })
        .catch((_err) => {
          dispatch(actionCreator(CONSTANTS.UPDATENEWSPAPERVIEWSPAGEURL.FAILURE, {}));
        });
    } else {
      setOpenUpgradeModal(true);
    }
  };
};
export const addRecentViewTree = async (treeId) => {
  await apiRequest(POST, `User/addrecentlyviewedtree/${treeId}`, {});
};

export const getRecentPeopleList = () => {
  let requestData = { authorId: getOwner() };
  return (dispatch, getState) => {
    let url = API_URLS.getRecentPeople(requestData),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.RECENTPEOPLE.REQUEST));
    callApi("GET", url, requestData, false, staticHeader, false, null, false)
      .then((res) => {
        const data = Array.isArray(res.data) ? res.data : [];
        dispatch(actionCreator(CONSTANTS.RECENTPEOPLE.SUCCESS, data));
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.RECENTPEOPLE.FAILURE));
      });
  };
};

export const updateStoryIsLiked = (
  storyId,
  isLiked,
  storyIndex,
  likeType = "UPDATESTORYISLIKED"
) => {
  const requestData = { storyId: storyId, isLiked: isLiked, storyIndex: storyIndex };
  return (dispatch, getState) => {
    let url = API_URLS.updateStoryIsLiked(requestData),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS[likeType].REQUEST));
    callApi("POST", url, {}, false, staticHeader)
      .then((_res) => {
        dispatch(actionCreator(CONSTANTS[likeType].SUCCESS, requestData));
      })
      .catch((err) => {
        dispatch(addMessage("Sorry, your story couldn't be updated. Please try again.", "error"));
        return err?.response?.data;
      });
  };
};

export const storylikespersons = (storyId, pageNumber, pageSize, _isLikesPageLoading = {}) => {
  const requestData = { storyId, pageNumber, pageSize };
  return (dispatch, getState) => {
    let url = API_URLS.storylikespersons(requestData),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.STORYLIKESPERSONS.REQUEST, pageNumber));
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(
          actionCreator(CONSTANTS.STORYLIKESPERSONS.SUCCESS, { data: res.data, pageNumber } || {})
        );
      })
      .catch((err) => {
        dispatch(addMessage("Sorry, your story couldn't be updated. Please try again.", "error"));
        return err?.response?.data;
      });
  };
};
export const getPersonAsync = (primaryPersonId, selSelectedPerson) => {
  apiRequest(GET, `persons/${primaryPersonId}/info`).then((res) => {
    selSelectedPerson(res.data);
  });
};
const getGroupStoriesAPICall = (
  getState,
  url,
  requestData,
  staticHeader,
  newRequest,
  dispatch,
  { isPaginationLoading, moreDataAvail, getTotalStories = false }
) => {
  isPaginationLoading.current = true;
  callApi("GET", url, requestData, false, staticHeader, false, null, { errorType: "AuthorStories" })
    .then((res) => {
      const { groupStories, groupStoryOffset } = getState().homepage;

      let groupSotryData = res.data;
      if (!groupSotryData?.length && moreDataAvail?.current) {
        moreDataAvail.current = false;
      }
      let deleteStoryList = getState().storyDelete.list;
      if (deleteStoryList?.length) {
        groupSotryData = groupSotryData.filter((elem) => DeletedFilterStory(elem, deleteStoryList));
      }
      if (newRequest) {
        if (groupStoryOffset > 0 && !requestData.forceFully) {
          dispatch(actionCreator(CONSTANTS.GETGROUPSTORIESPAGINATION.SUCCESS, groupSotryData));
        } else {
          dispatch(actionCreator(CONSTANTS.GETGROUPSTORIES.SUCCESS, groupSotryData));
        }
        if (getTotalStories) {
          getTotalStoriesCount(dispatch, requestData.requestId);
        }
      } else {
        if (requestData.pageNumber === 2 && groupStories.length === 0) {
          setTimeout(() => {
            dispatch(actionCreator(CONSTANTS.GETGROUPSTORIESPAGINATION.SUCCESS, groupSotryData));
            isPaginationLoading.current = false;
          }, 3500);
        } else {
          dispatch(actionCreator(CONSTANTS.GETGROUPSTORIESPAGINATION.SUCCESS, groupSotryData));
          isPaginationLoading.current = false;
        }
      }
    })
    .catch((err) => {
      if (newRequest) {
        dispatch(actionCreator(CONSTANTS.GETGROUPSTORIES.FAILURE, err?.response?.data));
      } else {
        dispatch(actionCreator(CONSTANTS.GETGROUPSTORIESPAGINATION.FAILURE, err));
        isPaginationLoading.current = false;
      }
    });
};
export const getStoriesByTopic = (
  data,
  newRequest = true,
  isPaginationLoading = {},
  moreDataAvail = true
) => {
  return (dispatch, getState) => {
    const { groupStories, lastVisitedGroup, groupStoryOffset } = getState().homepage;
    const requestLoaderCond = lastVisitedGroup !== data.groupId;
    if (newRequest) {
      if (requestLoaderCond) {
        dispatch(actionCreator(CONSTANTS.GETGROUPSTORIES.REQUEST));
      }
    } else {
      dispatch(actionCreator(CONSTANTS.GETGROUPSTORIESPAGINATION.REQUEST));
    }
    if (requestLoaderCond) {
      RequestLoader(newRequest, dispatch);
    }
    let url = API_URLS.GetStoriesByTopic(data),
      staticHeader = createHeader();
    if (data.groupId) {
      url = data.personId
        ? API_URLS.getFeedPersonpageStories(data)
        : API_URLS.getFeedpageStories(data);
      if (data.categoryName) {
        if (data.categoryName === "Drafts") {
          url = url + `?storyStatus=Draft`;
        } else {
          url = url + `?categoryName=${data.categoryName}`;
        }
      }
    } else if (data.treeId) {
      url = API_URLS.getStories(data);
    }
    const deleteStoryList = getState().storyDelete.list;
    const totalOffset = groupStoryOffset - deleteStoryList?.length;
    const queryExist = url.indexOf("?") === -1;
    if (queryExist) {
      url += `?offset=${totalOffset}`;
    }
    const refetchGroupDataCond = !(
      groupStories.length > 0 &&
      newRequest &&
      !data.forceFully &&
      lastVisitedGroup === data.groupId &&
      groupStoryOffset !== groupStories.length
    );
    if (refetchGroupDataCond) {
      getGroupStoriesAPICall(getState, url, data, staticHeader, newRequest, dispatch, {
        isPaginationLoading,
        moreDataAvail,
        getTotalStories: true
      });
    }
  };
};

export const clearStorylikespersons = () => (dispatch) => {
  dispatch({ type: CLEAR_STORY_LIKES_PERSONS });
};

export const isMiloModalOpen = (payload) => {
  return (dispatch) => {
    dispatch({ type: MILO_MODAL, payload });
  };
};
export const updateCord = (payload) => {
  return (dispatch) => {
    dispatch({ type: HOMESCROLLCORD, payload });
  };
};
export const dismissTopicPrompt = () => {
  return (dispatch, getState) => {
    let url = API_URLS.dismissTopicPromptAPI,
      staticHeader = createHeader();
    callApi("POST", url, {}, false, staticHeader)
      .then((_res) => {
        dispatch(dismissFeatureStoryPrompt());
      })
      .catch((err) => {
        console.log("error", err?.response?.data);
      });
  };
};

export const followFeaturedHomepageTopic = (topicId, getHomepageStories) => {
  return (dispatch, getState) => {
    let url = `${API_URLS.followTopic(topicId)}`,
      staticHeader = createHeader();
    dispatch({ type: CONSTANTS.FOLLOWTOPIC.REQUEST });
    callApi("POST", url, {}, false, staticHeader)
      .then(() => {
        dispatch(dismissFeatureStoryPrompt());
        dispatch({ type: CONSTANTS.FOLLOWTOPIC.SUCCESS });
        dispatch(actionCreator(CONSTANTS.SETFOLLOWUNFOLLOWCOUNTUPDATE.SUCCESS, 1));
        dispatch(addMessage("You are now following Featured Stories", "success"));
        getHomepageStories(true);
      })
      .catch(() => {
        dispatch({ type: CONSTANTS.FOLLOWTOPIC.FAILURE });
        dispatch(addMessage("Some Error Occured", "error"));
      });
  };
};
const dismissFeatureStoryPrompt = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROMPTS_FEATURE_STORY,
    payload: false
  });
};

export const getHintList = () => {
  return async (dispatch, getState) => {
    const url = API_URLS.getHintListApi(),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.HINTLIST.REQUEST));
    try {
      const resp = await callApi("GET", url, {}, false, staticHeader, false, null, false);
      dispatch(actionCreator(CONSTANTS.HINTLIST.SUCCESS, resp.data));
    } catch {
      dispatch(actionCreator(CONSTANTS.HINTLIST.FAILURE));
    }
  };
};

export const updateHintStatus = (param) => {
  return async (dispatch, getState) => {
    const url = API_URLS.updateHintStatusApi(param),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.UPDATEHINTSTATUS.REQUEST));
    try {
      await callApi("POST", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.UPDATEHINTSTATUS.SUCCESS, param));
      dispatch(addMessage("Hint discarded successfully.", "success"));
      dispatch(getHintList());
    } catch {
      dispatch(actionCreator(CONSTANTS.UPDATEHINTSTATUS.FAILURE, param));
      dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const deleteTree = (treeId, treeName) => {
  return async (dispatch) => {
    const url = API_URLS.deleteTreeApi(treeId),
      staticHeader = createHeader();
    try {
      await callApi("DELETE", url, {}, false, staticHeader).then((res) => {
        dispatch(addMessage(`${treeName} has been deleted`, "notification", { showIcon: false }));
      });
    } catch {
      dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

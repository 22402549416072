import * as CONSTANTS from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  compareRecords: [],
  addcompareRecords: [],
  treePersonInfo: [],
  recordPersonInfo: [],
  personAndRecordPersonAssociation: [],
  attachRecord:[],
  isPersonInfoLoding: false,
  isTreePersonLoading: false,
  mergeRecordLoading: false,
  personAndRecordAssociationLoading: false,
  attachRecordLoading: false,
  getMergeRecorderror: false,
  getTreeRecordError: false,
  getRecordError: false,
  saveMergeError: false,
  attachRecordError: false,
  error: false,
  checkAssociation: [],
  checkAssociationLoading: false,
  checkAssociationError: false,
};

const compareRecord = (state = initialState, { type = null, payload = null } = {}) => {
  switch (type) {
    case CONSTANTS.GET_MERGE_RECORD.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CONSTANTS.GET_MERGE_RECORD.SUCCESS:
      return {
        ...state,
        compareRecords: payload,
        isLoading: false,
      };

    case CONSTANTS.GET_MERGE_RECORD.FAILURE:
      return {
        ...state,
        getMergeRecorderror: true,
        isLoading: false,
      };
    case CONSTANTS.GET_TREE_PERSON_INFO.REQUEST:
      return {
        ...state,
        isTreePersonLoading: true,
      };
    case CONSTANTS.GET_TREE_PERSON_INFO.SUCCESS:
      return {
        ...state,
        treePersonInfo: payload,
        isTreePersonLoading: false,
      };

    case CONSTANTS.GET_TREE_PERSON_INFO.FAILURE:
      return {
        ...state,
        getTreeRecordError: true,
        isTreePersonLoading: false,
      };
    case CONSTANTS.GET_RECORD_PERSON_INFO.REQUEST:
      return {
        ...state,
        isPersonInfoLoding: true,
      };
    case CONSTANTS.GET_RECORD_PERSON_INFO.FAILURE:
      return {
        ...state,
        getRecordError: true,
        isPersonInfoLoding: false,
      };
    case CONSTANTS.GET_RECORD_PERSON_INFO.SUCCESS:
      return {
        ...state,
        recordPersonInfo: payload,
        isPersonInfoLoding: false,
      };

    case CONSTANTS.SAVE_MERGE_RECORD.FAILURE:
      return {
        ...state,
        mergeRecordLoading: false,
        saveMergeError: true,
      };
    case CONSTANTS.SAVE_MERGE_RECORD.REQUEST:
      return {
        ...state,
        mergeRecordLoading: true,
      };
    case CONSTANTS.SAVE_MERGE_RECORD.SUCCESS:
      return {
        ...state,
        addcompareRecords: payload,
        mergeRecordLoading: false,
      };
    case CONSTANTS.SAVE_PERSOANDRECORD_ASSOCIATIO.FAILURE:
      return {
        ...state,
        personAndRecordAssociationLoading: false,
        error: true,
      };
    case CONSTANTS.SAVE_PERSOANDRECORD_ASSOCIATIO.REQUEST:
      return {
        ...state,
        personAndRecordAssociationLoading: true,
      };
    case CONSTANTS.SAVE_PERSOANDRECORD_ASSOCIATIO.SUCCESS:
      return {
        ...state,
        personAndRecordPersonAssociation: payload,
        personAndRecordAssociationLoading: false,
      };
    case CONSTANTS.SAVETOTREE.FAILURE:
      return {
        ...state,
        attachRecordLoading: false,
        attachRecordError: true,
      };
    case CONSTANTS.SAVETOTREE.REQUEST:
      return {
        ...state,
        attachRecordLoading: true,
      };
    case CONSTANTS.SAVETOTREE.SUCCESS:
      return {
        ...state,
        attachRecord: payload,
        attachRecordLoading: false,
      };
    case CONSTANTS.CHECK_ASSOCIATION.FAILURE:
      return {
        ...state,
        checkAssociationLoading: false,
        checkAssociationError: true,
      };
    case CONSTANTS.CHECK_ASSOCIATION.REQUEST:
      return {
        ...state,
        checkAssociationLoading: true,
      };
    case CONSTANTS.CHECK_ASSOCIATION.SUCCESS:
      return {
        ...state,
        checkAssociation: payload,
        checkAssociationLoading: false,
      };

    default:
      return state;
  }
};

export default compareRecord;

import Loader from "components/Loader";
import { COUPON_STATUS } from "redux/actions/printBook/order";
import { decodeJWTtoken } from "services";
import { planByPlanId, PlanDuration, PlanType } from "utils/constant";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useFeatureFlags } from "services/featureFlag";
import { useTypedSelector } from "redux/utils/useTypeSelector";

function isOneMonthPlan(startDate: Date, endDate: Date) {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();
  const monthsDifference = (endYear - startYear) * 12 + (endMonth - startMonth);
  return monthsDifference === 1;
}

export default function PrintBookRoute({ component: Component, ...rest }: RouteProps & Required<Pick<RouteProps, "component">>) {
  const { isLoading, couponStatus } = useTypedSelector((state) => state.printBookOrder);
  const { enabled: blackFridayEnabled, loading: blackFridayLoading } = useFeatureFlags("FF_BlackFriday");
  const { enabled: cyberMondayEnabled, loading: cyberMondayLoading } = useFeatureFlags("FF_CyberMonday");
  const isNAUser = decodeJWTtoken()?.extension_WebsiteId === "1";

  if (isLoading || blackFridayLoading || cyberMondayLoading) {
    return <Loader />;
  }
  return (
    <Route
      {...rest}
      render={(renderProps) => {
        if (COUPON_STATUS.NotGranted !== couponStatus) {
          return <Component {...renderProps} />;
        } else {
          const startDate = new Date(decodeJWTtoken()?.extension_StartDate ?? "");
          const endDate = new Date(decodeJWTtoken()?.extension_EndDate ?? "");
          const oneMonthPlan = isOneMonthPlan(startDate, endDate);

          if (isNAUser) {
            if (oneMonthPlan) {
              return <Redirect to={{ pathname: "/upgrade/ultimate-non-annual" }} />;
            } else {
              return <Redirect to={{ pathname: "/na-conversion" }} />;
            }
          }

          if (blackFridayEnabled) {
            window.location.replace("https://get.storied.com/blackfridayprint");
            return;
          }

          if (cyberMondayEnabled) {
            window.location.replace("https://get.storied.com/cyberprint");
            return;
          }

          const planId = decodeJWTtoken()?.extension_PlanId;

          if (!planId) {
            return <Redirect to={{ pathname: "/upgrade/regular" }} />;
          }

          const planInfo = planByPlanId[planId];
          if (!planInfo) {
            return <Redirect to={{ pathname: "/upgrade/regular" }} />;
          } else {
            if (planInfo.planType === PlanType.Plus && planInfo.planDuration !== PlanDuration.Annual) {
              return <Redirect to={{ pathname: "/upgrade/plus-non-annual" }} />;
            }
            if (planInfo.planType === PlanType.Ultimate && planInfo.planDuration !== PlanDuration.Annual) {
              return <Redirect to={{ pathname: "/upgrade/ultimate-non-annual" }} />;
            }
          }

          return <Component {...renderProps} />;
        }
      }}
    />
  );
}
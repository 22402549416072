import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getAccessToken, removeViaMarketingURL } from "../../services";
import "../Privacy/index.css";
import { useDispatch, useSelector } from "react-redux";
import { addEmailsToWhiteList } from "../../redux/actions/story";
import Loader from "../../components/Loader";

const Marketing = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isAccessTokenTerms = getAccessToken()
    const { userAccount } = useSelector(state => { return state.user });
    useEffect(() => {
        if (userAccount && isAccessTokenTerms && userAccount.email) {
            dispatch(addEmailsToWhiteList(userAccount.email)).then((_response) => {
                removeViaMarketingURL();
                history.replace(`/`)
            })
        }
    }, [userAccount])
    return <Loader />
};

export default Marketing;
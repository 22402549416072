import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
//Services
import {
  setAccessToken,
  setPaymentCookies,
  setSearchCookies,
  setSignInUpRedirect,
  setSignUpGroupPlanFlow,
  setSignUpGroupUser,
  setUserFlow
} from "services";
import { getRedirectFreeTral, getRedirectURI } from "utils/auth.js";

const useAuthUtils = () => {
  const { getAccessTokenSilently, logout, loginWithRedirect: auth0LoginWithRedirect } = useAuth0();
  const location = useLocation();
  const search = location?.search;

  const refreshAuth0Token = async () => {
    const response = await getAccessTokenSilently({
      cacheMode: "off", //to get new accessToken
      detailedResponse: true // To get updated new accessToken, idToken, claims
    });
    setAccessToken(response.access_token);
  };

  const acquireTokenSilently = () => {
    return getAccessTokenSilently();
  };

  const logoutWithRedirect = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleSignUpGroupPlanFlow = (roleOptions = "plus") => {
    setSignInUpRedirect("/plans");
    setUserFlow("signUp");
    setSignUpGroupUser(true);
    setSignUpGroupPlanFlow(true);
    auth0LoginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
        roleOptions: `/payment/${roleOptions}`
      },
      appState: {
        returnTo: `/plans${search}`
      }
    });
  };

  const handleSignUpAuth = () => {
    setUserFlow("signUp");
    auth0LoginWithRedirect({
      authorizationParams: {
        screen_hint: "signup"
      }
    });
  };

  const handleSignInAuth = () => {
    setUserFlow("signInUp");
    auth0LoginWithRedirect();
  };
  const handleSubscribeSignUpAuth = (planName) => {
    setSearchCookies("true");
    setPaymentCookies(planName);
    setUserFlow("signUp");
    auth0LoginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
        roleOptions: getRedirectURI(planName)
      },
      appState: {
        returnTo: `${getRedirectURI(planName)}${search}`
      }
    });
  };
  const HandlePlans = (_instance, planname, planId = null) => {
    let path = getRedirectFreeTral(planname);
    if (planId && search) {
      path = `${path}${search}&planId=${planId}`;
    } else if (planId) {
      path = `${path}?planId=${planId}`;
    }
    setPaymentCookies(planname);
    setUserFlow("signUp");
    auth0LoginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
        roleOptions: path
      },
      appState: {
        returnTo: path
      }
    });
  };

  const handleStorySignup = (redirectUri) => {
    setUserFlow("signUp");
    auth0LoginWithRedirect({
      authorizationParams: {
        screen_hint: "signup"
      },
      appState: {
        returnTo: `${redirectUri}${search}`
      }
    });
  };

  const handleStoryLogin = (redirectUri) => {
    setUserFlow("signInUp");
    auth0LoginWithRedirect({
      appState: {
        returnTo: `${redirectUri}${search}`
      }
    });
  };
  return {
    acquireTokenSilently,
    logoutWithRedirect,
    handleSignInAuth,
    handleSignUpAuth,
    HandlePlans,
    handleSignUpGroupPlanFlow,
    handleSubscribeSignUpAuth,
    handleStorySignup,
    handleStoryLogin,
    refreshAuth0Token
  };
};

export default useAuthUtils;

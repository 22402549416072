import ClickAwayListener from "@mui/material/ClickAwayListener";
import classNames from "classnames";
import { modal } from "components/TailwindModal/Helper/components";
import { actions1, actions2, handleFocus } from "components/TailwindModal/Helper/methods";
import { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { getOS } from "utils/getOS";
import "./index.css";

interface ModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  title: string;
  clickAwayClose?: boolean;
  showClose?: boolean;
  classes?: string;
  titleFontWeight?: string;
  modalPadding?: string;
  modalHead?: string;
  innerClasses?: string;
  isPropagation?: boolean;
  showInfoTooltip?: boolean;
  modalType?: string | null;
  [key: string]: any;
}

const Modal: React.FC<ModalProps> = ({
  showModal,
  setShowModal,
  title,
  clickAwayClose = true,
  showClose = true,
  classes = "inset-0",
  titleFontWeight = "typo-font-bold",
  modalPadding = "px-7 py-5",
  modalHead = "px-7 pt-6",
  innerClasses = "max-w-edit-search-modal-w",
  isPropagation = true,
  showInfoTooltip = false,
  modalType = "",
  ...restProps
}) => {
  const twModal = useRef<HTMLDivElement>(null);
  const scrollbarWidthRef = useRef<number>(16);
  const history = useHistory();
  const clickAwayClass = classNames(
    `overflow-x-hidden overflow-y-auto fixed z-1004 outline-none focus:outline-none w-full cursor-default ${getOS() === "windows" && "modal-body-pr-17"
    } ${classes}`
  );

  useEffect(() => {
    if (showModal) document.body.classList.add("tw-modal-open");
    else document.body.classList.remove("tw-modal-open");

    return () => {
      document.body.classList.remove("tw-modal-open");
    };
  }, [showModal]);

  const handleFamilyModalKeyDown = useCallback((e: KeyboardEvent, selectedElement: string) => {
    if (e.shiftKey) {
      handleFamilyShiftKeyDown(e, selectedElement);
    } else {
      handleFamilyTabKeyDown(e, selectedElement);
    }
  }, []);

  const handleRelationalModalKeyDown = useCallback((e: KeyboardEvent, selectedElement: string) => {
    const { shiftKey } = e;

    if (shiftKey && selectedElement === "date") {
      e.preventDefault();
      handleFocus("addAction");
    } else if (!shiftKey) {
      const focusElements: { [key: string]: string } = {
        spouse: "sfirstName",
        isLiving: "addAction",
        addAction: "date",
        birthLocation: "death",
        deathLocation: "isLiving"
      };
      const focusElement = focusElements[selectedElement];
      if (focusElement) {
        e.preventDefault();
        handleFocus(focusElement);
      }
    }
  }, []);

  const handleModalKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const selectedElement =
        (e.composedPath()[0] as HTMLElement)?.id || (e.target as HTMLElement)?.id;
      const selectedElementName =
        selectedElement.charAt(0).toLowerCase() + selectedElement.slice(1);
      if (modalType === "Family" && e.key === "Tab") {
        handleFamilyModalKeyDown(e, selectedElementName);
      } else if (modalType === "Relational" && e.key === "Tab") {
        handleRelationalModalKeyDown(e, selectedElementName);
      }
    },
    [handleFamilyModalKeyDown, handleRelationalModalKeyDown, modalType]
  );

  useEffect(() => {
    if (showModal) window.addEventListener("keydown", handleModalKeyDown, false);
    else window.removeEventListener("keydown", handleModalKeyDown, false);
    return () => {
      window.removeEventListener("keydown", handleModalKeyDown, false);
    };
  }, [handleModalKeyDown, showModal]);

  const handleFamilyShiftKeyDown = (e: KeyboardEvent, selectedElement: string) => {
    if (actions1[selectedElement as keyof typeof actions1]) {
      e.preventDefault();
      actions1[selectedElement as keyof typeof actions1]();
    }
  };

  const handleFamilyTabKeyDown = (e: KeyboardEvent, selectedElement: string) => {
    if (actions2[selectedElement as keyof typeof actions2]) {
      e.preventDefault();
      actions2[selectedElement as keyof typeof actions2]();
    }
  };

  const handleClickAway = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const box = twModal.current;
      const scrollbarWidth = scrollbarWidthRef.current;
      if (box && box.scrollWidth - scrollbarWidth > (e as MouseEvent).clientX) {
        setShowModal(false);
      }
    },
    [setShowModal]
  );

  const modalProps = {
    innerClasses,
    modalWrap: null,
    modalHead,
    titleFontWeight,
    title,
    subTitle: null,
    modalPadding,
    content: null,
    showClose,
    setShowModal,
    history,
    showInfoTooltip,
    ...restProps
  };

  return (
    <>
      {showModal && (
        <>
          <div
            onClick={(e) => isPropagation && e.stopPropagation()}
            id="twModal"
            data-testid="twModal"
            ref={twModal}
            className={clickAwayClass}
          >
            {clickAwayClose ? (
              <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
                {modal({ ...modalProps })}
              </ClickAwayListener>
            ) : (
              modal({ ...modalProps })
            )}
          </div>
          <div
            className={`tw-modal-overlay opacity-40 fixed inset-0 z-1001 bg-black cursor-default`}
          ></div>
        </>
      )}
    </>
  );
};

export default Modal;

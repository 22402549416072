import { useAuth0 } from "@auth0/auth0-react";

//Components
import Loader from "components/Loader";


const UnAuthenticatedTemplate = ({ children, showLoader = true }) => {
    const { isAuthenticated, isLoading } = useAuth0();
    return (
        !isAuthenticated && !isLoading ?
            <> {children} </> : showLoader && <Loader />
    )
}

export default UnAuthenticatedTemplate;
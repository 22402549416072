import API from "API/__generated__";
import { tr } from "components/utils";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { addMessage } from "redux/actions/toastr";
import Loader from "../components/Loader";
import { getBillingInformation } from "../redux/actions/payments";
import {
  decodeJWTtoken,
  getAccessToken,
  getOfferSignUp,
  getSubscription,
  getSubscriptionDetails,
  getUpdateSubscription,
  removeOfferSignUpUser,
  removeUpdateSubscription
} from "../services";
import {
  EARLIER_PLAN_ID,
  STAR_PLUS_PLAN_ID,
  STORIED_PLUS_FREE_TRIAL_7,
  planNameArr
} from "../utils/constant";
import { newSubscriberData, userPayWallDetailCancel } from "./../utils";

const PaymentRoute = ({ component: Component, paywal, props: componentProps, ...rest }) => {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const locationUse = useLocation();
  const history = useHistory();
  const locationName = locationUse.pathname.split("/");
  const planName = locationName[2] || "";
  const { t } = useTranslation();
  const getQueryParams = new URLSearchParams(locationUse.search);
  const planId = getQueryParams.get("planId");

  useEffect(() => {
    if (getOfferSignUp()) {
      removeOfferSignUpUser();
    }
  });
  const expirypaywall = useMemo(() => {
    if (getSubscription()) {
      let subdata = getSubscriptionDetails();
      if (subdata?.endDate) {
        return newSubscriberData(subdata);
      } else if (subdata?.subscriptionId) {
        return { ...subdata, recurlyId: subdata.subscriptionId };
      }
    } else {
      return userPayWallDetailCancel(decodeJWTtoken());
    }
  }, []);
  const [isLoading, setLoading] = useState(!!expirypaywall);
  useEffect(() => {
    if (expirypaywall?.recurlyId) {
      dispatch(getBillingInformation(expirypaywall.recurlyId)).then((data) => {
        setLoading(false);
        if (["Cancelled"].includes(data.subscriptionStatus)) {
          history.replace("/settings/billing");
        }
      });
      API.Users.usersCheckbillinginfo().then((data) => {
        if (!data) {
          dispatch(addMessage(tr(t, "Please add a payment card."), "error"));
          history.replace("/settings/billing?add-payment-card=true");
        }
      });
    }
  }, [dispatch, expirypaywall, history, t]);
  useEffect(() => {
    if (locationUse.hash.search("#state") !== -1) {
      history.replace("/");
    }
  }, [history, locationUse.hash]);
  useEffect(() => {
    const isUpdateSubscription = getUpdateSubscription();
    if (planId === expirypaywall?.planId && !isUpdateSubscription) {
      dispatch(addMessage(tr(t, "payment.alreadySubscribed"), "notification"));
      return history.replace("/");
    }
    isUpdateSubscription && removeUpdateSubscription();
  }, [dispatch, planId, t, history, expirypaywall?.planId]);
  const allowPaymentPage = () => {
    return (
      !accessToken ||
      (accessToken &&
        !(
          decodeJWTtoken()?.extension_UserType === "Paid" &&
          decodeJWTtoken()?.extension_WebsiteId === 1
        ) &&
        ((!planName &&
          ![STAR_PLUS_PLAN_ID, EARLIER_PLAN_ID, STORIED_PLUS_FREE_TRIAL_7].includes(
            expirypaywall?.planId
          )) ||
          (planName === "early" && !expirypaywall?.planId) ||
          planNameArr.includes(planName)))
    );
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return allowPaymentPage() ? (
          <Component {...props} {...componentProps} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        );
      }}
    />
  );
};

PaymentRoute.propTypes = {
  component: PropTypes.object,
  paywal: PropTypes.bool
};

export default PaymentRoute;

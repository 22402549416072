import logoImg from "assets/images/logo.svg";
import logoBlackImg from "assets/images/logoBlack.svg";
import logoBlueImg from "assets/images/logoBlue.svg";
import { REACT_APP_ENV } from "utils/env";

const appEnv = REACT_APP_ENV.toLowerCase();
const HeaderLogo = () => {
  switch (appEnv) {
    case "production":
      return <img src={logoImg} fetchPriority="high" alt="Storied" width={94} height={20} />;

    case "automation":
      return <img src={logoBlueImg} fetchPriority="high" alt="Storied" width={94} height={20} />;

    case "test":
    default:
      return <img src={logoBlackImg} fetchPriority="high" alt="Storied" width={94} height={20} />;
  }
};

export default HeaderLogo;